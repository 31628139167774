import * as React from 'react';
import moment from 'moment';
import Pikaday from 'pikaday';
import { responsiveHelper } from '../../../Shared/helpers/responsive-helper';
import { supportsDateInput } from '../../../Shared/helpers/browser-support-helper';
import { useShouldTranslate, useTranslation } from '../../translation';
import { FormControlWithIcon, FormControlIcon } from '../control-with-icon/control-with-icon';
import { SvgIcon } from '../svg-icon';

import calendar from '../../assets/svg/calendar.svg';
import { Skeleton } from '../../components/skeleton/skeleton';

export const displayDateFormat = 'MMM D, YYYY';
export const htmlInputDateFormat = 'YYYY-MM-DD';

export type OptionalDate = Date | undefined | null;

export interface IDatePickerCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	placeholder?: string;
	disableTranslation?: string;
	options?: Pikaday.PikadayOptions;
	additionalClassNames?: string;
	todayMarker?: string;
	i18nConfig?: Pikaday.PikadayI18nConfig;
	language?: string;
}

export interface IDatePickerPrimitiveProps {
	value: OptionalDate;
	onChange: (value: OptionalDate) => void;
	'aria-invalid'?: boolean;
	'aria-required'?: boolean;
}
export interface DateInputPikadayState {
	language?: string;
}

export type IDatePickerProps = IDatePickerCommonProps & IDatePickerPrimitiveProps;

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
	const shouldTranslate = useShouldTranslate();
	const { disableTranslation = false } = props;

	if ((responsiveHelper.isXs || responsiveHelper.isSm) && supportsDateInput()) {
		return <DateInputHTMLField {...props} />;
	}

	return shouldTranslate && !disableTranslation ? (
		<React.Suspense fallback={<Skeleton />}>
			<TranslatedDatePicker {...props} />
		</React.Suspense>
	) : (
		<DateInputPikaday {...props} />
	);
};

const TranslatedDatePicker: React.FC<IDatePickerProps> = (props) => {
	const { language, translate } = useTranslation();
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] as const;
	const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;
	const weekdaysShort = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'] as const;

	const config :Pikaday.PikadayI18nConfig = {
		previousMonth: translate('previousMonth'),
		nextMonth: translate('nextMonth'),
		months: months.map((m) => translate(m)),
		weekdays: weekdays.map((wd) => translate(wd)),
		weekdaysShort: weekdaysShort.map((wds) => translate(wds)),
	};

	return <DateInputPikaday 
		todayMarker={translate('Today')} 
		i18nConfig={config} {...props} 
		placeholder={!!props.placeholder ? translate(props.placeholder as any) : undefined} 
		language={language}
	/>;
};

class DateInputPikaday extends React.Component<IDatePickerProps, DateInputPikadayState> {
	private pikaday?: Pikaday;
	private inputRef: HTMLInputElement;

	constructor(props: IDatePickerProps) {
		super(props);
		this.state = {
			language: this.props.language,
		};
	}

	render() {
		const {
			formControlId,
			placeholder,
			additionalClassNames,
			acceptanceTestTargetId,
			'aria-invalid': ariaInvalid,
			'aria-required': ariaRequired,
		} = this.props;
		return (
			<FormControlWithIcon iconPlacement="right">
				<input
					id={formControlId}
					onBlur={this.onInvalidHandler}
					className={`form-control ${additionalClassNames || ``}`}
					placeholder={placeholder}
					ref={this.setInputRef}
					data-pp-at-target={acceptanceTestTargetId}
					aria-invalid={ariaInvalid}
					aria-required={ariaRequired}
					autoComplete="off"
				/>
				<label htmlFor={formControlId}>
					<FormControlIcon darker={true} small={true}>
						<SvgIcon svg={calendar} />
					</FormControlIcon>
				</label>
			</FormControlWithIcon>
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IDatePickerProps) {
		if(nextProps.language !== this.props.language && this.pikaday){
			// State set to force appropriate re-render
			this.setState({language: nextProps.language}, () => this.initPikaday(this.inputRef));
			return;
		}

		const currentValue = this.props.value;
		let newValue = nextProps.value;
		const newMinDate = nextProps.options && nextProps.options.minDate;
		this.setValue(newValue, currentValue, newMinDate);

		const currentMinDate = this.props.options && this.props.options.minDate;
		this.setMinDate(newMinDate, currentMinDate, newValue);

		const currentMaxDate = this.props.options && this.props.options.maxDate;
		const newMaxDate = nextProps.options && nextProps.options.maxDate;
		this.setMaxDate(newMaxDate, currentMaxDate, newValue);
	}

	componentWillUnmount() {
		this.destroyPikadayIfExists();
	}

	onFieldValueUpdate = (newValue: Date) => {
		if (this.pikaday) {
			this.pikaday.setDate(newValue, true);
			this.pikaday.gotoDate(newValue);
		}
	}

	private setInputRef = (ref: HTMLInputElement) => {
		this.inputRef = ref;
		this.initPikaday(this.inputRef);
	}

	private destroyPikadayIfExists = () => {
		if (this.pikaday) {
			this.pikaday.destroy();
			this.pikaday = undefined;
		}
	}

	private initPikaday = (field: HTMLInputElement) => {
		this.destroyPikadayIfExists();

		const bestDefaultDate = this.props.value || (this.props.options && this.props.options.minDate) || undefined;

		if (field) {
			const pikadayOptions = {
				...this.props.options,
				field,
				defaultDate: bestDefaultDate,
				setDefaultDate: true,
				onSelect: this.onSelect,
				blurFieldOnSelect: false,
				format: displayDateFormat,
				toString: this.toString,
				parse: this.parseDateString,
			} as Pikaday.PikadayOptions;

			if(isValidI18nConfig(this.props.i18nConfig)) {
				pikadayOptions.i18n = this.props.i18nConfig;
			}

			this.pikaday = new Pikaday(pikadayOptions);
			
			if (!!this.props.value) {
				field.value = this.toString(this.props.value, displayDateFormat);
				this.pikaday.setDate(this.props.value, true);
			} else {
				field.value = '';
			}
		}
	}

	private setValue = (newValue: OptionalDate, currentValue: OptionalDate, newMinDate: OptionalDate) => {
		if (newValue instanceof Date) {
			this.setDateValue(newValue, currentValue);
		} else {
			this.clearValue(newMinDate);
		}
	}

	private setDateValue = (newValue: Date, currentValue: OptionalDate) => {
		if (!isSameDate(newValue, currentValue) && this.pikaday) {
			this.pikaday.setDate(newValue, true);
			this.pikaday.gotoDate(newValue);
		}
	}

	private clearValue = (minDate: OptionalDate) => {
		if (this.pikaday) {
			this.pikaday.setDate('', true);
			if (minDate) {
				this.pikaday.gotoDate(minDate);
			}
		}
	}

	private setMinDate = (newMinDate: OptionalDate, currentMinDate: OptionalDate, newValue: OptionalDate) => {
		if (!isSameDate(newMinDate, currentMinDate) && this.pikaday) {
			if (newMinDate) {
				this.pikaday.setMinDate(newMinDate);

				if (newValue && newMinDate > newValue) {
					this.pikaday.gotoDate(newMinDate);
				}
			} else {
				this.pikaday.setMinDate(new Date(0));
			}
		}
	}

	private setMaxDate = (newMaxDate: OptionalDate, currentMaxDate: OptionalDate, newValue: OptionalDate) => {
		if (!isSameDate(newMaxDate, currentMaxDate) && this.pikaday) {
			if (newMaxDate) {
				this.pikaday.setMaxDate(newMaxDate);

				if (newValue && newMaxDate < newValue) {
					this.pikaday.gotoDate(newMaxDate);
				}
			} else {
				this.pikaday.setMaxDate(new Date(0));
			}
		}
	}

	// Make pikaday more consistent with other input types by only firing onChange
	private onSelect = (date: OptionalDate) => {
		// if (date && isSameDate(date, this.props.value)) {
		// 	return;
		// }
		// if (date) {
		this.props.onChange(date);
		// }
	}

	// When the input is left with an empty or invalid value it wont fire onSelect
	// This manually fires onSelect(null) to update any watching observables to null
	private onInvalidHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
		const input = (ev.currentTarget as HTMLInputElement);
		const inputMoment = moment(input.value, displayDateFormat, false);
		const isInvalid = !inputMoment.isValid();
		if (isInvalid) {
			this.onSelect(null);
		}
	}

	private toString = (theDate: Date, format: string): string => {
		const {todayMarker = 'Today'} = this.props;
		let output = moment(theDate).format(format);
		if (moment(theDate).isSame(moment().startOf('day'), 'day')) {
			output += ` (${todayMarker})`;
		}
		return output;
	}

	private parseDateString = (dateString: string, format: string): Date => {
		if (dateString.trim().toLowerCase() === 'today') {
			return moment().startOf('day').toDate();
		}
		return moment(dateString).toDate();
	}
}

class DateInputHTMLField extends React.Component<IDatePickerProps> {
	render() {
		const {
			formControlId,
			placeholder,
			options,
			value,
			additionalClassNames,
			acceptanceTestTargetId,
			'aria-invalid': ariaInvalid,
		} = this.props;

		const date = value ? moment(value).format(htmlInputDateFormat) : '';
		const minDate = options && options.minDate;
		const min = minDate ? moment(minDate).format(htmlInputDateFormat) : '';
		const max = options && options.maxDate ? moment(options.maxDate).format(htmlInputDateFormat) : '';

		return (
			<FormControlWithIcon iconPlacement="right">
				<input
					id={formControlId}
					type="date"
					onChange={this.onChange}
					className={`form-control ${additionalClassNames || ``}`}
					placeholder={placeholder}
					value={date}
					min={min}
					max={max}
					data-pp-at-target={acceptanceTestTargetId}
					aria-invalid={ariaInvalid}
				/>
				<label htmlFor={formControlId}>
					<FormControlIcon darker={true} small={true}>
						<SvgIcon svg={calendar} />
					</FormControlIcon>
				</label>
			</FormControlWithIcon>
		);
	}

	private onChange = (ev: React.FormEvent<HTMLInputElement>) => {
		const { value } = (ev.target as HTMLInputElement);
		const m = moment(value);
		const date = m.isValid() ? m.toDate() : null;

		if (date) {
			this.props.onChange(date);
		}
	}
}

function isSameDate(a: OptionalDate, b: OptionalDate) {
	if (!a || !b) {
		return a === b;
	}
	if (a.getTime && b.getTime) {
		return (a.getTime() === b.getTime());
	}
	return a === b;
}

function isValidI18nConfig(
	config: Pikaday.PikadayI18nConfig | undefined
): boolean {
	const validation = { months: 12, weekdays: 7 };

	if (!config) {
		return false;
	}

	const previousMonth = !!config.previousMonth;
	const nextMonth = !!config.nextMonth;
	const months = config.months.length === validation.months;
	const weekdays = config.weekdays.length === validation.weekdays;
	const weekdaysShort = config.weekdaysShort.length === validation.weekdays;

	return previousMonth && nextMonth && months && weekdays && weekdaysShort;
}
