import {
	getDonorPledgeEntryDataService,
	setNewCRSFoken
} from "../../donorpledgeentry-data-service";
import {
	ConfirmationCodeErrorResponse,
	ConfirmationCodeResultStatus,
	ConfirmationCodeServiceUnavailableResponse,
	ConfirmationCodeSuccessResponse,
	EnterMobileNumberConfirmCodeRequest,
	EnterMobileNumberErrorResponse,
	EnterMobileNumberResponse,
	RecurringDetails,
	ResendConfirmationCodeRequest
} from "../../donorpledgeentry-generated";
import { MainViewModel } from "../../main/main-view-model";
import { SecurityCodeEvents } from '../../state-machine/security-code-states-events-actions';
import { generateSecurityCodeMachineNew } from '../../state-machine/security-code-state-machine';
import { Actions, Events } from "../../state-machine/states-events-actions";
import { ICancellablePromise } from "../../../LoggedInWeb/utils/cancellable-promise";
import { MachineContext } from "../../../Shared/state-machine/saga-state-machine";
import { confirmationCodeSentMessage } from "../../../WebGiving/helpers/notification-message-helper";
import { NotificationViewModel } from "../../../WebGiving/components/notification/notification";
import { scrollErrorIntoViewOnNextFrame } from "../../../WebGiving/utils/error-focuser";

const genericErrorMessage = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;

// actions
export const EnterSecurityCodeActions = {
	PostSecurityCode: postConfirmationCode,
	ResendSecurityCode: postResendConfirmationCode,
	ResendSecurityCodeByVoice: postResendConfirmationCodeByVoice,
	CompleteProcessSecurityCode: completeProcessSecurityCode,
};

// config
export const enterSecurityCodeMachineConfig = () =>
		generateSecurityCodeMachineNew(Actions.PostSecurityCode, Actions.ResendSecurityCode, Actions.ResendSecurityCodeByVoice, Actions.CompleteProcessSecurityCode);

function* postResendConfirmationCode(machineContext: MachineContext<MainViewModel>):
		IterableIterator<ICancellablePromise<EnterMobileNumberResponse|EnterMobileNumberErrorResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		enterSecurityCodeViewModel: {
			normalizedInternationalNumber,
		},
	} = mainViewModel;

	const request = {
			NormalizedInternationalNumber: normalizedInternationalNumber,
		} as ResendConfirmationCodeRequest;

	yield* resendConfirmationCode(machineContext, request);
}

function* postResendConfirmationCodeByVoice(machineContext: MachineContext<MainViewModel>):
		IterableIterator<ICancellablePromise<EnterMobileNumberResponse|EnterMobileNumberErrorResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		enterSecurityCodeViewModel: {
			normalizedInternationalNumber,
		}
	} = mainViewModel;

	const request = {
			NormalizedInternationalNumber: normalizedInternationalNumber,
			UseVoiceCall: true,
		} as ResendConfirmationCodeRequest;

	yield* resendConfirmationCode(machineContext, request);
}

function* resendConfirmationCode(machineContext: MachineContext<MainViewModel>, request: ResendConfirmationCodeRequest):
		IterableIterator<ICancellablePromise<EnterMobileNumberResponse|EnterMobileNumberErrorResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		userPrefersVoiceCalls,
		showNotification,
		enterSecurityCodeViewModel: {
			setNormalizedInternationalNumber,
			setRequestCode,
			setError,
		},
	} = mainViewModel;

	try {
		const response = yield getDonorPledgeEntryDataService().resendConfirmationCode({ model: request } , () => (mainViewModel));
		if (response.Success) {
			setNormalizedInternationalNumber(response.MobileNumber.NormalizedInternationalNumber);
			setRequestCode(response.RequestToken);
		} else {
			setError(response.Reason);
		}

		const notificationText = confirmationCodeSentMessage(request.UseVoiceCall == null ? userPrefersVoiceCalls : request.UseVoiceCall);
		const notificationViewModel = new NotificationViewModel(notificationText, 10);
		showNotification(notificationViewModel);
	} catch {
		setError(`Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`);
	}
	SecurityCodeEvents.raise.CodeResent(machineContext);
}

type EnterMobileNumberConfirmationCodeResponse = ConfirmationCodeSuccessResponse | ConfirmationCodeErrorResponse | ConfirmationCodeServiceUnavailableResponse;
type EnterMobileNumberConfirmationCodeRequest = { model: EnterMobileNumberConfirmCodeRequest, recurringDetails: RecurringDetails }

function* postConfirmationCode(machineContext: MachineContext<MainViewModel>) {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		disableValidation,
		setPerson,
		setRememberMe,
		setConfirmedMobileNumber,
		pledgeExistsViewModel: {
			setRecurringInfo,
			setPledgeProgressUrl,
			setRecurringPaymentExists
		},
		enterSecurityCodeViewModel: {
			securityCode,
			requestCode,
			rememberMobileNumber,
			normalizedInternationalNumber,
			setError,
			resetSecurityCode,
		},
		amount,
		recurringOption
	} = mainViewModel;

	if (!disableValidation) {
		const validationState = mainViewModel.enterSecurityCodeViewModel.validate();
		if (!validationState.isValid) {
			mainViewModel.enterSecurityCodeViewModel.validationState = validationState;
			scrollErrorIntoViewOnNextFrame();
			SecurityCodeEvents.raise.ValidationError(machineContext);
			return;
		}
	}

	if (rememberMobileNumber.value) {
		setRememberMe(true);
	}
	
	const request = {
		model: {
			Code: securityCode.value,
			RequestToken: requestCode,
			RememberMe: rememberMobileNumber.value,
			NormalizedInternationalNumber: normalizedInternationalNumber,
		},
		recurringDetails: recurringOption ? {
			PledgedAmount: amount.toString(), 
			RecurringAmount: recurringOption.amount.toString(),
			FrequencyCode: recurringOption.code, 
			StartDate: recurringOption.starts,
			EndDate: recurringOption.ends
		} : null
	} as EnterMobileNumberConfirmationCodeRequest;

	try {
		const response: EnterMobileNumberConfirmationCodeResponse = yield getDonorPledgeEntryDataService().enterMobileNumberConfirmationCode(request, () => (mainViewModel));
		switch (response.Status) {
			case ConfirmationCodeResultStatus.Success:
				if (response.Person) {
					setNewCRSFoken(response.Person.NewCSRFToken);
					setPerson(response.Person);
					setConfirmedMobileNumber(response.ConfirmedMobileNumber);
					if (response.Person.PledgeExists) {
						setRecurringPaymentExists(response.Person.RecurringPaymentExists);
						setPledgeProgressUrl(response.CampaignsRedirectUrl);
						setRecurringInfo({
							amount,
							option: recurringOption,
							redirectUrl: response.WebGivingRedirectUrl 
						});
						Events.raise.UserAlreadyPledged(machineContext);
					} else {
						SecurityCodeEvents.raise.RequestSuccess(machineContext);
					}
				} else {
					Events.raise.UserUnknown(machineContext);
				}
				resetSecurityCode();
				break;
			case ConfirmationCodeResultStatus.ConfirmationCodeError:
				if (response.ConfirmationCodeInvalid) {
					securityCode.setServerSideValidationError(response.Reason);
				} else {
					setError(response.Reason);
				}
				SecurityCodeEvents.raise.RequestFailure(machineContext);
				break;
			case ConfirmationCodeResultStatus.CampaignServiceUnavailable:
			default:
				setError(genericErrorMessage);
				SecurityCodeEvents.raise.RequestFailure(machineContext);
				break;
		}
	} catch {
		setError(genericErrorMessage);
		SecurityCodeEvents.raise.RequestFailure(machineContext);
	}
}

function* completeProcessSecurityCode(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	Events.raise.UserAuthenticated(mainViewModel.machineContext);
}
