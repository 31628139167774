import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { machineContextKey, MachineContext } from './saga-state-machine';

@inject(machineContextKey)
@observer
export class Match extends React.Component<{ state: string | string[], machineContext?: MachineContext }> {
	@computed
	get isMatched() {
		const { state, machineContext } = this.props;
		return machineContext && machineContext.matchesState(state);
	}
	render(): any {
		return this.isMatched ? this.props.children : null;
	}
}

@inject(machineContextKey)
@observer
export class MatchAny extends React.Component<{ states: (string | string[])[], machineContext?: MachineContext }> {
	@computed
	get isMatched() {
		const { states, machineContext } = this.props;
		return machineContext && machineContext.matchesAnyState(states);
	}

	render(): any {
		return this.isMatched ? this.props.children : null;
	}
}

@inject(machineContextKey)
@observer
export class MatchAll extends React.Component<{ states: (string | string[])[], machineContext?: MachineContext }> {
	@computed
	get isMatched() {
		const { states, machineContext } = this.props;
		return machineContext && machineContext.matchesAllStates(states);
	}

	render(): any {
		return this.isMatched ? this.props.children : null;
	}
}

@inject(machineContextKey)
@observer
export class MatchNot extends React.Component<{ state: string | string[], machineContext?: MachineContext }> {
	@computed
	get isMatched() {
		const { state, machineContext } = this.props;
		return machineContext && machineContext.matchesState(state);
	}

	render(): any {
		return this.isMatched ? null : this.props.children;
	}
}

@inject(machineContextKey)
@observer
export class MatchNone extends React.Component<{ states: (string | string[])[], machineContext?: MachineContext }> {
	@computed
	get isMatched() {
		const { states, machineContext } = this.props;
		return machineContext && machineContext.matchesAnyState(states);
	}

	render(): any {
		return this.isMatched ? null : this.props.children;
	}
}
