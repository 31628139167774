import { delay } from 'redux-saga';
import { MainViewModel } from '../main/main-view-model';
import { MachineContext } from '../../Shared/state-machine/saga-state-machine';
import { events, eventType, states } from '../../Shared/state-machine/states-events-and-actions';

// states
enum SecurityCodeStateValues {
	Idle,
	Resending,
	Invoking,
	NeedHelp,
	Resent,
	Success,
	Failure,
	ResendByVoice,
	InvokingResent,
	FailureResent,
	NeedHelpResent,
	History,
}
export const SecurityCodeStates = states(SecurityCodeStateValues);

// events
const eventItems = {
	RequestCodeResend: eventType<void>(),
	ResendingCode: eventType<void>(),
	CodeResent: eventType<void>(),
	ResendGuardDisabled: eventType<void>(),
	RequestCodeByVoice: eventType<void>(),
	SubmitSecurityCode: eventType<void>(),
	ValidationError: eventType<void>(),
	RequestSuccess: eventType<void>(),
	RequestFailure: eventType<void>(),
	CodeConfirmationFailed: eventType<void>(),
	ShowNeedHelp: eventType<void>(),
};
export const SecurityCodeEvents = events(eventItems);

// actions
export const SecurityCodeActions = {
	EnableResendGuard: enableResendGuard,
	DisableResendGuard: disableResendGuard,
	FailProcessSecurityCode: failProcessSecurityCode,
};

function* enableResendGuard(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const {
		enterSecurityCodeViewModel: { disableResendCode },
	} = machineContext.viewModel as MainViewModel;

	disableResendCode();
}

function* disableResendGuard(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const {
		enterSecurityCodeViewModel: { enableResendCode },
	} = machineContext.viewModel as MainViewModel;

	delay(20000).then(enableResendCode);
}

function* failProcessSecurityCode(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	SecurityCodeEvents.raise.CodeConfirmationFailed(machineContext);
}
