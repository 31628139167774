import { observable, action } from 'mobx';
import { Currency } from "../../donorpledgeentry-generated";
import { PledgeLabel } from "../../donorpledgeentry-generated";
import { MainViewModel } from "../../main/main-view-model";

export class PledgeSuccessViewModel {
	@observable
	amount: number;

	get isLoading(): boolean {
		return this.vm.isAppLoading;
	}

	currency: Currency;
	campaignName: string;
	thankYouMessage: string;
	giveToCampaignUrl: string;
	pledgeProgressUrl: string;
	pledgeLabel: PledgeLabel;

	constructor(private vm: MainViewModel) {
		this.setAmount(0);
		this.currency = vm.campaignInfo.Currency;
		this.campaignName = vm.campaignInfo.CampaignName;
		this.giveToCampaignUrl = vm.campaignInfo.GiveToCampaignUrl;
		this.pledgeProgressUrl = vm.campaignInfo.PledgeProgressUrl;
		this.pledgeLabel = vm.organization.PledgeLabel;
		vm.history.push(false);
	}

	@action
	setAmount = (amount: number) => {
		this.amount = amount;
	}

	@action
	setPledgeProgressUrl = (url: string) => {
		this.pledgeProgressUrl = url;
	};

	handleSubmit = () => {
		this.redirectToGiveToCampaignPage();
	}

	redirectToGiveToCampaignPage = () => {
		window.location.href = decodeURIComponent(this.giveToCampaignUrl);
	}
}
