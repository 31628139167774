import * as React from 'react';
import { observer } from 'mobx-react';

import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';

export const PledgeButton = observer(({
		amountFormatted,
		pledgeLabel,
		isLoading,
		acceptanceTestTargetId,
	}: {
		amountFormatted: string,
		currencyCode?: string,
		pledgeLabel: string,
		isLoading: boolean,
		acceptanceTestTargetId: string,
	}) => {

	const buttonLabel = `${pledgeLabel} $${amountFormatted}`;

	return <SubmitButton acceptanceTestTargetId={acceptanceTestTargetId} isLoading={isLoading}>{buttonLabel}</SubmitButton>;
});
