import {
	States,
	Events,
	Actions,
} from './states-events-actions';
import { MachineConfig } from 'xstate/lib/types';
import { confirmPledgeMachineConfig } from "../pages/confirm-pledge/confirm-pledge-machine-config";
import { enterMobileNumberMachineConfig } from "../pages/enter-mobile-number/enter-mobile-number-machine-config";
import { enterSecurityCodeMachineConfig } from "../pages/enter-security-code/enter-security-code-machine-config";
import { enterPledgeMachineConfig } from "../pages/enter-pledge/enter-pledge-machine-config";
import { createUserMachineConfig } from "../pages/create-user/create-user-machine-config";
import { enterCreatePledgeCodeMachineConfig } from '../pages/confirm-create-pledge/confirm-create-pledge-machine-config';

export const donorPledgeEntryMachineConfig = (): MachineConfig => ({
	key: 'DonorPledgeEntry',
	id: 'DonorPledgeEntry',
	initial: States.Init,
	states: {
		[States.Init]: {
			onEntry: [Actions.CheckForExistingPledge],
			on: {
				[Events.UserAlreadyPledged]: States.PledgeExists,
				[Events.UserHasNotPledged]: States.EnterPledgeAmount,
			},
		},
		[States.EnterPledgeAmount]: {
			onEntry: [Actions.RecordPageViewAndStats],
			on: {
				[Events.UserUnknown]: States.EnterMobileNumber,
				[Events.UserAuthenticated]: States.ConfirmPledge,
				[Events.UserUnknownHasConfirmedMobileNumber]: States.CreateUser,
				...signOutTransition(),
			},
			...enterPledgeMachineConfig(),
		},
		[States.EnterMobileNumber]: {
			onEntry: [Actions.RecordPageViewAndStats, Actions.CheckForConfirmedMobileNumber],
			on: {
				[Events.SecurityCodeSent]: States.EnterSecurityCode,
				[Events.EnterMobileNumberValidationError]: States.EnterMobileNumber,
				[Events.HistoryBackTriggered]: States.EnterPledgeAmount,
				[Events.UserUnknownHasConfirmedMobileNumber] : States.EnterPledgeAmount
			},
			...enterMobileNumberMachineConfig(),
		},
		[States.EnterSecurityCode]: {
			onEntry: [Actions.RecordPageViewAndStats],
			on: {
				[Events.ReEnterMobileNumber]: States.EnterMobileNumber,
				[Events.UserUnknown]: States.CreateUser,
				[Events.UserAlreadyPledged]: States.PledgeExists,
				[Events.UserAuthenticated]: States.ConfirmPledge,
				[Events.HistoryBackTriggered]: States.EnterMobileNumber,
			},
			...enterSecurityCodeMachineConfig(),
		},
		[States.CreateUser]: {
			onEntry: [Actions.RecordPageViewAndStats],
			on: {
				[Events.UserCreated]: States.ConfirmPledge,
				[Events.HistoryBackTriggered]: States.EnterMobileNumber,
			},
			...createUserMachineConfig(),
		},
		[States.ConfirmPledge]: {
			onEntry: [
				Actions.CheckForExistingPledge,
				Actions.RecordPageViewAndStats,
			],
			on: {
				[Events.PledgeRejected]: States.EnterPledgeAmount,
				[Events.PledgeConfirmed]: States.PledgeSuccess,
				[Events.UserSessionExpired]: States.ReenterSecurityCode,
				[Events.UserAlreadyPledged]: States.PledgeExists,
				[Events.HistoryBackTriggered]: States.EnterPledgeAmount,
				...signOutTransition(),
			},
			...confirmPledgeMachineConfig(),
		},
		[States.ReenterSecurityCode]: {
			onEntry: [Actions.RecordPageViewAndStats],
			on: {
				[Events.UserAlreadyPledged]: States.PledgeExists,
				[Events.UserAuthenticated]: States.PledgeSuccess,
				[Events.HistoryBackTriggered]: States.EnterPledgeAmount,
				...signOutTransition(),
			},
			...enterCreatePledgeCodeMachineConfig(),
		},
		[States.PledgeExists]: {
			onEntry: [Actions.RecordPageViewAndStats, Actions.PromptEmailVerification],
			on: {
				[Events.HistoryBackTriggered]: States.EnterPledgeAmount,
				...signOutTransition(),
			},
		},
		[States.PledgeSuccess]: {
			onEntry: [Actions.RecordPageViewAndStats, Actions.PromptEmailVerification],
			on: {
				[Events.HistoryBackTriggered]: States.EnterPledgeAmount,
				...signOutTransition(),
			},
		},
	},
});

const signOutTransition = () => ({
	[Events.UserSignedOut]: {
		[States.EnterPledgeAmount]: {
			actions: [Actions.ProcessUserSignOut],
		},
	},
});
