import * as React from 'react';

import { PledgeSuccessViewModel } from './pledge-success-view-model';
import * as styles from "./pledge-success.less";
import { IPageBaseProps } from '../shared/page-props-base';

import { Formatter } from "../../../LoggedInWeb/helpers/formatter";
import { FadeTransition, fadeTransitionDuration } from "../../../WebGiving/components/fade-transition";
import { Form } from "../../../WebGiving/primitives/form";
import { PanelHeading } from "../../../WebGiving/components/panel-heading";
import { SvgIcon } from "../../../WebGiving/components/svg-icon";
import { SubmitButton } from "../../../WebGiving/components/submit-button/submit-button";

import iconEnvelope from "../../../WebGiving/assets/svg/envelope.svg";
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';

export class PledgeSuccess extends React.Component<IPageBaseProps<PledgeSuccessViewModel>> {
	render() {
		const { vm, transitionState } = this.props;
		const {
			amount,
			campaignName,
			pledgeProgressUrl,
			thankYouMessage,
			isLoading,
			handleSubmit,
			pledgeLabel,
		} = vm;

		const elementsShouldEnter = transitionState === 'entered';

		const currencySymbol = '$';
		const amountFormatted = Formatter.formatNumberForDisplay(amount, 2);

		return (
			<>
				<Form onSubmit={handleSubmit}>
					<PanelHeading vm={this.props.vm}>
						<FadeTransition in={elementsShouldEnter}>
							<div className={styles.contentWrapper}>
								<div className={styles.pledgeSuccessIcon}>
									<SvgIcon svg={iconEnvelope} />
								</div>
								<h2>
									Thank you for {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.VerbPresentTenseLowerCase : "pledging"} <strong>{currencySymbol}{amountFormatted}</strong>
								</h2>
								<p className={styles.subHeading}>
									to {campaignName}
								</p>
								{thankYouMessage && <p className={styles.thankYouMessage}>
									{thankYouMessage}
								</p>}
								<FocusOnMount delay={fadeTransitionDuration}>
									<SubmitButton
										acceptanceTestTargetId="PledgeSuccessCtaButton"
										isLoading={isLoading}
										isBlocked={false}>
										Give to Campaign
									</SubmitButton>
								</FocusOnMount>
								<div className={styles.secondaryCta}>
									<a className="brand-link" href={pledgeProgressUrl}>See my {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : "pledge"} progress</a>
								</div>
							</div>
						</FadeTransition>
					</PanelHeading>
				</Form>
			</>
		);
	}
}
