import * as React from 'react';
import { observer } from 'mobx-react';

import { EnterMobileNumberViewModel } from './enter-mobile-number-view-model';
import * as style from './enter-mobile-number.less';
import { IPageBaseProps } from '../shared/page-props-base';
import { getPageClasses } from '../shared/get-page-classes';
import { pageFooter } from '../../main/main.less';

import { Form } from '../../../WebGiving/primitives/form';
import { FadeTransition } from '../../../WebGiving/components/fade-transition';
import { PanelHeading } from '../../../WebGiving/components/panel-heading';
import { PanelContentTransition } from '../../../WebGiving/components/panel-content-transition';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { FormControl } from '../../../WebGiving/components/form-control/form-control';
import { MobilePhoneControl } from '../../components/mobile-phone-control/mobile-phone-control';
import { InjectableWebGivingProps, injectWebGivingProps } from '../../../WebGiving/utils/injectable-props';
import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';
import { Link, InfoLink } from '../../../WebGiving/primitives/link/link';

@injectWebGivingProps
@observer
export class EnterMobileNumber extends React.Component<IPageBaseProps<EnterMobileNumberViewModel> & InjectableWebGivingProps> {
	panelBodyNode: HTMLDivElement;

	render() {
		const { vm, transitionState } = this.props;
		const {
			mobileNumber,
			mobileCountry,
			pageShouldBeVisible,
			isLoading,
			isBlocked,
		} = vm;
		const elementsShouldEnter = transitionState === 'entered';

		return (
			<div className={getPageClasses(pageShouldBeVisible, isLoading)} data-pp-at-target={`Enter Mobile Number page`}>
				<Form onSubmit={this.handleSubmit}>
					<PanelHeading vm={vm}>
						<FadeTransition in={elementsShouldEnter}>
							<div>
								<h1 className="panel-title">VERIFY YOUR MOBILE NUMBER</h1>
								<p className="panel-subtitle">Enter your number so we can send you a security code.</p>

								<InfoLink acceptanceTestTargetId="How does mobile sign in work" onClick={this.showMobileSigninInfo} branded={false}>
									How does mobile sign in work?
								</InfoLink>
							</div>
						</FadeTransition>
					</PanelHeading>
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<PanelContentTransition in={true} setContentHeight={this.setContentHeight} className="panel-body-content">
							<div className="row">
								<div className={`col-sm-8 col-centered ${style.mobileNumberField}`}>
									<FormControl label="Mobile number" validationError={mobileNumber.validationError}>
										<MobilePhoneControl
											numberField={mobileNumber}
											countryField={mobileCountry}
											acceptanceTestTargetId="Mobile number"
											focusOnMount={true}
											aria-required={true}
										/>
									</FormControl>

									<div className={style.needHelp}>
										<Link acceptanceTestTargetId="Need help" onClick={this.showHelp} branded={false}>Need help?</Link>
									</div>

								</div>
							</div>
						</PanelContentTransition>
					</div>
					<PanelFooter className={pageFooter}>
						<SubmitButton acceptanceTestTargetId="Send code" isLoading={isLoading} isBlocked={isBlocked}>Send code</SubmitButton>
					</PanelFooter>
				</Form>
			</div>
		);
	}

	setContentHeight = (offsetHeight: number) => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${offsetHeight}px`;
		}
	}

	private savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;

	private showHelp = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		this.props.vm.showHelp();
	}

	private showMobileSigninInfo = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		this.props.vm.showMobileSigninInfo();
	}

	private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.props.vm.handleSubmit();
	}
}
