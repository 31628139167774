import { InvokingMachineStates, InvokingMachineEvents } from './invoking-states-events-actions';

export type GenerateInvokingStateMachineType<TData> = (action: string) => TData;

// This is a generic state machine which supports the behavior
// of invoking a single action via repetitive states i.e idle, invoking, success, failure
export const generateInvokingStateMachine: GenerateInvokingStateMachineType<any> =  (action) => {
	return {
		initial: InvokingMachineStates.Idle,
		states: {
			[InvokingMachineStates.Idle]: {
				on: {
					[InvokingMachineEvents.InitializeRequest]: InvokingMachineStates.Invoking,
				},
			},
			[InvokingMachineStates.Invoking]: {
				onEntry: action,
				on: {
					[InvokingMachineEvents.RequestSuccess]: InvokingMachineStates.Success,
					[InvokingMachineEvents.RequestFailure]: InvokingMachineStates.Failure,
				},
			},
			[InvokingMachineStates.Success]: {},
			[InvokingMachineStates.Failure]: {
				on: {
					[InvokingMachineEvents.InitializeRequest]: InvokingMachineStates.Invoking,
				},
			},
		},
	};
};

export type GenerateInvokingStateMachineNewType<TData> = (invokeAction: string, successAction: string, failAction: string) => TData;

export const generateInvokingStateMachineNew: GenerateInvokingStateMachineNewType<any> = (invokeAction, successAction, failAction) => {
	return {
		initial: InvokingMachineStates.Idle,
		states: {
			[InvokingMachineStates.Idle]: {
				on: {
					[InvokingMachineEvents.InitializeRequest]: InvokingMachineStates.Invoking,
				},
			},
			[InvokingMachineStates.Invoking]: {
				onEntry: invokeAction,
				on: {
					[InvokingMachineEvents.ValidationError]: InvokingMachineStates.Idle,
					[InvokingMachineEvents.RequestSuccess]: InvokingMachineStates.Success,
					[InvokingMachineEvents.RequestFailure]: InvokingMachineStates.Failure,
				},
			},
			[InvokingMachineStates.Success]: {
				onEntry: successAction,
			},
			[InvokingMachineStates.Failure]: {
				onEntry: failAction,
				on: {
					[InvokingMachineEvents.RequestedFailed]: InvokingMachineStates.Idle,
				},
			},
		},
	};
};
