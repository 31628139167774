import * as React from 'react';

import { OriginalAmountInput, OriginalAmountInputProps } from '../original';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './index.less';

export const OriginalPassiveAmountInput: React.FC<OriginalAmountInputProps> = ({
	value,
	className: classNameProp,
	...rest
}) => {
	const className = classNames(classNameProp, value === 0 && styles.inputZeroValue);

	return <OriginalAmountInput className={className} value={value} {...rest} />;
};
