import { observable, action } from 'mobx';

import { PageViewModelBase } from '../shared/page-view-model-base';

import { DonorPledgeEntryError, DonorPledgeEntryErrorType } from '../../components/error-message/error-message';
import { ModelMetadata } from '../../donorpledgeentry-generated';
import { MainViewModel } from '../../main/main-view-model';
import { InvokingMachineEvents } from '../../state-machine/invoking-states-events-actions';

import { ValidationState, validateFields } from '../../../WebGiving/validation/view-model-validator';
import { FormField } from '../../../WebGiving/components/form-field';
import { createRulesFromMetadata } from '../../../WebGiving/validation/validation-rules';

export class CreateUserViewModel extends PageViewModelBase {
	@observable
	firstName: FormField<string>;

	@observable
	lastName: FormField<string>;

	@observable
	emailAddress: FormField<string>;

	@observable
	validationState?: ValidationState;

	@observable
	error: DonorPledgeEntryError;

	constructor ( private vm: MainViewModel,
		firstName: string = '',
		lastName: string = '',
		emailAddress: string = '') {
		super();
		this.firstName = new FormField<string>(firstName, createRulesFromMetadata(ModelMetadata.CreateAccountRequest.FirstName));
		this.lastName = new FormField<string>(lastName, createRulesFromMetadata(ModelMetadata.CreateAccountRequest.LastName));
		this.emailAddress = new FormField<string>(emailAddress, createRulesFromMetadata(ModelMetadata.CreateAccountRequest.Email));
		vm.history.push(false);
	}

	validate(): ValidationState {
		return validateFields(this);
	}

	@action
	setError = (message: string) => {
		this.error = {
			errorType: DonorPledgeEntryErrorType.Generic,
			message,
		};
	}

	@action
	resetError = () => {
		this.error = null;
	}

	@action
	handleSubmit = () => {
		InvokingMachineEvents.raise.InitializeRequest(this.vm.machineContext);
	}

	@action
	resetForm = () => {
		this.firstName.updateValue('');
		this.lastName.updateValue('');
		this.emailAddress.updateValue('');
		this.firstName.resetField();
		this.lastName.resetField();
		this.emailAddress.resetField();
		this.resetError();
	}
}
