import {
	states,
	events,
	eventType,
} from '../../Shared/state-machine/states-events-and-actions';
import { MachineContext } from '../../Shared/state-machine/saga-state-machine';

enum InvokingMachineStateValues {
	Idle,
	Invoking,
	Success,
	Failure,
}

export const InvokingMachineStates = states(InvokingMachineStateValues);

export const InvokingMachineEvents = events({
	InitializeRequest: eventType<void>(),
	ValidationError: eventType<void>(),
	RequestSuccess: eventType<void>(),
	RequestFailure: eventType<void>(),
	RequestedFailed: eventType<void>(),
});

// actions
export const InvokingMachineActions = {
	InvokingFailureDefault: defaultFailure,
};

function* defaultFailure (machineContext: MachineContext<any>): IterableIterator<void> {
	InvokingMachineEvents.raise.RequestedFailed(machineContext);
}
