import { Task, delay, channel, buffers } from 'redux-saga';
import { take, fork, call, CallEffect, CallEffectFn, cancelled } from 'redux-saga/effects';
import { action, runInAction } from 'mobx';
import { Channel } from './user-action-channel';

export type Action = { prototype: Object };
export type ActionHandlerFn<TContext, T extends Action> = (context: TContext, action: T['prototype']) => void;
export type AsyncActionHandlerFn<TContext, T extends Action> = (context: TContext, action: T['prototype']) => IterableIterator<any>;


export interface ActionConfig {
	async: boolean;

	/**
	 * Incoming user actions will be ignored while a blocking action runs
	 * A blocking action may cancel a currently running action if it has lower priority
	 */
	blocking?: boolean;

	/**
	 * Used to make SignOut action to be more important than SubmitCurrentPage
	 */
	priority?: number;

	debounceable?: boolean;
}

export interface SyncActionConfig<TContext, T extends Action = any> extends ActionConfig {
	async: false;
	handler: ActionHandlerFn<TContext, T>;
}

export interface SyncActionHandler<TContext, T extends Action = any> extends SyncActionConfig<TContext, T> {
	actionType: T;
}

export interface AsyncActionConfig<TContext, T extends Action = any> extends ActionConfig {
	async: true;
	handler: AsyncActionHandlerFn<TContext, T>;
}

export interface AsyncActionHandler<TContext, T extends Action = any> extends AsyncActionConfig<TContext, T> {
	actionType: T;
}

export class ActionHandlers<TContext, TActionType extends Action> {
	readonly handlers: (SyncActionHandler<TContext> | AsyncActionHandler<TContext>)[] = [];

	add<T extends TActionType>(actionType: T, options: SyncActionConfig<TContext, T> | AsyncActionConfig<TContext, T>) {
		this.ensureNotADuplicate(actionType);

		const { async, handler, blocking, priority, debounceable } = options;
		const actionHandler: SyncActionHandler<TContext> | AsyncActionHandler<TContext> = {
			actionType,
			async: async as any,
			handler: handler,
			blocking,
			priority,
			debounceable,
		};

		this.handlers.push(actionHandler);

		return actionHandler;
	}

	get(action: TActionType['prototype']): SyncActionHandler<TContext> | AsyncActionHandler<TContext> {
		const [handler] = this.handlers.filter(x => action instanceof x.actionType);
		if (!handler) {
			throw new Error(`ActionHandler for ${(action as any).constructor.name} is not configured`);
		}

		return handler;
	}

	getInstructionForIncomingAction(
		runningAction: SyncActionHandler<TContext> | AsyncActionHandler<TContext>,
		incomingAction: (SyncActionHandler<TContext> | AsyncActionHandler<TContext>),
	): ActionConcurrencyInstruction {
		if (!runningAction.async) {
			// sync action cannot be running
			return ActionConcurrencyInstruction.AllowIncoming;
		}

		if (runningAction.actionType === incomingAction.actionType && runningAction.debounceable) {
			return ActionConcurrencyInstruction.CancelRunning;
		}

		// cancel the running action with lower priority the incoming action with lower priority
		if ((runningAction.priority || 0) < (incomingAction.priority || 0)) {
			return ActionConcurrencyInstruction.CancelRunning;
		}

		if (runningAction.blocking) {
			return ActionConcurrencyInstruction.BlockIncoming;
		} else {
			if (incomingAction.async) {
				// no two async action can run in parallel
				return ActionConcurrencyInstruction.BlockIncoming;
			} else {
				return ActionConcurrencyInstruction.AllowIncoming;
			}
		}
	}

	private ensureNotADuplicate(actionType: Action) {
		if (this.handlers.filter(x => x.actionType === actionType).length > 0) {
			throw new Error(`${actionType} is already configured`);
		}
	}
}

export enum ActionConcurrencyInstruction {
	BlockIncoming,
	CancelRunning,
	AllowIncoming,
}

export const callInAction: FunctionFactory<IterableIterator<any>, CallEffect> = (fn: (...args: any[]) => IterableIterator<any>, ...args: any[]): any => {
	return (call as any)(wrapSagaEffectFn(fn), ...args);
};

export const forkInAction: FunctionFactory<IterableIterator<any>, CallEffect> = (fn: (...args: any[]) => IterableIterator<any>, ...args: any[]): any => {
	return (fork as any)(wrapSagaEffectFn(fn), ...args);
};

function wrapSagaEffectFn(fn: (...args: any[]) => IterableIterator<any>) {
	const name = (fn as any).name;

	const wrapped = (...args: any[]) => {
		return wrapIteratorInMobxAction(fn(...args));
	};

	if (typeof name === 'string') {
		// keep the function name to get better saga stacks
		try {
			Object.defineProperty(wrapped, 'name', { value: name, writable: false });
		} catch (ex) {
			// phantom js does not work like any other normal browser
		}
	}

	return wrapped as CallEffectFn<any>;
}

function wrapIteratorInMobxAction<T>(iterator: IterableIterator<T>): IterableIterator<T> {
	const { next, throw: t, return: r } = iterator;

	iterator.next = action(next);
	if (t) {
		iterator.throw = action(t);
	}
	if (r) {
		iterator.return = action(r);
	}

	return iterator;
}

export type TCreateActionHandlers<TContext> = () => ActionHandlers<TContext, any>;
export type TGetActionName<TUserAction> = (userAction: TUserAction) => string | undefined;

export function* sagaLoopActionDispatcher<TUserAction, TContext>(
	options: {
		createActionHandlers: TCreateActionHandlers<TContext>,
		getActionName: TGetActionName<TUserAction>,
		userActionChannel: Channel<TUserAction>,
		reportError: (error: any, customData?: any) => void,
		context: TContext,
	},
): IterableIterator<any> {
	const { createActionHandlers, getActionName, userActionChannel, reportError, context } = options;

	const actionHandlers = createActionHandlers();

	//let executingBlockingAction;
	let currentAsyncHandler: AsyncActionHandler<TContext> | undefined;
	let currentAsyncTask: Task | undefined;

	while (true) {
		const userAction = yield take(userActionChannel);
		try {
			const incomingActionHandler = actionHandlers.get(userAction);

			if (currentAsyncHandler && currentAsyncTask && currentAsyncTask.isRunning()) {
				const instruction = actionHandlers.getInstructionForIncomingAction(currentAsyncHandler, incomingActionHandler);

				switch (instruction) {
					case ActionConcurrencyInstruction.AllowIncoming:
						console.log('Allowing incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						break;
					case ActionConcurrencyInstruction.CancelRunning:
						console.log('Cancelling running action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						// if (currentAsyncHandler.blocking) {
						// 	executingBlockingAction = false;
						// }
						currentAsyncTask.cancel();
						currentAsyncHandler = undefined;
						break;
					case ActionConcurrencyInstruction.BlockIncoming:
						console.log('Blocking incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						continue;
					default:
						throw new Error(`ActionConcurrencyInstruction[${instruction}] is not supported`);
				}
			}

			console.log('Starting action', { incoming: incomingActionHandler.actionType.name });
			if (incomingActionHandler.async) {
				currentAsyncHandler = incomingActionHandler;

				// if (currentAsyncHandler.blocking) {
				// 	executingBlockingAction = true;
				// }

				currentAsyncTask = yield fork(function* () {
					try {
						yield callInAction(incomingActionHandler.handler, context, userAction);
					} catch (ex) {
						reportError(ex, {
							sagaRunningFork: true,
							sagaUserAction: getActionName(userAction),
						});
					} finally {
						// if (!(yield cancelled())) {
						// 	executingBlockingAction = false;
						// }
					}
				});
			} else {
				runInAction(() => incomingActionHandler.handler(context, userAction));
			}
		} catch (ex) {
			reportError(ex, {
				sagaUserAction: getActionName(userAction),
			});
		}
	}
}

type Func0<TReturnType> = () => TReturnType;
type Func1<T1, TReturnType> = (arg1: T1) => TReturnType;
type Func2<T1, T2, TReturnType> = (arg1: T1, arg2: T2) => TReturnType;
type Func3<T1, T2, T3, TReturnType> = (arg1: T1, arg2: T2, arg3: T3) => TReturnType;
type Func4<T1, T2, T3, T4, TReturnType> = (arg1: T1, arg2: T2, arg3: T3, arg4: T4) => TReturnType;
type Func5<T1, T2, T3, T4, T5, TReturnType> = (arg1: T1, arg2: T2, arg3: T3,
	arg4: T4, arg5: T5) => TReturnType;
type Func6Rest<T1, T2, T3, T4, T5, T6, TReturnType> = (arg1: T1, arg2: T2, arg3: T3,
	arg4: T4, arg5: T5, arg6: T6,
	...rest: any[]) => TReturnType;

interface FunctionFactory<TFnReturnType, TReturnType> {
	(fn: Func0<TFnReturnType>): TReturnType;
	<T1>(fn: Func1<T1, TFnReturnType>,
		arg1: T1): TReturnType;
	<T1, T2>(fn: Func2<T1, T2, TFnReturnType>,
		arg1: T1, arg2: T2): TReturnType;
	<T1, T2, T3>(fn: Func3<T1, T2, T3, TFnReturnType>,
		arg1: T1, arg2: T2, arg3: T3): TReturnType;
	<T1, T2, T3, T4>(fn: Func4<T1, T2, T3, T4, TFnReturnType>,
		arg1: T1, arg2: T2, arg3: T3, arg4: T4): TReturnType;
	<T1, T2, T3, T4, T5>(fn: Func5<T1, T2, T3, T4, T5, TFnReturnType>,
		arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5): TReturnType;
	<T1, T2, T3, T4, T5, T6>(fn: Func6Rest<T1, T2, T3, T4, T5, T6, TFnReturnType>,
		arg1: T1, arg2: T2, arg3: T3,
		arg4: T4, arg5: T5, arg6: T6, ...rest: any[]): TReturnType;
}

export interface IProcessLatestRequest {
	runImmediately?: boolean;
}

interface IProcessLatestConfiguration<T extends IProcessLatestRequest> {
	requestChannel: Channel<T>;
	worker: (message: T) => any;
	debouncingInterval: number;
	onError?: (error: Error) => void;
	onStartProcessing?: () => any;
	onFinishProcessing?: () => any;
}

export function* processLatest<T>(configuration: IProcessLatestConfiguration<T>): IterableIterator<any> {
	let debouncingTask: Task | null = null;
	let latestRequest: T;
	const innerChannel = channel<T>(buffers.sliding(1));

	yield fork(function* innerProcessTask() {
		while (true) {
			let message = yield take(innerChannel);

			if (configuration.onStartProcessing) {
				configuration.onStartProcessing();
			}

			try {
				yield call(configuration.worker, message);

				// No incoming task and the latest request has been processed
				if (debouncingTask && !debouncingTask.isRunning() && message === latestRequest && configuration.onFinishProcessing) {
					configuration.onFinishProcessing();
				}
			} catch (ex) {
				if (typeof configuration.onError === 'function') {
					yield call(configuration.onError, ex);
				} else {
					throw ex;
				}
			} finally {
				if (yield cancelled()) {
					throw new Error('This is never supposed to happen. The processing saga should run until completion once started!!');
				}
			}
		}
	});

	while (true) {
		let request = yield take(configuration.requestChannel);

		if (debouncingTask) {
			yield debouncingTask.cancel();
		}

		debouncingTask = yield fork(function* processLatestDebouncingTask() {
			if (!request.runImmediately) {
				yield call(delay, configuration.debouncingInterval);
			}
			innerChannel.put(request);
			latestRequest = request;
		});
	}
}
