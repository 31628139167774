import { getDonorPledgeEntryDataService } from "../../donorpledgeentry-data-service";
import {
	EnterCreatePledgeConfirmationCodeRequest,
	ResendCreatePledgeCodeRequest,
	ResendCreatePledgeCodeResponse,
	EnterCreatePledgeConfirmationCodeResultStatus,
	EnterCreatePledgeConfirmationCodeAlreadyExistsResponse,
	EnterCreatePledgeConfirmationCodeErroneousCodeResponse,
	EnterCreatePledgeConfirmationCodeServiceUnavailableResponse,
	EnterCreatePledgeConfirmationCodeSuccessResponse,
} from "../../donorpledgeentry-generated";
import { MainViewModel } from "../../main/main-view-model";
import { Actions, Events } from "../../state-machine/states-events-actions";
import { SecurityCodeEvents } from '../../state-machine/security-code-states-events-actions';
import { generateSecurityCodeMachineNew } from '../../state-machine/security-code-state-machine';
import { ICancellablePromise } from "../../../LoggedInWeb/utils/cancellable-promise";
import { MachineContext } from "../../../Shared/state-machine/saga-state-machine";
import { confirmationCodeSentMessage } from "../../../WebGiving/helpers/notification-message-helper";
import { NotificationViewModel } from "../../../WebGiving/components/notification/notification";
import { scrollErrorIntoViewOnNextFrame } from "../../../WebGiving/utils/error-focuser";

const genericErrorMessage = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;

export const EnterCreatePledgeCodeActions = {
	PostCreatePledgeCode: postCreatePledgeCode,
	ResendCreatePledgeCode: postResendCreatePledgeCode,
	ResendCreatePledgeCodeByVoice: postResendCreatePledgeCodeByVoice,
	CreatePledgeCodeSuccess: createPledgeCodeSuccess,
};

export const enterCreatePledgeCodeMachineConfig = () =>
		generateSecurityCodeMachineNew(Actions.PostCreatePledgeCode, Actions.ResendCreatePledgeCode, Actions.ResendCreatePledgeCodeByVoice, Actions.CreatePledgeCodeSuccess);

function* postResendCreatePledgeCode(machineContext: MachineContext<MainViewModel>): IterableIterator<ICancellablePromise<ResendCreatePledgeCodeResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		confirmCreatePledgeViewModel: {
			normalizedInternationalNumber,
		},
	} = mainViewModel;

	const request = {
			NormalizedInternationalNumber: normalizedInternationalNumber,
		} as ResendCreatePledgeCodeRequest;

	yield* resendCreatePledgeCode(machineContext, request);
}

function* postResendCreatePledgeCodeByVoice(machineContext: MachineContext<MainViewModel>): IterableIterator<ICancellablePromise<ResendCreatePledgeCodeResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		confirmCreatePledgeViewModel: {
			normalizedInternationalNumber,
		},
	} = mainViewModel;

	const request = {
			NormalizedInternationalNumber: normalizedInternationalNumber,
			UseVoiceCall: true,
		} as ResendCreatePledgeCodeRequest;

	yield* resendCreatePledgeCode(machineContext, request);
}

function* resendCreatePledgeCode(machineContext: MachineContext<MainViewModel>, request: ResendCreatePledgeCodeRequest): IterableIterator<ICancellablePromise<ResendCreatePledgeCodeResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		userPrefersVoiceCalls,
		showNotification,
		confirmCreatePledgeViewModel: {
			setRequestCode,
			setError,
		},
	} = mainViewModel;

	try {
		const response = yield getDonorPledgeEntryDataService().resendCreatePledgeCode({ model: request }, () => (mainViewModel));
		if (response.Success) {
			setRequestCode(response.RequestToken);
		} else {
			setError(response.Reason);
		}

		const notificationText = confirmationCodeSentMessage(userPrefersVoiceCalls);
		const notificationViewModel = new NotificationViewModel(notificationText, 10);
		showNotification(notificationViewModel);
	} catch {
		setError(genericErrorMessage);
	}
	SecurityCodeEvents.raise.CodeResent(machineContext);
}

type EnterCreatePledgeConfirmationCodeResponse =
	| EnterCreatePledgeConfirmationCodeSuccessResponse
	| EnterCreatePledgeConfirmationCodeAlreadyExistsResponse
	| EnterCreatePledgeConfirmationCodeServiceUnavailableResponse
	| EnterCreatePledgeConfirmationCodeErroneousCodeResponse;

function* postCreatePledgeCode(machineContext: MachineContext<MainViewModel>) {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		disableValidation,
		amount,
		setAmount,
		setPersonHasPledged,
		confirmCreatePledgeViewModel: {
			securityCode,
			requestCode,
			rememberMobileNumber,
			setError,
			resetSecurityCode,
		},
		pledgeExistsViewModel:{
			setRecurringInfo,
			setPledgeProgressUrl,
		},
		pledgeSuccessViewModel: { setAmount: setSuccessPageAmount, setPledgeProgressUrl: setSuccessPagePledgeProgressUrl },
		recurringOption
	} = mainViewModel;

	if (!disableValidation) {
		const validationState = mainViewModel.confirmCreatePledgeViewModel.validate();
		if (!validationState.isValid) {
			mainViewModel.confirmCreatePledgeViewModel.validationState = validationState;
			scrollErrorIntoViewOnNextFrame();
			SecurityCodeEvents.raise.ValidationError(machineContext);
			return;
		}
	}

	const request = recurringOption
		? {
			model: {
				Code: securityCode.value,
				RequestToken: requestCode,
				RememberMe: rememberMobileNumber.value,
				Amount: amount,
				RecurringDetails: {
					PledgedAmount: amount.toString(),
					RecurringAmount: recurringOption.amount.toString(),
					FrequencyCode: recurringOption.code, 
					StartDate: recurringOption.starts,
					EndDate: recurringOption.ends
				}
			} as EnterCreatePledgeConfirmationCodeRequest,
		}
		: {
			model: {
				Code: securityCode.value,
				RequestToken: requestCode,
				RememberMe: rememberMobileNumber.value,
				Amount: amount,
			} as EnterCreatePledgeConfirmationCodeRequest,
		};

	try {
		const response: EnterCreatePledgeConfirmationCodeResponse = yield getDonorPledgeEntryDataService().enterCreatePledgeConfirmationCode(request, () => (mainViewModel));

		switch (response.Status) {
			case EnterCreatePledgeConfirmationCodeResultStatus.Success:
				if(response.WebGivingRedirectUrl) {
					window.location.href = decodeURIComponent(response.WebGivingRedirectUrl);
				}
				else {
					setSuccessPagePledgeProgressUrl(response.CampaignsRedirectUrl);
					resetSecurityCode();
					setSuccessPageAmount(amount);
					setAmount(0);
					setPersonHasPledged(true);
					SecurityCodeEvents.raise.RequestSuccess(machineContext);
				}
				break;
			case EnterCreatePledgeConfirmationCodeResultStatus.UserAlreadyPledged:
				setAmount(0);
				setPersonHasPledged(true);
				setPledgeProgressUrl(response.CampaignsRedirectUrl);
				setRecurringInfo({ 
					amount,
					option: recurringOption,
					redirectUrl: response.WebGivingRedirectUrl 
				});
				Events.raise.UserAlreadyPledged(machineContext);
				break;
			case EnterCreatePledgeConfirmationCodeResultStatus.ConfirmationCodeError:
				if (response.ConfirmationCodeInvalid) {
					securityCode.setServerSideValidationError(response.Reason);
				} else {
					setError(response.Reason);
				}
				SecurityCodeEvents.raise.RequestFailure(machineContext);
				break;
			default:
				setError(genericErrorMessage);
				SecurityCodeEvents.raise.RequestFailure(machineContext);
				break;
		}
	} catch {
		setError(genericErrorMessage);
		SecurityCodeEvents.raise.RequestFailure(machineContext);
	}
}

function createPledgeCodeSuccess(machineContext: MachineContext<MainViewModel>) {
	Events.raise.UserAuthenticated(machineContext);
}
