import { observable, action } from 'mobx';

export class ScrollStateStore {
	@observable isAtTopOfThePage = true;

	constructor() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	}

	@action.bound
	handleScroll() {
		this.isAtTopOfThePage = window.pageYOffset <= 0 && document.documentElement.scrollTop <= 0;
	}
}

let scrollStateStore: ScrollStateStore;

export function getScrollStateStore(): ScrollStateStore {
	if (!scrollStateStore) {
		scrollStateStore = new ScrollStateStore();
	}
	return scrollStateStore;
}
