import { action } from 'mobx';
import { PostError } from '../../Shared/utils/ajax-client';
import { ResetPageReason } from '../donorpledgeentry-generated';

export const defaultErrorMessage: string = `Our system encountered an unexpected error. We're currently looking into it. Please try again soon.`;
export const getErrorInfo = action(getErrorInfoInsideAction); // Can't use @action decorator in a module.

export type ErrorInfo = {
	error: any;
	message?: string;
	resetPageReason?: ResetPageReason;
};

export class ConfirmGiftPostError {
	error: any;
	message?: string;

	constructor(public errorType: string, public paymentVerb: string) {
		this.error = errorType;
		this.message = getPaymentConfirmationErrorMessage(paymentVerb);
	}
}

function getErrorInfoInsideAction(e: any): ErrorInfo {
	if (e instanceof PostError && e.validationErrors) {
		return {
			error: e,
			message: `Our system encountered an unexpected error. We're currently looking into it. Please ensure your details are correct.`,
		};
	} else if (e instanceof PostError && e.shouldReloadPage) {
		const resetPageReason = getResetPageReason(e);
		return {
			error: e,
			resetPageReason,
		};
	} else if (e instanceof ConfirmGiftPostError) {
		return {
			error: e,
			message: e.message,
		};
	} else {
		return {
			error: e,
			message: defaultErrorMessage,
		};
	}
}

function getResetPageReason(e: PostError) {
	const isAuthFailure = [401, 403].indexOf(e.client.status) > -1;
	if (isAuthFailure) {
		return ResetPageReason.PersonNotFound;
	}

	const reasonName = e.userError as keyof typeof ResetPageReason;
	const reason: ResetPageReason = ResetPageReason[reasonName] || ResetPageReason.Unknown;
	return reason;
}

export function getPaymentConfirmationErrorMessage(paymentVerb: string) {
	return `Sorry, we ran into some trouble while processing your ${paymentVerb}.
Please check your inbox to verify if this ${paymentVerb} was successful. If you do not receive a ${paymentVerb} confirmation within 5 minutes, please try again.`;
}
