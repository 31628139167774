let dateInputSupported: boolean | undefined = undefined;
let touchSupported: boolean | null = null;
let isInternetExplorer: boolean | null = null;

export function supportsDateInput(): boolean {
	if (dateInputSupported === undefined) {
		const test = document.createElement('input');

		try {
			test.type = 'date';
			dateInputSupported = test.type === 'date';
		} catch (error) {
			dateInputSupported = false;
		}
	}

	return dateInputSupported;
}

export function isTouchSupported(): boolean {
	if (touchSupported === null) {
		touchSupported = 'ontouchstart' in document;
	}

	return touchSupported;
}

export function isIE(): boolean {
	if (isInternetExplorer === null) {
		const d = document as any;
		//documentMode is specific to internet explorer. Edge is NOT included
		const documentMode: number | undefined = d.documentMode;
		if (documentMode) {
			isInternetExplorer = true;
			return isInternetExplorer;
		}

		isInternetExplorer = false;
	}

	return isInternetExplorer;
}
