import { observable } from "mobx";
import {
	CreatePledgeRequest,
	Currency,
	PledgeLabel
} from "../../donorpledgeentry-generated";
import { DonorPledgeEntryError, DonorPledgeEntryErrorType } from '../../components/error-message/error-message';
import { Events } from "../../state-machine/states-events-actions";
import { MainViewModel } from "../../main/main-view-model";
import { InvokingMachineEvents } from "../../state-machine/invoking-states-events-actions";
import { OptionalRecurringOption } from "../../utils/recurring-utils";

export class ConfirmPledgeViewModel {
	get amount(): number {
		return this.vm.amount;
	}

	get isLoading(): boolean {
		return this.vm.isAppLoading;
	}

	currency: Currency;
	campaignName: string;
	pledgeLabel: PledgeLabel;

	@observable
	recurringOption: OptionalRecurringOption;

	@observable
	error: DonorPledgeEntryError;

	constructor(private vm: MainViewModel) {
		this.currency = vm.campaignInfo.Currency;
		this.campaignName = vm.campaignInfo.CampaignName;
		this.pledgeLabel = vm.organization.PledgeLabel;
		vm.history.push(false);
	}

	handleEdit = () => {
		this.resetError();
		Events.raise.PledgeRejected(this.vm.machineContext);
	}

	handleSubmit = () => {
		const request = this.recurringOption
		? { 
			Amount: this.amount,
			RecurringDetails: {
				PledgedAmount: this.amount.toString(),
				RecurringAmount: this.recurringOption.amount.toString(),
				FrequencyCode: this.recurringOption.code, 
				StartDate: this.recurringOption.starts,
				EndDate: this.recurringOption.ends
			}} as CreatePledgeRequest | any
		: { Amount: this.amount } as CreatePledgeRequest | any;
		
		InvokingMachineEvents.raise.InitializeRequest(this.vm.machineContext, request);
	}

	setRecurringOption(option: OptionalRecurringOption)
	{
		this.recurringOption = option
	}

	setError = (errorMessages: string[]) => {
		this.error = {
			errorType: DonorPledgeEntryErrorType.CreatePledgeError,
			errorMessageLines: errorMessages,
		};
	}

	resetError = () => {
		this.error = null;
	}
}
