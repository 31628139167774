import * as React from 'react';

interface ILogoProps {
	logoUrl: string;
	legalName: string;
	websiteUrl: string | null;
	logoClass: string;
	alt?: string | null;
}

export function OrganizationLogo({ logoUrl, legalName, websiteUrl, logoClass, alt }: ILogoProps) {
	const altText = alt || '';
	const logo = <img data-pp-at-target="Organization logo" className={logoClass} src={logoUrl} alt={altText} />;
	if (websiteUrl) {
		return <a href={websiteUrl}>{logo}</a>;
	}
	return logo;
}
