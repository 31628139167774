import { observable, action, computed } from 'mobx';

import { donorPledgeEntryMachineConfig } from '../state-machine/state-machine';
import { Actions, Events, EnterMobileNumberSubStatesNew, EnterSecurityCodeSubStates, EnterCreatePledgeCodeSubStates } from '../state-machine/states-events-actions';
import {
	BrandingPackage,
	Currency,
	DonorPledgeEntryPersonModel,
	DonorPledgeEntryPushpayInfo,
	DonorPledgeEntryViewModel,
	Theme,
	WebGivingMobileNumber,
	DonorPledgeEntryOrganization,
	DonorPledgeEntryCampaignInfo,
} from '../donorpledgeentry-generated';

import { ModalType } from '../modals/modal-type';
import { EnterPledgeViewModel } from '../pages/enter-pledge/enter-pledge-view-model';
import { EnterMobileNumberViewModel } from '../pages/enter-mobile-number/enter-mobile-number-view-model';
import { EnterMobileNumberEvents } from '../pages/enter-mobile-number/enter-mobile-number-machine-config';
import { EnterSecurityCodeViewModel } from '../pages/enter-security-code/enter-security-code-view-model';
import { CreateUserViewModel } from '../pages/create-user/create-user-view-model';
import { ConfirmPledgeViewModel } from '../pages/confirm-pledge/confirm-pledge-view-model';
import { PledgeExistsViewModel } from '../pages/pledge-exists/pledge-exists-view-model';
import { PledgeSuccessViewModel } from '../pages/pledge-success/pledge-success-view-model';
import { ConfirmCreatePledgeViewModel } from '../pages/confirm-create-pledge/confirm-create-pledge-view-model';
import { SecurityCodeEvents } from '../state-machine/security-code-states-events-actions';

import { connectMachine, IHaveMachine } from '../../Shared/state-machine/connect-machine';
import { MachineContext } from '../../Shared/state-machine/saga-state-machine';
import { ScrollStateStore, getScrollStateStore } from '../../Shared/utils/scroll-state-store';
import { userActionChannel, Channel } from '../../Shared/utils/user-action-channel';
import { NotificationViewModel } from '../../WebGiving/components/notification/notification';
import { HistoryAdapter } from '../../WebGiving/utils/history-adapter';

import { WebGivingUserAction } from '../../WebGiving/webgiving-user-actions';
import { OptionalRecurringOption } from '../utils/recurring-utils';

@connectMachine(donorPledgeEntryMachineConfig, Actions.handlers)
export class MainViewModel implements IHaveMachine {
	readonly machineContext: MachineContext;
	readonly disableValidation: boolean;
	readonly pushpayInfo: DonorPledgeEntryPushpayInfo;
	readonly organization: DonorPledgeEntryOrganization;
	readonly campaignInfo: DonorPledgeEntryCampaignInfo;

	@observable
	brandingPackage: BrandingPackage | null;

	@observable
	enterPledgeViewModel: EnterPledgeViewModel;

	@observable
	enterMobileNumberViewModel: EnterMobileNumberViewModel;

	@observable
	enterSecurityCodeViewModel: EnterSecurityCodeViewModel;

	@observable
	createUserViewModel: CreateUserViewModel;

	@observable
	confirmPledgeViewModel: ConfirmPledgeViewModel;

	@observable
	pledgeExistsViewModel: PledgeExistsViewModel;

	@observable
	confirmCreatePledgeViewModel: ConfirmCreatePledgeViewModel;

	@observable
	pledgeSuccessViewModel: PledgeSuccessViewModel;

	@observable
	amount: number;

	@observable
	currency: Currency;

	@observable
	campaignName: string;

	@observable
	campaignDescription: string;

	@observable
	person: DonorPledgeEntryPersonModel | null;

	@observable
	rememberMe: boolean;

	@observable
	confirmedMobileNumber: WebGivingMobileNumber | null = null;

	@computed
	get defaultMobileNumber(): string {
		if(!!this.confirmedMobileNumber) {
			return this.confirmedMobileNumber.NationalNumber;
		} else if(!!this.person) {
			return this.person.MobileNumber.NationalNumber;
		}
		return '';
	}

	@computed
	get visibleModal(): ModalType | null {
		if (this.machineContext.matchesAnyState([
				EnterMobileNumberSubStatesNew.NeedHelp,
				EnterSecurityCodeSubStates.NeedHelp,
				EnterSecurityCodeSubStates.NeedHelpResent,
				EnterCreatePledgeCodeSubStates.NeedHelp,
				EnterCreatePledgeCodeSubStates.NeedHelpResent])) {
			return ModalType.NeedHelp;
		}
		if (this.machineContext.matchesState(EnterMobileNumberSubStatesNew.HowDoesMobileSignInWork)) {
			return ModalType.MobileSignInInfo;
		}
		return null;
	}

	@observable
	resentCodeState(): boolean {
		return this.machineContext.matchesAnyState([
				EnterSecurityCodeSubStates.NeedHelpResent,
				EnterCreatePledgeCodeSubStates.NeedHelpResent]);
	}

	@observable
	userPrefersVoiceCalls: boolean = false;

	@observable
	correlationId: string;

	@observable
	merchantPaymentMin: number;

	@observable
	merchantPaymentMax: number;

	@observable
	merchantAchMax?: number | null;

	@observable
	organizationRecurringOptions: {
		hasQuarterlyRecurringGivingEnabled: boolean,
		hasSemiYearlyRecurringGivingEnabled: boolean,
		hasYearlyRecurringGivingEnabled: boolean,
	};

	@observable
	isAppLoading: boolean = false;

	@observable
	personHasPledged: boolean = false;

	@observable
	pledgeProgressUrl: string;

	@observable
	giveToCampaignUrl: string;

	@observable
	theme: Theme;

	@observable
	scrollStateStore: ScrollStateStore = getScrollStateStore();

	@observable
	isAccountDetailsOpen: boolean;

	resendCodeDisabled = observable.box(false);

	@observable
	recurringOption: OptionalRecurringOption;

	@observable
	notification: NotificationViewModel;

	userActionChannel: Channel<WebGivingUserAction> = userActionChannel();

	history = new HistoryAdapter('pp-dpe-key');

	constructor(model: DonorPledgeEntryViewModel, disableValidation: boolean = false) {
		const {
			Theme,
			PushpayInfo,
			OrganizationInfo,
			ConfirmedMobileNumber,
			CampaignInfo: {
				CampaignName,
				Description,
				Currency,
				GiveToCampaignUrl,
				PledgeProgressUrl,
			},
		} = model;

		const {
			MerchantPaymentMin,
			MerchantPaymentMax,
			MerchantAchMax,
			HasQuarterlyRecurringGivingEnabled,
			HasSemiYearlyRecurringGivingEnabled,
			HasYearlyRecurringGivingEnabled,
		} = model.OrganizationInfo;

		this.disableValidation = disableValidation;
		this.pushpayInfo = PushpayInfo;
		this.organization = OrganizationInfo;
		this.campaignInfo = model.CampaignInfo;
		this.theme = Theme;
		this.brandingPackage = model.BrandingPackage || <BrandingPackage>{
			EnhancedLogoImageUrl: Theme.MerchantLogoUrl,
			PrimaryColor: Theme.PrimaryColor,
			SecondaryColor: Theme.SecondaryColor,
		};

		this.amount = NewFeatures.EnhancedDonorAuth_IdentityVerification_DPE ? (model.Amount || 0) : 0;
		this.currency = Currency;
		this.campaignName = CampaignName;
		this.campaignDescription = Description;
		this.person = model.Person;
		this.rememberMe = model.ClientIsMobilePhone || this.person instanceof Object;
		this.personHasPledged = this.person ? this.person.PledgeExists : false;
		this.pledgeProgressUrl = PledgeProgressUrl;
		this.giveToCampaignUrl = GiveToCampaignUrl;

		this.confirmedMobileNumber = ConfirmedMobileNumber || (model.Person ? model.Person.MobileNumber : null);
		this.correlationId = model.SessionCorrelationId;
		this.merchantPaymentMin = MerchantPaymentMin;
		this.merchantPaymentMax = MerchantPaymentMax;
		this.merchantAchMax = MerchantAchMax;
		this.organizationRecurringOptions = {
			hasQuarterlyRecurringGivingEnabled: HasQuarterlyRecurringGivingEnabled,
			hasSemiYearlyRecurringGivingEnabled: HasSemiYearlyRecurringGivingEnabled,
			hasYearlyRecurringGivingEnabled: HasYearlyRecurringGivingEnabled,
		}

		this.enterPledgeViewModel = new EnterPledgeViewModel(this);
		this.enterMobileNumberViewModel = new EnterMobileNumberViewModel(this, this.defaultMobileNumber, OrganizationInfo.HomeCountry);
		this.enterSecurityCodeViewModel = new EnterSecurityCodeViewModel(
			this,
			this.defaultMobileNumber,
			false,
			this.resendCodeDisabled,
		);
		this.createUserViewModel = new CreateUserViewModel(this);
		this.pledgeExistsViewModel = new PledgeExistsViewModel(this);
		this.confirmPledgeViewModel = new ConfirmPledgeViewModel(this);
		this.confirmCreatePledgeViewModel = new ConfirmCreatePledgeViewModel(
			this,
			this.defaultMobileNumber,
			false,
			this.resendCodeDisabled,
		);
		this.pledgeSuccessViewModel = new PledgeSuccessViewModel(this);
	}

	@action
	setIsAccountDetailsOpen = (state: boolean) => {
		this.isAccountDetailsOpen = state;
	}

	@action
	setAmount = (amount: number) => {
		this.amount = amount;
		this.enterPledgeViewModel.setAmount(amount);
	}

	@action
	setConfirmedMobileNumber = (mobileNumber?: WebGivingMobileNumber) => {
		if(!!mobileNumber) {
			this.confirmedMobileNumber = mobileNumber;
			this.setMobileNumber(mobileNumber.NationalNumber);
		}
	}

	setMobileNumber = (mobileNumber: string) => {
		this.enterMobileNumberViewModel.setMobileNumber(mobileNumber);
		this.enterSecurityCodeViewModel.setMobileNumber(mobileNumber);
		this.confirmCreatePledgeViewModel.setMobileNumber(mobileNumber);
	}

	@action
	setUserPrefersVoiceCalls = (preference: boolean) => {
		this.userPrefersVoiceCalls = preference;
	}

	@action
	setRememberMe = (rememberMe: boolean) => {
		this.rememberMe = rememberMe;
	}

	@action
	setPerson = (person?: DonorPledgeEntryPersonModel) => {
		if(!!person) {
			this.person = person;
			this.personHasPledged = person.PledgeExists;
		} else {
			this.person = null;
			this.setPersonHasPledged(false);
		}
	}

	@action
	setRecurringOption = (option: OptionalRecurringOption) => {
		this.recurringOption = option;
		this.confirmPledgeViewModel.setRecurringOption(option);
	}

	@action
	setPersonHasPledged = (hasPledged: boolean) => {
		this.personHasPledged = hasPledged;
	}

	@action
	raiseAction = (action: WebGivingUserAction) => {
		this.userActionChannel.put(action);
	}

	@action
	reportError = (error: any, customData?: any) => {
		/*
		const errorInfo = getErrorInfo(error);

		if (typeof errorInfo.resetPageReason === 'number') {
			this.currentPageViewModel.error = {
				errorType: WebGivingErrorType.ShouldReloadPage,
				reason: errorInfo.resetPageReason,
				paymentLabel: this.viewData.MerchantInfo.PaymentLabel,
				resetPasswordUrl: this.viewData.PushpayInfo.ResetPasswordUrl,
			};
			this.currentPageViewModel.isBlocked = true;
		} else {
			this.currentPageViewModel.error = {
				errorType: WebGivingErrorType.Generic,
				message: errorInfo.message || defaultErrorMessage,
			};
		}

		reportError(errorInfo.error, this.currentPage, customData);
		*/
	}

	@action
	showNotification = (notification: NotificationViewModel) => {
		this.notification = notification;
	}

	@action
	closeModal = () => {
		Events.raise.CloseModal(this.machineContext);
	}

	@action
	sendVoiceCall = () => {
		EnterMobileNumberEvents.raise.RequestCodeByVoice(this.machineContext);
	}

	@action
	resendVoiceCall = () => {
		SecurityCodeEvents.raise.RequestCodeByVoice(this.machineContext);
	}

	@action
	resendTextMessage = () => {
		SecurityCodeEvents.raise.RequestCodeResend(this.machineContext);
	}

	handleUserSignOut = () => {
		Events.raise.UserSignedOut(this.machineContext);
	}

	isPage = (pageState: string | string[]): boolean => {
		return this.machineContext && this.machineContext.matchesState(pageState);
	}
}
