import { FormField } from '../../../WebGiving/components/form-field';
import { action, observable, reaction } from 'mobx';
import { ValidationState, validateFields } from '../../../WebGiving/validation/view-model-validator';
import {
	createValidatorFunc,
	FieldValidationFailure,
	fieldValidationSuccess,
	RuleType,
	ValidatorFunc,
	alwaysValid,
} from '../../../WebGiving/validation/validation-rules';
import { Formatter } from '../../../LoggedInWeb/helpers/formatter';
import { PageViewModelBase } from '../shared/page-view-model-base';
import { MainViewModel } from '../../main/main-view-model';
import { EnterPledgeEvents } from './enter-pledge-machine-config';
import { BrandingPackage, PaymentLabel, PledgeLabel } from '../../donorpledgeentry-generated';
import { States, Events } from '../../state-machine/states-events-actions';
import { FrequencyCode } from '../../../LoggedInWeb/loggedinweb-generated';
import pledgeIcon from '../../assets/svg/pledge.svg';
import recurringIcon from '../../../WebGiving/assets/svg/recurring.svg';
import {
	getRecurringBaseRepeats,
	PledgeType,
	RecurringOption,
    dateFormat,
    getValidDate,
    OptionalRecurringOption,
	getRecurringOptions,
} from '../../utils/recurring-utils';

import { IFormControlSplitButtonOptions } from '../../../WebGiving/components/split-button';
import { OptionalDate } from '../../../WebGiving/components/date-picker/date-picker';

export class EnterPledgeViewModel extends PageViewModelBase {

	paymentLabel: PaymentLabel;

	@observable
	validationState?: ValidationState;

	@observable
	amount: FormField<number>;

	campaignName: string;

	organizationName: string;

	campaignDescription: string;

	isLoading: boolean;

	@observable
	brandingPackage: BrandingPackage | null;

	merchantPaymentMin: number;

	merchantPaymentMax: number;

	merchantAchMax?: number | null;

	organizationRecurringOptions: {
		hasQuarterlyRecurringGivingEnabled: boolean;
		hasSemiYearlyRecurringGivingEnabled: boolean;
		hasYearlyRecurringGivingEnabled: boolean;
	};

	pledgeLabel: PledgeLabel;

	@observable
	frequency: FormField<FrequencyCode> = new FormField<FrequencyCode>(FrequencyCode.Other, alwaysValid());;

	@observable
	recurringOption: FormField<OptionalRecurringOption>;

	@observable
	recurringOptions: RecurringOption[] = [];

	@observable
	recurringEndDate: FormField<OptionalDate>;

	@observable
	recurringStartDate: FormField<OptionalDate>;

    readonly recurringMinStartDate: Date = moment().startOf('day').toDate();

	recurringMaxEndDate: Date = moment().add(3,'years').startOf('day').toDate();

    @observable
	pledgeType: FormField<PledgeType>;

	pledgeTypeOptions: IFormControlSplitButtonOptions<PledgeType>[];

	constructor(private vm: MainViewModel) {
		super();
		this.campaignName = vm.campaignName;
		this.campaignDescription = vm.campaignDescription;
		this.isLoading = vm.isAppLoading;
		this.brandingPackage = vm.brandingPackage;
		this.merchantPaymentMin = vm.merchantPaymentMin;
		this.merchantPaymentMax = vm.merchantPaymentMax;
		this.merchantAchMax = vm.merchantAchMax;
		this.pledgeLabel = vm.organization.PledgeLabel;
		this.setupAmountField(vm);

		this.pledgeType = FormField.Unvalidated(PledgeType.WithRecurring as PledgeType)
			
			this.paymentLabel = vm.organization.PaymentLabel;
			this.organizationName = vm.organization.LegalName;

			this.recurringOption = new FormField<OptionalRecurringOption>(
				undefined,
				this.createRecurringOptionValidator()
			);

			this.recurringStartDate = new FormField<OptionalDate>(this.recurringMinStartDate, this.createStartDateValidator());
			this.recurringEndDate = new FormField<OptionalDate>(
				vm.campaignInfo.EndDate ? new Date(vm.campaignInfo.EndDate) : moment().add(12,'months').startOf('day').toDate(),
				this.createEndDateValidator()
			);

			if (vm.campaignInfo.EndDate) {
				this.recurringMaxEndDate = new Date(vm.campaignInfo.EndDate);
			}

			this.organizationRecurringOptions = {
				hasQuarterlyRecurringGivingEnabled: vm.organization.HasQuarterlyRecurringGivingEnabled,
				hasSemiYearlyRecurringGivingEnabled: vm.organization.HasSemiYearlyRecurringGivingEnabled,
				hasYearlyRecurringGivingEnabled: vm.organization.HasYearlyRecurringGivingEnabled,
			};

			this.pledgeTypeOptions = [
				{
					value: PledgeType.WithRecurring,
					label: `${
						NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar
							? this.pledgeLabel.VerbSentenceCase
							: 'Pledge'
					} and set up recurring`,
					icon: recurringIcon,
				},
				{
					value: PledgeType.OneTime,
					label: `${
						NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar
							? this.pledgeLabel.VerbSentenceCase
							: 'Pledge'
					} only`,
					icon: pledgeIcon
				},
			];

			this.calculateRecurringOptions();

			reaction(
				() => [this.amount.value, this.recurringEndDate.value, this.recurringStartDate.value],
				() => this.calculateRecurringOptions()
			);

		vm.history.start(() => {
			const isFirstPage = vm.isPage(States.EnterPledgeAmount);
			if (isFirstPage) {
				return false;
			}

			Events.raise.HistoryBackTriggered(vm.machineContext);

			return true;
		});
	}

	setupAmountField(vm: MainViewModel) {
		const minPaymentAmount = vm.merchantPaymentMin ? vm.merchantPaymentMin : 1;

		let maxPaymentAmount;
		if (vm.merchantAchMax) {
			maxPaymentAmount = vm.merchantAchMax > vm.merchantPaymentMax ? vm.merchantAchMax : vm.merchantPaymentMax;
		} else {
			maxPaymentAmount = vm.merchantPaymentMax;
		}

		this.amount = new FormField<number>(vm.amount, this.createAmountValidator(minPaymentAmount, maxPaymentAmount));
	}

	@action
	setAmount = (amount: number) => {
		if(amount === 0) {
			this.resetAmount();
		} else {
			this.amount.updateValue(amount);
		}
	}

	@action
	resetAmount = () => {
		this.amount.updateValue(0);
		this.amount.resetField();
	}

	validate(): ValidationState {
		return validateFields(this, 'Please enter valid details');
	}

	@action
	handleSubmit = () => {
		if (this.pledgeType.value === PledgeType.OneTime) {
			this.resetRecurringOption();
		}

		EnterPledgeEvents.raise.PledgeSubmitted(this.vm.machineContext);
	}

	@action
	setRecurringOption = (value: FrequencyCode) => {
		const option = this.recurringOptions.find(opt => opt.code === value);
		this.recurringOption.updateValue(option);
		this.recurringOption.revalidate();
	};

	@action
	setRecurringStartDate = (date: OptionalDate) => {
		this.recurringStartDate.updateValue(date);
	};

	@action
	setRecurringEndDate = (date: OptionalDate) => {
		this.recurringEndDate.updateValue(date);
	};

    @action
	resetRecurringOption = () => {
		this.recurringOption.updateValue(undefined);
		this.recurringOption.resetField();
		this.frequency.updateValue(FrequencyCode.Other);
	};

	@action
	calculateRecurringOptions = () => {
		const amount = this.amount.value;
		var freqs: RecurringOption[] = [];
        const startDate = getValidDate(this.recurringStartDate.value);
        const endDate = getValidDate(this.recurringEndDate.value);

		if (amount && startDate && endDate) {
			freqs = getRecurringOptions({
				amount: amount,
				paymentMax: Math.min(this.merchantPaymentMax, this.merchantAchMax || this.merchantPaymentMax),
				paymentMin: this.merchantPaymentMin,
				startDate,
				endDate,
				options: getRecurringBaseRepeats(
					{
						start: startDate,
						end: endDate,
						...this.organizationRecurringOptions,
					})
			})
		}

		const lastSelectedFrequency = this.recurringOption.value === undefined
			? undefined
			: this.recurringOption.value.code;

		if(lastSelectedFrequency) {
			const option = freqs.find(opt => opt.code === lastSelectedFrequency);
			this.recurringOption.updateValue(option);
		} else {
			this.resetRecurringOption();
			this.frequency.value = FrequencyCode.Other;
		}
		this.recurringOptions = freqs;
	};

	private createAmountValidator = (minPaymentAmount: number, maxPaymentAmount: number): ValidatorFunc<number> => {
		const minAmount = 0.01, maxAmount = 1e+12;
		return createValidatorFunc((amount: number) => {
			if (amount === 0) {
				return new FieldValidationFailure(RuleType.Number, `Please enter an amount`);
			}

			if (isNaN(amount) || amount < minAmount || amount > maxAmount) {
				const minPaymentAmountFormatted = Formatter.formatNumberForDisplay(minPaymentAmount, 2);
				const maxPaymentAmountFormatted = Formatter.formatNumberForDisplay(maxPaymentAmount, 2);
				return new FieldValidationFailure(RuleType.Number, `Your amount must be between $${minPaymentAmountFormatted} and $${maxPaymentAmountFormatted}`);
			}

			return fieldValidationSuccess;
		}, RuleType.Number);
	}

	private createRecurringOptionValidator = () =>
		createValidatorFunc<OptionalRecurringOption>((option) => {
			if (this.pledgeType.value !== PledgeType.WithRecurring) {
				return fieldValidationSuccess;
			}

			if (!option) {
				return new FieldValidationFailure(RuleType.Required, 'Please select a frequency option');
			}

			return fieldValidationSuccess;
		}, RuleType.Required);

	private createStartDateValidator = () =>
		createValidatorFunc<OptionalDate>((startDate) => {
			if (this.pledgeType.value !== PledgeType.WithRecurring) {
				return fieldValidationSuccess;
			}

			if (!startDate) {
				return new FieldValidationFailure(RuleType.Required, 'Please provide a starting date');
			}

			const today = moment();
			const isBeforeToday = today.isAfter(startDate, 'day');
			if (isBeforeToday) {
				return new FieldValidationFailure(RuleType.Date, 'Starting date cannot be in the past');
			}

			return fieldValidationSuccess;
		}, RuleType.Required | RuleType.Date);

	private createEndDateValidator = () =>
		createValidatorFunc<OptionalDate>((endDate) => {
			if (this.pledgeType.value !== PledgeType.WithRecurring) {
				return fieldValidationSuccess;
			}

			if (!endDate) {
				return new FieldValidationFailure(RuleType.Required, 'Please provide an end date');
			}

			const minDate = moment(this.recurringStartDate.value);
			const maxDate = moment(this.recurringMaxEndDate);
			const isBeforeMinDate = minDate.isAfter(endDate);
			const isAfterMaxDate = maxDate.isBefore(endDate, 'day');

			if (isBeforeMinDate) {
				return new FieldValidationFailure(RuleType.Date, 'End date must be 1 day after start date or later');
			}

			if (isAfterMaxDate) {
				return new FieldValidationFailure(
					RuleType.Date,
					`End date must be before ${maxDate.format(dateFormat)}`
				);
			}

			return fieldValidationSuccess;
		}, RuleType.Required | RuleType.Date);
}

