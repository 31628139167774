import {
	events,
	eventType,
	states,
} from "../../../Shared/state-machine/states-events-and-actions";
import {
	Actions,
	Events,
} from "../../state-machine/states-events-actions";
import { InvokingMachineEvents } from '../../state-machine/invoking-states-events-actions';
import { generateInvokingStateMachineNew } from '../../state-machine/invoking-state-machine';

import { MainViewModel } from "../../main/main-view-model";
import {
	CreateAccountRequest,
	CreateAccountServiceUnavailableResponse,
	CreateAccountStatusResult,
	CreateAccountSuccessResponse,
} from "../../donorpledgeentry-generated";
import { getDonorPledgeEntryDataService, setNewCRSFoken } from "../../donorpledgeentry-data-service";

import { ICancellablePromise } from "../../../LoggedInWeb/utils/cancellable-promise";
import { MachineContext } from "../../../Shared/state-machine/saga-state-machine";

const genericErrorMessage = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;

// states
enum CreateUserStateValues {
	Idle,
	Invoking,
	Success,
	Failure,
}
export const CreateUserStates = states(CreateUserStateValues);

// events
const eventItems = {
	CreateUserSubmitted: eventType<typeof Actions.dataTypes.ProcessEnterPledge>(),
	ValidationError: eventType<void>(),
	RequestSuccess: eventType<void>(),
	RequestFailure: eventType<void>(),
	UserCreationFailed: eventType<void>(),
};
export const CreateUserEvents = events(eventItems);

// actions
export const CreateUserActions = {
	ProcessCreateUser: postCreateAccount,
	CompleteProcessCreateUser: completeProcessCreateUser,
	FailProcessCreateUser: failProcessCreateUser,
};

// config
export const createUserMachineConfig =
() => generateInvokingStateMachineNew(Actions.ProcessCreateUser, Actions.CompleteProcessCreateUser, Actions.InvokingFailureDefault);

function* postCreateAccount (machineContext: MachineContext<MainViewModel>):
IterableIterator<ICancellablePromise<CreateAccountSuccessResponse | CreateAccountServiceUnavailableResponse>> {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const { createUserViewModel, disableValidation, setPerson, rememberMe } = mainViewModel;
	const {
		firstName,
		lastName,
		emailAddress,
		setError,
		resetForm,
	} = createUserViewModel;

	if (!disableValidation) {
		const validationState = createUserViewModel.validate();
		if (!validationState.isValid) {
			createUserViewModel.validationState = validationState;
			CreateUserEvents.raise.ValidationError(machineContext);
			return;
		}
	}

	const request = {
		model: {
			FirstName: firstName.value,
			LastName: lastName.value,
			Email: emailAddress.value,
			RememberMe: rememberMe,
		} as CreateAccountRequest,
	};

	try {
		const response = yield getDonorPledgeEntryDataService().createAccount(request, () => (mainViewModel));

		switch (response.Status) {
			case CreateAccountStatusResult.Success:
				const successResponse = response as CreateAccountSuccessResponse;
				setNewCRSFoken(successResponse.Person.NewCSRFToken);
				setPerson(successResponse.Person);
				InvokingMachineEvents.raise.RequestSuccess(machineContext);
				resetForm();
				break;
			case CreateAccountStatusResult.Unknown:
			case CreateAccountStatusResult.ServiceUnavailable:
			default:
				setError(genericErrorMessage);
				InvokingMachineEvents.raise.RequestFailure(machineContext);
				break;
		}
	} catch {
		setError(genericErrorMessage);
		InvokingMachineEvents.raise.RequestFailure(machineContext);
	}
}

function* completeProcessCreateUser (machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	Events.raise.UserCreated(machineContext);
}

function* failProcessCreateUser (machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	CreateUserEvents.raise.UserCreationFailed(machineContext);
}
