import * as libphonenumber from 'google-libphonenumber';
import { Country } from '../donorpledgeentry-generated';
import { TextMask } from '../../WebGiving/utils/text-mask';
import { isPhoneNumberChar } from '../../Shared/utils/string-functions';

const numberRegExp = new RegExp(/\D/gi);
const maxSanitizedInput = 30;

export namespace PhoneNumberUtils {
	export function format(phoneNumber: string, country?: Country) {
		if (!phoneNumber) {
			return phoneNumber;
		}

		try {
			const lpn = libphonenumber.PhoneNumberUtil.getInstance();

			const number = lpn.parse(phoneNumber, getCountryCode(country));
			return lpn.format(number, libphonenumber.PhoneNumberFormat.NATIONAL);
		} catch (ex) {
			return phoneNumber;
		}
	}

	/**
	 * 1. Strip all the placeholders from the phone number
	 * 2. Format the numbers
	 * 3. Create mask fn from the formatted numbers to calculate the caret position delta
	 */
	export function phoneNumberTextMask(phoneNumber: string, country?: Country): TextMask[] {
		if (!phoneNumber) {
			return [];
		}

		// remove everything except numbers
		let sanitized = phoneNumber.replace(numberRegExp, '');

		// keep the leading + if it's present
		if (phoneNumber.charAt(0) === '+') {
			sanitized = `+${sanitized}`;
		}

		const formatter = new libphonenumber.AsYouTypeFormatter(getCountryCode(country) as string);

		let formattedOutput: string = '';
		const inputLength = Math.min(maxSanitizedInput, sanitized.length);

		for (let i = 0; i < inputLength; i++) {
			formattedOutput = formatter.inputDigit(sanitized[i]);
		}

		return formattedOutput.split('').map((char): TextMask => {
			if (isPhoneNumberChar(char)) {
				return (c: string) => c === char ? { char } : { char: '' };
			}

			// formatting placeholder
			return char;
		});
	}

	export function isValidNumberForRegion(phoneNumber: string, country: Country) {
		try {
			const lpn = libphonenumber.PhoneNumberUtil.getInstance();

			const number = lpn.parse(phoneNumber, getCountryCode(country));
			return lpn.isValidNumberForRegion(number, getCountryCode(country));
		} catch (ex) {
			// it safer to return true so we let the server-side validation to check this phone number
			return true;
		}
	}

	export function getRegionForNumber(phoneNumber: string, country?: Country): Country | undefined {
		try {
			const lpn = libphonenumber.PhoneNumberUtil.getInstance();

			const number = lpn.parse(phoneNumber, getCountryCode(country));
			const result = lpn.getRegionCodeForNumber(number);

			if (!result) {
				return undefined;
			}

			const resultAsEnum = Country[result as keyof typeof Country];
			return resultAsEnum;
		} catch (ex) {
			return undefined;
		}
	}

	export function getExampleMobileNumber(country: Country) {
		const lpn = libphonenumber.PhoneNumberUtil.getInstance();
		// typings are out of date
		const phoneNumber = (lpn as any).getExampleNumberForType(getCountryCode(country), libphonenumber.PhoneNumberType.MOBILE);

		return phoneNumber ? lpn.format(phoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL) : '';
	}

	function getCountryCode(country?: Country): string | undefined {
		if (typeof country === 'number') {
			return Country[country];
		}

		return undefined;
	}
}
