import * as React from 'react';
import { observer } from 'mobx-react';
import { classNames } from '../../Shared/utils/classnames';

@observer
export class PanelHeading extends React.Component<{
	vm: { isLoading: boolean },
	className?: string;
}, {}> {

	render() {
		return (
			<div className={classNames(
				'panel-heading',
				'text-center',
				this.props.className,
				{ 'loading': this.props.vm.isLoading })}>
				{this.props.children}
			</div>
		);
	}
}
