import { action, observable } from 'mobx';
import { ValidationState } from '../../../WebGiving/validation/view-model-validator';

export abstract class PageViewModelBase {
	@observable
	isLoading: boolean = false;

	@observable
	isBlocked: boolean = false;

	@observable
	pageShouldBeVisible: boolean = true;

	@observable
	disableValidation?: boolean;

	abstract validate(): ValidationState;

	@action.bound
	setDisableValidation(disableValidation?: boolean) {
		this.disableValidation = disableValidation;
	}
}
