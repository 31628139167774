import * as React from 'react';
import { observer } from 'mobx-react';

import { EnterSecurityCodeViewModel } from './enter-security-code-view-model';
import * as styles from './enter-security-code.less';
import { IPageBaseProps } from '../shared/page-props-base';
import { Events } from '../../state-machine/states-events-actions';
import { pageFooter } from '../../main/main.less';
import { ErrorMessage } from '../../components/error-message/error-message';

import { FadeTransition } from '../../../WebGiving/components/fade-transition';
import { PanelHeading } from '../../../WebGiving/components/panel-heading';
import { injectWebGivingProps, InjectableWebGivingProps } from '../../../WebGiving/utils/injectable-props';
import { LeftChevronLink, Link, DisabledLink } from '../../../WebGiving/primitives/link/link';
import { Form } from '../../../WebGiving/primitives/form';
import { getPageClasses } from '../../../WebGiving/pages/helpers/get-page-classes';
import { PanelContentTransition, panelContentTransitionDuration } from '../../../WebGiving/components/panel-content-transition';
import { FormControlLabel } from '../../../WebGiving/primitives/label';
import { FormControlWithIcon, FormControlIcon } from '../../../WebGiving/components/control-with-icon/control-with-icon';
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';
import { TextboxField, CheckboxField } from '../../../WebGiving/components/form-fields';
import { SvgIcon } from '../../../WebGiving/components/svg-icon';
import { FieldValidationMessage } from '../../../WebGiving/components/form-control/form-control-validation-error';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';
import { TooltipPlacement, Tooltip, StandardTooltip } from '../../../WebGiving/components/tooltip/tooltip';
import { standardButton } from '../../../WebGiving/components/tooltip/tooltip.less';
import padlocksvg from '../../../WebGiving/assets/svg/padlock.svg';
import * as ua from "ua-parser-js";

const isMobile = (() => new ua.UAParser(window.navigator.userAgent).getDevice().type === 'mobile')();

@injectWebGivingProps
@observer
export class EnterSecurityCode extends
	React.Component<IPageBaseProps<EnterSecurityCodeViewModel> & InjectableWebGivingProps>
{
	panelBodyNode: HTMLDivElement;

	render() {
		const { vm, transitionState } = this.props;
		const {
			mobileNumber,
			error,
			securityCode,
			rememberMobileNumber,
			isLoading,
			userIsRemembered,
			pageShouldBeVisible,
			isBlocked,
		} = vm;
		const elementsShouldEnter = transitionState === 'entered';
		const securityCodeId = 'security-code';

		const rememberMeVisible = !isMobile && !userIsRemembered;

		const resendCodeLink = <ResendCodeLink handleResendCode={this.handleResendCode} resentCodeState={vm.resentCodeState()} />;

		return (
			<div className={getPageClasses(pageShouldBeVisible, isLoading)} data-pp-at-target={pageShouldBeVisible ? `Confirm Mobile Number page` : null}>
				<PanelHeading vm={vm}>
					<FadeTransition in={elementsShouldEnter}>
						<div>
							<h1 className="panel-title">Verify your mobile number</h1>
							<div className="panel-subtitle">Enter the security code sent to your number <strong className={styles.mobileNumber}>{mobileNumber}</strong>. {resendCodeLink}</div>
							<LeftChevronLink acceptanceTestTargetId="Change your number" onClick={this.handleChangeMobileNumber} branded={false}>
								Change your number
							</LeftChevronLink>
						</div>
					</FadeTransition>
				</PanelHeading>
				<Form onSubmit={this.handleSubmit}>
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<PanelContentTransition in={elementsShouldEnter} setContentHeight={this.setContentHeight} className="panel-body-content">
							<div>
								<ErrorMessage error={error} />
								<div className="row">
									<div className={`col-sm-8 col-centered ${styles.securityCodeField}`}>
										<div className="form-control-container">
											<FormControlLabel formControlId={securityCodeId}>Security code</FormControlLabel>
											<FormControlWithIcon iconPlacement="right">
												<FocusOnMount delay={panelContentTransitionDuration}>
													<TextboxField
														formField={securityCode}
														formControlId={securityCodeId}
														acceptanceTestTargetId="Confirmation Code"
														numericKeyboard
														autoComplete="one-time-code"
														maxLength={6}
														additionalClassNames={securityCode.validationError ? 'form-control-invalid' : undefined}
													/>
												</FocusOnMount>
												<FormControlIcon><SvgIcon svg={padlocksvg} /></FormControlIcon>
											</FormControlWithIcon>
											{securityCode.validationError && <FieldValidationMessage errorMessage={securityCode.validationError} />}
										</div>

										<div className={styles.needHelp}>
											<Link acceptanceTestTargetId="Need help" onClick={this.showHelp} branded={false}>Need help?</Link>
										</div>

										{rememberMeVisible && (
											<div className={styles.remember}>
												<CheckboxField
													formField={rememberMobileNumber}
													label="Stay signed in"
													acceptanceTestTargetId="Stay signed in"
													ariaDescribedBy="remember-me-tooltip"
												/>
												<RememberMeTooltip tooltipContentId="remember-me-tooltip" />
											</div>
										)}
									</div>
								</div>
							</div>
						</PanelContentTransition>
					</div>
					<PanelFooter className={pageFooter}>
						<SubmitButton acceptanceTestTargetId="Next" isLoading={isLoading} isBlocked={isBlocked}>Confirm</SubmitButton>
					</PanelFooter>
				</Form>
			</div>
		);
	}

	setContentHeight = (offsetHeight: number) => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${offsetHeight}px`;
		}
	}

	private savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;

	private showHelp = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		this.props.vm.showHelp();
	}

	private handleResendCode = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		this.props.vm.resendCode();
	}

	private handleChangeMobileNumber = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		this.props.vm.resetError();
		Events.raise.ReEnterMobileNumber(this.props.machineContext);
	}

	private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.props.vm.handleSubmit();
	}
}

const RememberMeTooltip = (props: { tooltipContentId: string }) => {
	const text = [
		`Choosing this option reduces the number of times you're asked to enter your number on this device.`,
		`To keep your account secure, use this option only on secure personal devices.`,
	];

	const content: React.ReactChild[] = text.map((para, index) => <p key={index}>{para}</p>);

	return (
		<StandardTooltip {...props} placement={TooltipPlacement.Bottom} content={content} />
	);
};

const ResendCodeLink = (props: { resentCodeState: boolean, handleResendCode: (event: React.MouseEvent<HTMLAnchorElement>) => void }) => {
	return (
		props.resentCodeState
		? <Tooltip placement={TooltipPlacement.Bottom} buttonClassName={standardButton} content={`We can only send you a code every 20 seconds.`} hideOnMobile>
			<DisabledLink>Resend code</DisabledLink>
		  </Tooltip>
		: <Link className={styles.resendCode} acceptanceTestTargetId="Resend code" onClick={props.handleResendCode} branded={false}>Resend code</Link>
	);
};
