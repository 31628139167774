import * as React from 'react';
import { observer } from 'mobx-react';
import { scrollErrorIntoViewOnNextFrame } from "../../../WebGiving/utils/error-focuser";
import { Alert } from "../../../WebGiving/components/alert/alert";
import { ResetPageReason } from '../../donorpledgeentry-generated';

export enum DonorPledgeEntryErrorType {
	Generic,
	ShouldReloadPage,
	CreatePledgeError,
}

export interface IGenericError {
	errorType: DonorPledgeEntryErrorType.Generic;
	message: string;
}

export interface IShouldReloadPageError {
	errorType: DonorPledgeEntryErrorType.ShouldReloadPage;
	reason: ResetPageReason;
	resetPasswordUrl: string;
}

export interface ICreatePledgeError {
	errorType: DonorPledgeEntryErrorType.CreatePledgeError;
	errorMessageLines: string[];
}

export type DonorPledgeEntryError = IGenericError | IShouldReloadPageError | ICreatePledgeError | null | undefined;

export interface IDonorPledgeEntryErrorMessageProps {
	error: DonorPledgeEntryError;
	id?: string;
}

@observer
export class ErrorMessage extends React.Component<IDonorPledgeEntryErrorMessageProps> {

	UNSAFE_componentWillReceiveProps(nextProps: IDonorPledgeEntryErrorMessageProps) {
		if (nextProps.error && nextProps.error !== this.props.error) {
			scrollErrorIntoViewOnNextFrame();
		}
	}

	render() {
		const { error, id } = this.props;

		if (!error) {
			return <Alert content={null} id={id} />;
		}

		let content: React.ReactChild;
		switch (error.errorType) {
			case DonorPledgeEntryErrorType.Generic:
				content = error.message;
				break;
			case DonorPledgeEntryErrorType.ShouldReloadPage:
				content = error.reason;
				break;
			case DonorPledgeEntryErrorType.CreatePledgeError:
					content = this.displayMarkupForCreatePledgeError(error);
					return <Alert content={content} id={id} multipleLines={error.errorMessageLines.length > 1} />;
			default:
					const unsupported: never = error;
					throw new Error(`Unsupported DonorPledgeEntryErrorType ${unsupported}`);			
		}

		return <Alert content={content} id={id}/>;
	}

	private displayMarkupForCreatePledgeError(error: ICreatePledgeError) {
		const { errorMessageLines } = error;

		if (errorMessageLines.length === 1) {
			return errorMessageLines[0];
		}

		return (
			<div>
				{errorMessageLines.map(line => <p>{line}</p>)}
			</div>
		);
	}
}
