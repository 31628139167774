import * as React from 'react';

export class ErrorBoundary extends React.Component<{ onError: (error: any, customData: React.ErrorInfo) => void }> {
	componentDidCatch(error: any, reactErrorInfo: React.ErrorInfo) {
		this.props.onError(error, reactErrorInfo);
	}

	render(): any {
		return this.props.children;
	}
}
