import { MachineConfig } from 'xstate/lib/types';
import { MachineContext } from './saga-state-machine';
import { ActionMap } from './action-map';

export interface IHaveMachine {
	machineContext: MachineContext;
}

export function connectMachine(config: () => MachineConfig, actions: ActionMap) {
	return function classDecorator<T extends { new(...args:any[]): IHaveMachine }>(constructor: T) {
		return class extends constructor {
			readonly machineContext = new MachineContext(config(), actions, this);
		};
	};
}
