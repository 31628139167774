import { Actions, Events } from './states-events-actions';
import { SecurityCodeStates, SecurityCodeEvents } from './security-code-states-events-actions';

export type GenerateSecurityCodeMachineType<TData> = (confirmCode: string, resendCode: string, success: string) => TData;

// config
export const generateSecurityCodeMachine: GenerateSecurityCodeMachineType<any> = (confirmCode: string, resendCode: string, success: string) => {
	return {
		initial: SecurityCodeStates.Idle,
		states: {
			[SecurityCodeStates.Idle]: {
				on: {
					[SecurityCodeEvents.RequestCodeResend]: SecurityCodeStates.Resending,
					[SecurityCodeEvents.SubmitSecurityCode]: SecurityCodeStates.Invoking,
				},
			},
			[SecurityCodeStates.Resending]: {
				onEntry: [Actions.EnableResendGuard, resendCode],
				on: {
					[SecurityCodeEvents.CodeResent]: SecurityCodeStates.Resent,
					[SecurityCodeEvents.SubmitSecurityCode]: SecurityCodeStates.Invoking,
				},
			},
			[SecurityCodeStates.Resent]: {
				onEntry: Actions.DisableResendGuard,
				on: {
					[SecurityCodeEvents.ResendGuardDisabled]: SecurityCodeStates.Idle,
					[SecurityCodeEvents.RequestCodeResend]: SecurityCodeStates.Resending,
					[SecurityCodeEvents.SubmitSecurityCode]: SecurityCodeStates.Invoking,
				},
			},
			[SecurityCodeStates.Invoking]: {
				onEntry: confirmCode,
				on: {
					[SecurityCodeEvents.RequestSuccess]: SecurityCodeStates.Success,
					[SecurityCodeEvents.RequestFailure]: SecurityCodeStates.Failure,
					[SecurityCodeEvents.ValidationError]: SecurityCodeStates.Idle,
				},
			},
			[SecurityCodeStates.Success]: {
				onEntry: success,
			},
			[SecurityCodeStates.Failure]: {
				onEntry: Actions.FailProcessSecurityCode,
				on: {
					[SecurityCodeEvents.CodeConfirmationFailed]: SecurityCodeStates.Idle,
				},
			},
		},
	};
};

export type GenerateSecurityCodeMachineTypeNew<TData> = (confirmCode: string, resendCode: string, resendCodeByVoice: string, success: string) => TData;

// config
export const generateSecurityCodeMachineNew: GenerateSecurityCodeMachineTypeNew<any> = (confirmCode: string, resendCode: string, resendCodeByVoice: string, success: string) => {
	return {
		initial: SecurityCodeStates.Idle,
		states: {
			[SecurityCodeStates.Idle]: {
				on: {
					[SecurityCodeEvents.RequestCodeResend]: SecurityCodeStates.Resending,
					[SecurityCodeEvents.SubmitSecurityCode]: SecurityCodeStates.Invoking,
					[SecurityCodeEvents.ShowNeedHelp]: SecurityCodeStates.NeedHelp,
				},
			},
			[SecurityCodeStates.Resending]: {
				onEntry: [Actions.EnableResendGuard, resendCode],
				on: {
					[SecurityCodeEvents.CodeResent]: SecurityCodeStates.Resent,
				},
			},
			[SecurityCodeStates.Invoking]: {
				onEntry: confirmCode,
				on: {
					[SecurityCodeEvents.RequestSuccess]: SecurityCodeStates.Success,
					[SecurityCodeEvents.RequestFailure]: SecurityCodeStates.Failure,
					[SecurityCodeEvents.ValidationError]: SecurityCodeStates.Idle,
				},
			},
			[SecurityCodeStates.NeedHelp]: {
				on: {
					[Events.CloseModal]: SecurityCodeStates.Idle,
					[SecurityCodeEvents.RequestCodeResend]: SecurityCodeStates.Resending,
					[SecurityCodeEvents.RequestCodeByVoice]: SecurityCodeStates.ResendByVoice,
				}
			},
			[SecurityCodeStates.Resent]: {
				onEntry: Actions.DisableResendGuard,
				on: {
					[SecurityCodeEvents.ResendGuardDisabled]: SecurityCodeStates.Idle,
					[SecurityCodeEvents.SubmitSecurityCode]: SecurityCodeStates.InvokingResent,
					[SecurityCodeEvents.ShowNeedHelp]: SecurityCodeStates.NeedHelpResent,
				},
			},
			[SecurityCodeStates.Success]: {
				onEntry: success,
			},
			[SecurityCodeStates.Failure]: {
				onEntry: Actions.FailProcessSecurityCode,
				on: {
					[SecurityCodeEvents.CodeConfirmationFailed]: SecurityCodeStates.Idle,
				},
			},
			[SecurityCodeStates.ResendByVoice]: {
				onEntry: [Actions.EnableResendGuard, resendCodeByVoice],
				on: {
					[SecurityCodeEvents.CodeResent]: SecurityCodeStates.Resent,
				},
			},
			[SecurityCodeStates.InvokingResent]: {
				onEntry: confirmCode,
				on: {
					[SecurityCodeEvents.RequestSuccess]: SecurityCodeStates.Success,
					[SecurityCodeEvents.RequestFailure]: SecurityCodeStates.FailureResent,
					[SecurityCodeEvents.ValidationError]: SecurityCodeStates.Resent,
				},
			},
			[SecurityCodeStates.NeedHelpResent]: {
				on: {
					[Events.CloseModal]: SecurityCodeStates.Resent,
					[SecurityCodeEvents.ResendGuardDisabled]: SecurityCodeStates.NeedHelp,
				}
			},
			[SecurityCodeStates.FailureResent]: {
				onEntry: Actions.FailProcessSecurityCode,
				on: {
					[SecurityCodeEvents.CodeConfirmationFailed]: SecurityCodeStates.Resent,
				},
			},
		},
	};
};
