import * as React from 'react';
import { observer } from 'mobx-react';
import { DonorPledgeEntryPushpayInfo, DonorPledgeEntryOrganization } from '../donorpledgeentry-generated';
import { classNames } from '../../Shared/utils/classnames';
import { SvgIcon } from '../../WebGiving/components/svg-icon';
import logo from '../../WebGiving/assets/svg/pushpay-logo.svg';

import {
	footer,
	footerContainer,
	footerLink,
	orgInfo,
	separator,
	pushpayInfoWrapper,
	pushpayLogoWrapper,
	pushpayLogo,
	info,
	component,
	horizontalSeparator,
} from './main.less';

export interface IFooterProp {
	organization: DonorPledgeEntryOrganization;
	pushpayInfo: DonorPledgeEntryPushpayInfo;
	className?: string;
}

@observer
export class DpeMainFooter extends React.Component<IFooterProp> {
	render() {
		const { organization, pushpayInfo, className } = this.props;
		const {
			HelpCenterLink: helpCenterLink,
		} = pushpayInfo;
		const {
			LegalName: legalName,
			Address: address,
		} = organization;
		return (
			// tslint:disable:max-line-length
			<FooterContainer className={classNames(footer, className)}>
				<div className={orgInfo}>
					{legalName ? <p><strong>Organization Legal Name</strong>: {legalName}</p> : undefined}
					{legalName && address ? <span className={separator}>|</span> : undefined}
					{address ? <p><strong>Address</strong>: {address}</p> : undefined}
				</div>
				<FooterSeparator />
				<FooterPushpaySection helpCenterLink={helpCenterLink} />
			</FooterContainer>
			// tslint:enable:max-line-length
		);
	}
}

@observer
export class FooterContainer extends React.Component<{ className?: string}, {}> {
	render() {
		const { className, children} = this.props;
		return(
			<footer className={classNames(footer, className)}>
			<div className={classNames(footerContainer, 'container')}>
				{children}
			</div>
		</footer>
		);
	}
}

export const FooterSeparator = () => <hr className={horizontalSeparator} />;

export const FooterPushpaySection = observer(({ helpCenterLink }: { helpCenterLink: string }) => {
	return(
		<div className={pushpayInfoWrapper}>
			<div className={pushpayLogoWrapper}>
				<SvgIcon className={classNames(pushpayLogo, component)} svg={logo} />
			</div>
			<div className={info}>
				<a className={classNames(footerLink, component)} href={helpCenterLink} target="_blank" rel="noopener noreferrer">Help Center</a>
				<span className={separator}>|</span>
				<div className={component}>&copy; {new Date().getFullYear()} Pushpay<sup>&reg;</sup> Ltd, All rights reserved</div>
			</div>
		</div>
	);
});
