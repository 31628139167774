import * as React from 'react';
import { classNames } from '../../Shared/utils/classnames';

export class PanelFooter extends React.Component<{
	className?: string;
}, {}> {

	render() {
		return (
			<div className={classNames(
				'panel-footer',
				this.props.className)}>
				{this.props.children}
			</div>
		);
	}
}
