import { InvokingMachineStates, InvokingMachineActions } from './invoking-states-events-actions';
import { SecurityCodeActions, SecurityCodeStates } from './security-code-states-events-actions';
import { getDonorPledgeEntryDataService, setNewCRSFoken } from '../donorpledgeentry-data-service';
import { PageStatsRequest } from '../donorpledgeentry-generated';
import { MainViewModel } from '../main/main-view-model';
import { postConfirmPledge } from "../pages/confirm-pledge/confirm-pledge-machine-config";
import { EnterSecurityCodeActions } from "../pages/enter-security-code/enter-security-code-machine-config";
import { EnterPledgeActions, EnterPledgeStates } from "../pages/enter-pledge/enter-pledge-machine-config";
import { CreateUserActions } from "../pages/create-user/create-user-machine-config";
import { EnterCreatePledgeCodeActions } from "../pages/confirm-create-pledge/confirm-create-pledge-machine-config";
import { postEnterMobileNumber, EnterMobileNumberStates, postEnterMobileNumberByVoice } from "../pages/enter-mobile-number/enter-mobile-number-machine-config";
import { MachineContext } from '../../Shared/state-machine/saga-state-machine';
import { convertLocalDateToUtcForLogs } from '../../Shared/utils/date-helper';
import { states, events, eventType, actions, subStates } from '../../Shared/state-machine/states-events-and-actions';
import { clearUserRemembered } from '../../WebGiving/utils/local-storage';
import { GoogleAnalyticsWrapper } from '../../WebGiving/infrastructure/google-analytics-wrapper';
import { createEmailVerificationNotification } from '../../WebGiving/components/notification/notification';

enum StateValues {
	Init,
	EnterPledgeAmount,
	EnterMobileNumber,
	EnterSecurityCode,
	CreateUser,
	PledgeExists,
	ConfirmPledge,
	ReenterSecurityCode,
	PledgeSuccess,
}

export const States = states(StateValues);
export const EnterPledgeAmountSubStates = subStates(States.EnterPledgeAmount, EnterPledgeStates);
export const EnterMobileNumberSubStates = subStates(States.EnterMobileNumber, InvokingMachineStates);
export const EnterMobileNumberSubStatesNew = subStates(States.EnterMobileNumber, EnterMobileNumberStates); // NewFeatures.DonorPledgeEntry_ModalDialogs 
export const EnterSecurityCodeSubStates = subStates(States.EnterSecurityCode, SecurityCodeStates);
export const ConfirmPledgeSubStates = subStates(States.ConfirmPledge, InvokingMachineStates);
export const EnterCreatePledgeCodeSubStates = subStates(States.ReenterSecurityCode, SecurityCodeStates);

export const Events = events({
	UserHasNotPledged: eventType<void>(),
	UserAuthenticated: eventType<void>(),
	UserUnknown: eventType<void>(),
	UserUnknownHasConfirmedMobileNumber	: eventType<void>(),
	SecurityCodeSent: eventType<void>(),
	ReEnterMobileNumber: eventType<void>(),
	EnterMobileNumberValidationError: eventType<void>(),
	UserCreated: eventType<void>(),
	UserAlreadyPledged: eventType<void>(),
	UserSessionExpired: eventType<void>(),
	PledgeConfirmed: eventType<void>(),
	PledgeRejected: eventType<void>(),
	UserSignedOut: eventType<void>(),
	HistoryBackTriggered: eventType<void>(),
	CloseModal: eventType<void>(),
});

export const Actions = actions({
	RecordPageViewAndStats: recordPageViewAndStats,
	CheckForExistingPledge: checkForExistingPledge,
	PromptEmailVerification: promptEmailVerification,
	ProcessUserSignOut: postForgetMe,
	ProcessEnterMobileNumberRequest: postEnterMobileNumber,
	ProcessEnterMobileNumberRequestByVoice: postEnterMobileNumberByVoice,
	ProcessConfirmPledgeRequest: postConfirmPledge,
	CheckForConfirmedMobileNumber: checkForConfirmedMobileNumber,
	...InvokingMachineActions,
	...EnterPledgeActions,
	...CreateUserActions,
	...SecurityCodeActions,
	...EnterSecurityCodeActions,
	...EnterCreatePledgeCodeActions,
});

function* checkForExistingPledge(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const { personHasPledged } = machineContext.viewModel as MainViewModel;
	if (personHasPledged) {
		Events.raise.UserAlreadyPledged(machineContext);
	} else {
		Events.raise.UserHasNotPledged(machineContext);
	}
}

function* postForgetMe(machineContext: MachineContext<MainViewModel>) {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const response = yield getDonorPledgeEntryDataService().forgetMe({}, () => (mainViewModel));
	if (response.NewCSRFToken) {
		setNewCRSFoken(response.NewCSRFToken);
		clearUserRemembered();
		mainViewModel.setPerson(undefined);
		mainViewModel.setConfirmedMobileNumber(undefined);
		mainViewModel.setMobileNumber('');
		mainViewModel.setAmount(0);
		mainViewModel.createUserViewModel.resetForm();
	} else {
		mainViewModel.enterMobileNumberViewModel.setError(response);
	}
}

function* recordPageViewAndStats(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	let { machineState, viewModel } = machineContext;
	let currentState = machineState.toString();
	if (currentState && viewModel) {
		GoogleAnalyticsWrapper.pageView(currentState);

		rg4js('trackEvent', { type: 'pageView', path: `/${currentState}` });

		postStatsData(currentState, viewModel, null);
	}
}

function postStatsData(currentPage: string, mainViewModel: MainViewModel, additionalData: { [key: string]: string } | null) {
	var service = getDonorPledgeEntryDataService();

	let stats: PageStatsRequest = {
		PageName: currentPage,
		BrandingElements: null,
		Timestamp: convertLocalDateToUtcForLogs(moment()),
		AdditionalData: additionalData,
	};

	if (mainViewModel.brandingPackage) {
		const brandingElements: string[] = [];
		var properties = Object.keys(mainViewModel.brandingPackage);
		for (let index in properties) {

			var brandedElement = !!(<any>mainViewModel.brandingPackage)[properties[index]];
			if (brandedElement) {
				brandingElements.push(properties[index]);
			}
		}
		stats.BrandingElements = brandingElements;
	}

	service.recordPageStats({ model: stats }, () => mainViewModel) .catch(e => {
		// Catch stats error and do nothing, we are done;
	});
	
	return;
}

function* promptEmailVerification(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const { machineState, viewModel } = machineContext;
	const mainViewModel = viewModel as MainViewModel;
	const { person, showNotification, pushpayInfo } = mainViewModel;
	const showBanner = person && person.ShowEmailVerificationPrompt;

	if (!showBanner) {
		return;
	}

	if (person && machineState.toString() === States.PledgeSuccess) {
		showNotification(
			createEmailVerificationNotification({
				items: [
					{
						text: `Keep your account secure by verifying {{emailAddress}}, from the email we sent you. No longer using this email?`,
						textParams:{ emailAddress: person.EmailAddress },
						action: {
							label: 'Update email address',
							url: `${pushpayInfo.DonorPortalUpdateEmailUrl}`,
						},
					},
				],
			})
		);
	} else if (person && machineState.toString() === States.PledgeExists) {
		showNotification(
			createEmailVerificationNotification({
				items: [
					{
						text: `Your email address is not verified.`,
						action: {
							label: 'Send verification link',
							debounceDelay: 20000,
							onClick: () =>
								getDonorPledgeEntryDataService().sendEmailAddressConfirmation({}, () => mainViewModel),
						},
					},
					{
						text: `to {{emailAddress}} or if you're no longer using this email,`,
						textParams: { emailAddress: person.EmailAddress },
						action: {
							label: 'please update email address',
							url: `${pushpayInfo.DonorPortalUpdateEmailUrl}`,
						},
					},
				],
			})
		);
	}
}

function* checkForConfirmedMobileNumber(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const { confirmedMobileNumber } = machineContext.viewModel as MainViewModel;

	if (NewFeatures.EnhancedDonorAuth_IdentityVerification_DPE && confirmedMobileNumber != null) {
		Events.raise.UserUnknownHasConfirmedMobileNumber(machineContext);
	}
}
