import * as React from 'react';
import { observer } from 'mobx-react';
import { DonorPledgeEntryPersonModel, DonorPledgeEntryOrganization, BrandingPackage } from '../donorpledgeentry-generated';
import { Account } from '../components/account/account';
import { ErrorBoundary } from '../../Shared/components/error-boundary';
import { ErrorInfo } from '../utils/error-utils';
import { MainHeader } from '../components/header/header';

export interface IHeaderProps {
	organization: DonorPledgeEntryOrganization;
	brandingPackage: BrandingPackage | null;
	person: DonorPledgeEntryPersonModel | null;
	setIsAccountDetailsOpen: (visible: boolean) => void;
	isAccountVisible: boolean;
	manageAccountUrl: string;
	handleUserSignOut: () => void;
	className: string;
	onError: (errorInfo: ErrorInfo, customData?: any) => void;
	transparentBackground?: boolean;
}


@observer
export class DpeMainHeader extends React.Component<IHeaderProps> {
	render() {
		const {
			person,
			setIsAccountDetailsOpen,
			isAccountVisible,
			organization,
			brandingPackage,
			manageAccountUrl,
			handleUserSignOut,
			className,
			onError,
			transparentBackground = false,
		} = this.props;
		return (
			<MainHeader
				className={className}
				transparentBackground={transparentBackground}
				onError={onError}
				welcomeMessage={person && `Hi, ${person.Nickname}`}
				legalName={organization.LegalName}
				logoUrl={organization.LogoUrl}
				brandingPackage={brandingPackage}
			>
				<ErrorBoundary onError={this.handleErrorInAccountMenu}>
					<Account
						setAccountVisible={setIsAccountDetailsOpen}
						isAccountVisible={isAccountVisible}
						person={person}
						paymentLabel={organization.PaymentLabel}
						manageAccountUrl={manageAccountUrl}
						handleUserSignOut={handleUserSignOut}
					/>
				</ErrorBoundary>
			</MainHeader>
		);
	}

	private handleErrorInAccountMenu = (errorInfo: ErrorInfo, customData?: any) => {
		this.props.setIsAccountDetailsOpen(false);
		this.props.onError(errorInfo, customData);
	}
}
