import {
	Channel,
	END,
} from 'redux-saga';
import { isFunction } from './is-function';
export type Channel<T> = Channel<T>;

/**
 * A custom simplified implementation of redux-saga channel that notifies all the subscribers (takers) and does not have a buffer.
 * A default channel works like a load balancer trying to spread all the incoming message across all the subscribers (takers).
 * While the subscribers (takers) are busy, it puts the incoming message into a buffer.
 * It sounds useful for supporting sagas running on Node that need load balancing to allocate work across multiple workers.
 * We need a simple pub/sub for our UI sagas, all the messages should be sent to all the subscribers (takers)
 * allowing all the sagas to react to user actions.
 * This channel is not limited to user actions - it can be used on anything we want.
 * Please feel free to rename the channel if you can think of a better name :)
 * @export
 * @template T
 * @returns {Channel<T>}
 */
export function userActionChannel<T>(): Channel<T> {
	let closed = false;
	const takers: { (message: T | END): void, cancel?: () => void }[] = [];

	function checkForbiddenStates() {
		if (closed && takers.length) {
			throw Error('Cannot have a closed channel with pending takers');
		}
	}

	return {
		close() {
			checkForbiddenStates();
			if (!closed) {
				closed = true;

				// end all takers on close
				takers.forEach(x => {
					x(END);
				});
			}
		},
		flush(cb?: (message: T | END) => void) {
			checkForbiddenStates();
			// there's no buffer in our case, always return END

			if (isFunction(cb)) {
				cb(END);
			}
		},
		put(message: T | END) {
			checkForbiddenStates();
			if (closed) {
				return;
			}

			// every taker should be executed only once
			// using splice() to clear the takers array
			const takersCopy = takers.splice(0, takers.length);

			for (let i = 0; i < takersCopy.length; i++) {
				const taker = takersCopy[i];
				taker(message);
			}
		},
		take(cb: { (message: T | END): void, cancel?: () => void }) {
			checkForbiddenStates();
			if (closed) {
				cb(END);
				return;
			}

			takers.push(cb);
			cb.cancel = () => {
				const index = takers.indexOf(cb);
				if (index !== -1) {
					takers.splice(index, 1);
				}
			};
		},
	};
}
