

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from './donorpledgeentry-data-service';
export interface PageStatsRequest {
	PageName: string;
	BrandingElements: string[] | null;
	AdditionalData: { [key: string]: string } | null;
	Timestamp: Date;
}
export interface ForgetMeResponse {
	NewCSRFToken: string;
}
export interface EnterCreatePledgeConfirmationCodeRequest {
	Code: string;
	RequestToken: string;
	RememberMe: boolean;
	Amount: number;
	RecurringDetails: RecurringDetails;
}
export interface EnterCreatePledgeConfirmationCodeErroneousCodeResponse {
	Status: EnterCreatePledgeConfirmationCodeResultStatus.ConfirmationCodeError;
	ConfirmationCodeInvalid: boolean;
	Reason: string;
}
export enum EnterCreatePledgeConfirmationCodeResultStatus {
	Unknown = 0, Success = 1, UserAlreadyPledged = 2, ServiceUnavailable = 3, ConfirmationCodeError = 4,
}
export interface EnterCreatePledgeConfirmationCodeServiceUnavailableResponse {
	Status: EnterCreatePledgeConfirmationCodeResultStatus.ServiceUnavailable;
}
export interface EnterCreatePledgeConfirmationCodeAlreadyExistsResponse {
	Status: EnterCreatePledgeConfirmationCodeResultStatus.UserAlreadyPledged;
	WebGivingRedirectUrl: string;
	CampaignsRedirectUrl: string;
}
export interface EnterCreatePledgeConfirmationCodeSuccessResponse {
	Status: EnterCreatePledgeConfirmationCodeResultStatus.Success;
	WebGivingRedirectUrl: string;
	CampaignsRedirectUrl: string;
}
export interface ResendCreatePledgeCodeRequest {
	UseVoiceCall?: boolean | null;
}
export interface ResendCreatePledgeCodeResponse {
	RequestToken: string;
}
export interface CreatePledgeRequest {
	Amount: number;
	PayerPrefersVoiceCall: boolean;
	RecurringDetails: RecurringDetails;
}
export interface CreatePledgeServiceUnavailableResponse {
	Status: CreatePledgeResultStatus.ServiceUnavailable;
}
export enum CreatePledgeResultStatus {
	Unknown = 0, Success = 1, UserSessionExpired = 2, UserAlreadyPledged = 3, ServiceUnavailable = 4,
}
export interface CreatePledgeAlreadyExistsResponse {
	Status: CreatePledgeResultStatus.UserAlreadyPledged;
	WebGivingRedirectUrl: string;
	CampaignsRedirectUrl: string;
}
export interface CreatePledgeSessionExpiredResponse {
	Status: CreatePledgeResultStatus.UserSessionExpired;
	RequestToken: string;
}
export interface CreatePledgeSuccessResponse {
	Status: CreatePledgeResultStatus.Success;
	WebGivingRedirectUrl: string;
	CampaignsRedirectUrl: string;
}
export interface CreateAccountRequest {
	FirstName: string;
	LastName: string;
	Email: string;
	RememberMe: boolean;
	Language: Language;
}
export interface Language {
	Name: string;
	TwoLetterIsoCode: string;
}
export interface CreateAccountServiceUnavailableResponse {
	Status: CreateAccountStatusResult.ServiceUnavailable;
}
export enum CreateAccountStatusResult {
	Unknown = 0, Success = 1, ServiceUnavailable = 2,
}
export interface CreateAccountSuccessResponse {
	Status: CreateAccountStatusResult.Success;
	Person: DonorPledgeEntryPersonModel;
	RegistrationConfirmationModel: RegistrationConfirmationModel;
}
export interface RegistrationConfirmationModel {
	Name: string;
	WebsiteConfirmationLink: string;
	ReferMerchantApp: boolean;
	MerchantName: string;
	PaymentNoun: string;
	PaymentNounPlural: string;
	Title: string;
	Flavour: string;
	PostTitleMessage: string;
	ListUnsubscribeHeader: string;
	MerchantDisplayAddressLines: string[];
	AuthCode: string;
	Subject: string;
}
export interface DonorPledgeEntryPersonModel {
	Nickname: string;
	MobileNumber: WebGivingMobileNumber;
	PostalCodeCommonName: string;
	PostalCodeRegex: string;
	NewCSRFToken: string;
	TimeZoneInfo: TimeZoneInfo;
	TimeZoneId: string;
	CultureCode: string;
	EmailAddress: string;
	ShowEmailVerificationPrompt: boolean;
	SignedPersonId: string;
	TermsAndConditionsVersion: string;
	PledgeExists: boolean;
	RecurringPaymentExists: boolean;
}
export interface TimeZoneInfo {
	Id: string;
	DisplayName: string;
	StandardName: string;
	DaylightName: string;
	BaseUtcOffset: string;
	SupportsDaylightSavingTime: boolean;
}
export interface WebGivingMobileNumber {
	NormalizedInternationalNumber: string;
	NationalNumber: string;
}
export interface ResendConfirmationCodeRequest {
	NormalizedInternationalNumber: string;
	UseVoiceCall?: boolean | null;
	Language: Language;
}
export interface RecurringDetails {
	PledgedAmount: string;
	RecurringAmount: string;
	FrequencyCode: FrequencyCode;
	StartDate: string;
	EndDate: string;
}
export enum FrequencyCode {
	Other = 0, Weekly = 1, Fortnightly = 2, Monthly = 3, FirstAndFifteenth = 4, OneMinute = 5, FiveMinutes = 6, FourHours = 7, Yearly = 8, SemiYearly = 9, Quarterly = 10,
}
export interface EnterMobileNumberConfirmCodeRequest {
	Code: string;
	RequestToken: string;
	RememberMe: boolean;
	NormalizedInternationalNumber: string;
}
export interface ConfirmationCodeServiceUnavailableResponse {
	Status: ConfirmationCodeResultStatus.CampaignServiceUnavailable;
}
export enum ConfirmationCodeResultStatus {
	Unknown = 0, Success = 1, ConfirmationCodeError = 2, CampaignServiceUnavailable = 3,
}
export interface ConfirmationCodeErrorResponse {
	Status: ConfirmationCodeResultStatus.ConfirmationCodeError;
	ConfirmationCodeInvalid: boolean;
	Reason: string;
}
export interface ConfirmationCodeSuccessResponse {
	Status: ConfirmationCodeResultStatus.Success;
	Person: DonorPledgeEntryPersonModel | null;
	ConfirmedMobileNumber: WebGivingMobileNumber;
	WebGivingRedirectUrl: string;
	CampaignsRedirectUrl: string;
}
export interface EnterMobileNumberRequest {
	MobileNumber: string;
	Country: string;
	UseVoiceCall?: boolean | null;
	Language: Language;
}
export interface EnterMobileNumberErrorResponse {
	Success: false;
	Reason: string;
}
export interface EnterMobileNumberResponse {
	MobileNumber: WebGivingMobileNumber;
	RequestToken: string;
	FastModeActive: boolean;
	Success: true;
}
export enum ResetPageReason {
	Unknown = 0, PersonNotFound = 1, PaymentNotFound = 2, DuplicateMobileNumber = 3, AdminAccountLocked = 4,
}
export interface MerchantConflictResponse {
	MerchantInfo: WebGivingMerchant;
	Theme: Theme;
}
export interface Theme {
	PrimaryColor: string;
	SecondaryColor: string;
	Nonce: string;
	MerchantLogoUrl: string;
	ReturnUrl: string;
	ReturnLabel: string;
}
export interface WebGivingMerchant {
	Handle: string;
	TradingName: string;
	LegalName: string;
	MerchantId: number;
	MerchantVersion: number;
	IsHppEnabled: boolean;
	TermsAndConditions: string;
	PrivacyPolicy: string;
	Address: string;
	Country: Country;
	SupportsAch: boolean;
	SupportsPayerCoversFees: boolean;
	PayerCoversFeesDefault: boolean;
	TransactionFeeRate: number;
	PercentageOfPaymentAddedToCoverFees: number;
	SupportedCardBrandKeys: string[];
	CardPreference?: MerchantCardPreference | null;
	CustomFields: ICustomFieldViewModel[];
	PaymentLabel: PaymentLabel;
	PaymentMin: number;
	PaymentMax: number;
	AchMax?: number | null;
	LogoUrl: string;
	PaymentMethodPreference: PaymentMethodType;
	PayersMustBeVerified: boolean;
	PayerSpecifiedRecurringEndDateEnabled: boolean;
	WebsiteUrl: string;
	Currency: Currency;
	MobileAppName: string;
	GetAppUrl: string;
	HasCustomApp: boolean;
	WebGivingUrl: string;
	HasCustomLogo: boolean;
	BrandingEnabled: boolean;
	RecurringByDefault: boolean;
	HasCustomTermsOrPrivacy: boolean;
	OneTimeFutureGiftEnabled: boolean;
	IsEntitledToLanguageSelector: boolean;
	HasAnnualRecurringGivingEnabled: boolean;
}
export enum Currency {
	NZD = 0, AUD = 1, USD = 2, CAD = 3, Unspecified = -1,
}
export enum PaymentMethodType {
	Unknown = 0, CreditCard = 1, NzBankAccount = 2, ACH = 3, Cash = 4, RecordedCheck = 5, AchCheck = 6, ProfitStarsScannedCheck = 7, RecordedCreditCard = 8, RecordedACH = 9, ImportedUnspecified = 10, ImportedUnsupported = 11,
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface ICustomFieldViewModel {
	Key: string;
	Label: string;
	IsRequired: boolean;
	Type: CustomFieldType;
	Order: number;
}
export enum CustomFieldType {
	Unknown = 0, Text = 1, Number = 2, DropDown = 3, Fund = 4,
}
export enum MerchantCardPreference {
	AnyCard = 0, DebitPreferred = 1, DebitOnly = 2,
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export interface DonorPledgeEntryViewModel {
	OrganizationInfo: DonorPledgeEntryOrganization;
	Theme: Theme;
	BrandingPackage: BrandingPackage | null;
	Person: DonorPledgeEntryPersonModel | null;
	ConfirmedMobileNumber: WebGivingMobileNumber | null;
	GoogleAnalyticsTrackerId: string | null;
	SessionCorrelationId: string;
	PushpayInfo: DonorPledgeEntryPushpayInfo;
	CampaignInfo: DonorPledgeEntryCampaignInfo;
	ClientIsMobilePhone: boolean;
	Amount: number | null;
	InitialQueryString: string | null;
}
export interface DonorPledgeEntryCampaignInfo {
	CampaignName: string;
	Description: string;
	LandingPageUrl: string;
	GiveToCampaignUrl: string;
	PledgeProgressUrl: string;
	Currency: Currency;
	ThankYouMessage: string;
	EndDate: string | null;
}
export interface DonorPledgeEntryPushpayInfo {
	TermsAndConditionsVersion: string;
	TermsAndConditions: string;
	PrivacyStatement: string;
	HelpCenterLink: string;
	ManageAccountUrl: string;
	ResetPasswordUrl: string;
	UpdateProfileUrl: string;
	DonorPortalUpdateEmailUrl: string;
}
export interface BrandingPackage {
	Version: string | null;
	HeroImageUrl: string | null;
	HeroImageKey: string | null;
	EnhancedLogoImageUrl: string | null;
	EnhancedLogoImageKey: string | null;
	FaviconImageUrl: string | null;
	FaviconImageKey: string | null;
	GivingTitle: string | null;
	PrimaryColor: string;
	SecondaryColor: string;
	RetrievedAt: Instant;
	ETag: string | null;
}
export interface Instant {
}
export interface DonorPledgeEntryOrganization {
	LegalName: string;
	Address: string;
	LogoUrl: string;
	BrandingEnabled: boolean;
	HasCustomLogo: boolean;
	PaymentLabel: PaymentLabel;
	PledgeLabel: PledgeLabel;
	HomeCountry: Country;
	MerchantPaymentMin: number;
	MerchantPaymentMax: number;
	MerchantAchMax?: number | null;
	HasYearlyRecurringGivingEnabled: boolean;
	HasSemiYearlyRecurringGivingEnabled: boolean;
	HasQuarterlyRecurringGivingEnabled: boolean;
}
export interface PledgeLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbSentenceCase: string;
	VerbLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
}
export const DonorPledgeEntryApiConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/c/.+/.+', 'i')))[0],
	actions: {
		enterMobileNumber: <IApiAction<{model: EnterMobileNumberRequest}, EnterMobileNumberResponse | EnterMobileNumberErrorResponse>>{
			url: (data) => `/EnterMobileNumber`,
		},
		enterMobileNumberConfirmationCode: <IApiAction<{model: EnterMobileNumberConfirmCodeRequest, recurringDetails: RecurringDetails}, ConfirmationCodeSuccessResponse | ConfirmationCodeErrorResponse | ConfirmationCodeServiceUnavailableResponse>>{
			url: (data) => `/EnterMobileNumberConfirmationCode`,
		},
		resendConfirmationCode: <IApiAction<{model: ResendConfirmationCodeRequest}, EnterMobileNumberResponse | EnterMobileNumberErrorResponse>>{
			url: (data) => `/ResendConfirmationCode`,
		},
		createAccount: <IApiAction<{model: CreateAccountRequest}, CreateAccountSuccessResponse | CreateAccountServiceUnavailableResponse>>{
			url: (data) => `/CreateAccount`,
		},
		createPledge: <IApiAction<{model: CreatePledgeRequest}, CreatePledgeSuccessResponse | CreatePledgeSessionExpiredResponse | CreatePledgeAlreadyExistsResponse | CreatePledgeServiceUnavailableResponse>>{
			url: (data) => `/CreatePledge`,
		},
		resendCreatePledgeCode: <IApiAction<{model: ResendCreatePledgeCodeRequest}, ResendCreatePledgeCodeResponse>>{
			url: (data) => `/ResendCreatePledgeCode`,
		},
		enterCreatePledgeConfirmationCode: <IApiAction<{model: EnterCreatePledgeConfirmationCodeRequest}, EnterCreatePledgeConfirmationCodeSuccessResponse | EnterCreatePledgeConfirmationCodeAlreadyExistsResponse | EnterCreatePledgeConfirmationCodeServiceUnavailableResponse | EnterCreatePledgeConfirmationCodeErroneousCodeResponse>>{
			url: (data) => `/EnterCreatePledgeConfirmationCode`,
		},
		forgetMe: <IApiAction<{}, ForgetMeResponse>>{
			url: (data) => `/ForgetMe`,
		},
		sendEmailAddressConfirmation: <IApiAction<{}>>{
			url: (data) => `/SendEmailAddressConfirmation`,
		},
		recordPageStats: <IApiAction<{model: PageStatsRequest}>>{
			url: (data) => `/RecordPageStats`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let EnterMobileNumberRequest : { [name: string]: IPropertyMetadata } = {
		MobileNumber: {
			propertyName: "MobileNumber",
			displayName: "Mobile",
			placeholder: "e.g. 555 123 4567",
			validationRules: {
				length: {
					errorMessage: "This number is not valid",
					parameters: {
						max: 20,
						min: 5,
					},
				},
				required: {
					errorMessage: "Please enter mobile number",
				},
				skipsanitization: {},
			},
		},
		Country: {
			propertyName: "Country",
			validationRules: {
				length: {
					errorMessage: "The field Country must be a string with a maximum length of 2.",
					parameters: {
						max: 2,
					},
				},
				required: {
					errorMessage: "The Country field is required.",
				},
			},
		},
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let EnterMobileNumberConfirmCodeRequest : { [name: string]: IPropertyMetadata } = {
		Code: {
			propertyName: "Code",
			displayName: "Confirmation Code",
			placeholder: "e.g. 123456",
			validationRules: {
				length: {
					errorMessage: "Please enter a 6 digit security code",
					parameters: {
						max: 6,
						min: 6,
					},
				},
				required: {
					errorMessage: "Please enter a 6 digit security code",
				},
			},
		},
		RequestToken: {
			propertyName: "RequestToken",
			validationRules: {
				skipsanitization: {},
			},
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		NormalizedInternationalNumber: {
			propertyName: "NormalizedInternationalNumber",
			validationRules: {
				length: {
					errorMessage: "The field NormalizedInternationalNumber must be a string with a minimum length of 9 and a maximum length of 20.",
					parameters: {
						max: 20,
						min: 9,
					},
				},
				skipsanitization: {},
			},
		},
	};
	export let RecurringDetails : { [name: string]: IPropertyMetadata } = {
		PledgedAmount: {
			propertyName: "PledgedAmount",
			validationRules: {
				length: {
					errorMessage: "The field PledgedAmount must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
			},
		},
		RecurringAmount: {
			propertyName: "RecurringAmount",
			validationRules: {
				length: {
					errorMessage: "The field RecurringAmount must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
			},
		},
		FrequencyCode: {
			propertyName: "FrequencyCode",
			validationRules: {
				required: {
					errorMessage: "The FrequencyCode field is required.",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			validationRules: {
				length: {
					errorMessage: "The field StartDate must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			validationRules: {
				length: {
					errorMessage: "The field EndDate must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
			},
		},
	};
	export let ResendConfirmationCodeRequest : { [name: string]: IPropertyMetadata } = {
		NormalizedInternationalNumber: {
			propertyName: "NormalizedInternationalNumber",
			validationRules: {
				length: {
					errorMessage: "The field NormalizedInternationalNumber must be a string with a minimum length of 9 and a maximum length of 20.",
					parameters: {
						max: 20,
						min: 9,
					},
				},
				required: {
					errorMessage: "The NormalizedInternationalNumber field is required.",
				},
				skipsanitization: {},
			},
		},
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let CreateAccountRequest : { [name: string]: IPropertyMetadata } = {
		FirstName: {
			propertyName: "FirstName",
			placeholder: "e.g. James",
			validationRules: {
				length: {
					errorMessage: "This name is not valid",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter first name",
				},
			},
		},
		LastName: {
			propertyName: "LastName",
			placeholder: "e.g. Smith",
			validationRules: {
				length: {
					errorMessage: "This name is not valid",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter last name",
				},
			},
		},
		Email: {
			propertyName: "Email",
			placeholder: "e.g. james@smith.com",
			validationRules: {
				email: {
					errorMessage: "This email address is not valid",
				},
				length: {
					errorMessage: "This email address is not valid",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter email address",
				},
			},
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		Language: {
			propertyName: "Language",
		},
	};
	export let CreatePledgeRequest : { [name: string]: IPropertyMetadata } = {
		Amount: {
			propertyName: "Amount",
			placeholder: "0",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				required: {
					errorMessage: "The Amount field is required.",
				},
			},
		},
		PayerPrefersVoiceCall: {
			propertyName: "PayerPrefersVoiceCall",
			validationRules: {
				required: {
					errorMessage: "The PayerPrefersVoiceCall field is required.",
				},
			},
		},
		RecurringDetails: {
			propertyName: "RecurringDetails",
			get modelMetadata() {
				return ModelMetadata.RecurringDetails;
			},
		},
	};
	export let ResendCreatePledgeCodeRequest : { [name: string]: IPropertyMetadata } = {
		UseVoiceCall: {
			propertyName: "UseVoiceCall",
		},
	};
	export let EnterCreatePledgeConfirmationCodeRequest : { [name: string]: IPropertyMetadata } = {
		Code: {
			propertyName: "Code",
			displayName: "Confirmation Code",
			placeholder: "e.g. 123456",
			validationRules: {
				length: {
					errorMessage: "Please enter a 6 digit security code",
					parameters: {
						max: 6,
						min: 6,
					},
				},
				required: {
					errorMessage: "Please enter a 6 digit security code",
				},
			},
		},
		RequestToken: {
			propertyName: "RequestToken",
		},
		RememberMe: {
			propertyName: "RememberMe",
			validationRules: {
				required: {
					errorMessage: "The RememberMe field is required.",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				required: {
					errorMessage: "The Amount field is required.",
				},
			},
		},
		RecurringDetails: {
			propertyName: "RecurringDetails",
			get modelMetadata() {
				return ModelMetadata.RecurringDetails;
			},
		},
	};
	export let PageStatsRequest : { [name: string]: IPropertyMetadata } = {
		PageName: {
			propertyName: "PageName",
		},
		BrandingElements: {
			propertyName: "BrandingElements",
		},
		AdditionalData: {
			propertyName: "AdditionalData",
		},
		Timestamp: {
			propertyName: "Timestamp",
			validationRules: {
				date: {
					errorMessage: "The field Timestamp must be a date.",
				},
				required: {
					errorMessage: "The Timestamp field is required.",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export type CountryConfig= {
	displayName: string;
	//two-letter display code. Usually the CCA2 except when we override it, i.e. GB=>UK
	displayCode2: string;
	countryCode: Country;
	mobilePlaceholder: string;
	callingCode: string;
};
export const CountryData: CountryConfig[] = [
{
	displayName: "Australia",
	displayCode2: "AU",
	countryCode: 1,
	mobilePlaceholder: "0412 345 678",
	callingCode: "+61",
},
{
	displayName: "Belgium",
	displayCode2: "BE",
	countryCode: 56,
	mobilePlaceholder: "0470 12 34 56",
	callingCode: "+32",
},
{
	displayName: "Canada",
	displayCode2: "CA",
	countryCode: 124,
	mobilePlaceholder: "(201) 555-5555",
	callingCode: "+1",
},
{
	displayName: "Switzerland",
	displayCode2: "CH",
	countryCode: 756,
	mobilePlaceholder: "078 123 45 67",
	callingCode: "+41",
},
{
	displayName: "Germany",
	displayCode2: "DE",
	countryCode: 276,
	mobilePlaceholder: "0162 1234567",
	callingCode: "+49",
},
{
	displayName: "Denmark",
	displayCode2: "DK",
	countryCode: 208,
	mobilePlaceholder: "20-12-34-56",
	callingCode: "+45",
},
{
	displayName: "Finland",
	displayCode2: "FI",
	countryCode: 246,
	mobilePlaceholder: "045 71234567",
	callingCode: "+358",
},
{
	displayName: "United Kingdom",
	displayCode2: "UK",
	countryCode: 826,
	mobilePlaceholder: "07900 123456",
	callingCode: "+44",
},
{
	displayName: "Guatemala",
	displayCode2: "GT",
	countryCode: 320,
	mobilePlaceholder: "5123-4567",
	callingCode: "+502",
},
{
	displayName: "Iceland",
	displayCode2: "IS",
	countryCode: 352,
	mobilePlaceholder: "678 1234",
	callingCode: "+354",
},
{
	displayName: "Latvia",
	displayCode2: "LV",
	countryCode: 428,
	mobilePlaceholder: "23 123 456",
	callingCode: "+371",
},
{
	displayName: "Mexico",
	displayCode2: "MX",
	countryCode: 484,
	mobilePlaceholder: "(01) 55 5555 5555",
	callingCode: "+52",
},
{
	displayName: "Norway",
	displayCode2: "NO",
	countryCode: 578,
	mobilePlaceholder: "456 12 345",
	callingCode: "+47",
},
{
	displayName: "New Zealand",
	displayCode2: "NZ",
	countryCode: 0,
	mobilePlaceholder: "(021) 123-4567",
	callingCode: "+64",
},
{
	displayName: "Puerto Rico",
	displayCode2: "PR",
	countryCode: 630,
	mobilePlaceholder: "(787) 555-5555",
	callingCode: "+1",
},
{
	displayName: "Sweden",
	displayCode2: "SE",
	countryCode: 752,
	mobilePlaceholder: "070-123 45 67",
	callingCode: "+46",
},
{
	displayName: "Singapore",
	displayCode2: "SG",
	countryCode: 702,
	mobilePlaceholder: "8551-2345",
	callingCode: "+65",
},
{
	displayName: "United States of America",
	displayCode2: "US",
	countryCode: 2,
	mobilePlaceholder: "(201) 555-5555",
	callingCode: "+1",
},
{
	displayName: "South Africa",
	displayCode2: "ZA",
	countryCode: 710,
	mobilePlaceholder: "023 456 7890",
	callingCode: "+27",
}];
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
