import * as React from 'react';
import logOutSvg from '../../../WebGiving/assets/svg/icon-logout.svg';
import { LinkWithIcon, LinkProps, LinkWithIconContent, RightChevronLink } from '../../../WebGiving/primitives/link/link';
import { SvgIcon } from '../svg-icon';

export class LogOutLink extends React.Component<LinkProps, {}> {
	render() {
		return (
			<LinkWithIcon {...this.props}>
				<SvgIcon svg={logOutSvg} />
				<LinkWithIconContent>{this.props.children}</LinkWithIconContent>
			</LinkWithIcon>
		);
	}
}

export class ManageAccountLink extends React.Component<LinkProps, {}> {
	render() {
		return <RightChevronLink {...this.props} openInNewTab={true} />;
	}
}
