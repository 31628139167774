
import { LocalStorageHelper } from '../../Shared/helpers/local-storage';
const storage = LocalStorageHelper.getLocalStorage();

const prefix = 'pp-rememberUser';

export function getUserRememberedAmount(merchantId: number, userId?: string): number | undefined {
	if (! userId) {
		return undefined;
	}
	const retrieved = storage.getItem(getAmountKey(merchantId, userId));
	if (! retrieved) {
		return undefined;
	}
	const amount = parseFloat(retrieved);
	if (isNaN(amount)) {
		return undefined;
	}
	return amount;
}

export function setUserRememberedAmount(merchantId: number, userId: string, amount: number): void {
	storage.setItem(getAmountKey(merchantId, userId), amount.toString());
}

export function clearUserRemembered(): void {
	for (let key in storage) {
		if (key.indexOf(`${prefix}.`) === 0) {
			storage.removeItem(key);
		}
	}
}

function getAmountKey(merchantId: number, userId: string) {
	return `${prefix}.${merchantId}.${userId}.amount`;
}