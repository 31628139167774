import * as React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Provider } from 'mobx-react';
import { runSaga } from 'redux-saga';
import { Machine, State } from 'xstate';
import { Action, Event, MachineConfig, StandardMachine, ParallelMachine } from 'xstate/lib/types';
import { toStatePaths } from 'xstate/lib/utils';
import { ActionMap } from './action-map';
import { forkInAction } from '../utils/saga-utils';
import { Fragment } from '../components/fragment';

export const machineContextKey = 'machineContext';

@observer
export class StateMachine extends React.Component<{ machineContext: MachineContext }> {
	render() {
		return (
			<Provider machineContext={this.props.machineContext}>
				<Fragment>
					{this.props.children}
				</Fragment>
			</Provider>
		);
	}
}

export class MachineContext<TViewModel = any> {
	@observable
	machine: StandardMachine | ParallelMachine;

	@observable
	machineState: State;

	@observable
	private _disableActionsForTesting = false;

	constructor(machineConfig: MachineConfig,
		public actionMap: ActionMap,
		public viewModel?: TViewModel) {
		this.machine = Machine(machineConfig);
		this.machineState = this.machine.initialState;
		this.runActions(this.machine.initialState.actions);
	}

	@computed
	private get machineStateValue() {
		return this.machineState.value;
	}

	@action.bound
	transition(event: Event, data?: any) {
		console.log(`${this.machine.key} current state`, this.machineState, event, data);

		const nextState = this.machine.transition(this.machineState, event, data);

		console.log(`${this.machine.key} new state:`, nextState);

		this.machineState = nextState;

		this.runActions(nextState.actions, data);
	}

	@action.bound
	runActions(actions: Action[], data?: any) {
		if (this._disableActionsForTesting) {
			return;
		}

		const thisMachineContext = this;

		//Run the actions in parallel
		runSaga({}, function* () {
			for (let actionKey of actions) {
				if (typeof actionKey === 'string') {
					const action = thisMachineContext.actionMap[actionKey];
					if (action) {
						//Wrap actions in Mobx action
						yield forkInAction(function* () {
							yield action(thisMachineContext, data);
						});
					}
				} else if (typeof actionKey === 'function') {
					//todo
				} else if (typeof actionKey === 'object') {
					//todo
				} else {
					console.warn(`Unhandled action ${actionKey}`);
				}
			}
		});
	}

	@action.bound
	disableActionsForTesting(disableActions: boolean) {
		this._disableActionsForTesting = disableActions;
	}

	matchesState = (state: string | string[]) => {
		const statePathToMatch = ([] as string[]).concat(state).join('.');
		const match = new RegExp(`(^|\\.)${statePathToMatch}($|\\.)`);
		const statePaths = toStatePaths(this.machineStateValue).map(s => s.join('.'));

		return statePaths.some(statePath => match.test(statePath));
	}

	matchesAnyState = (states: (string | string[])[]) => {
		return states.some(state => this.matchesState(state));
	}

	matchesAllStates = (states: (string | string[])[]) => {
		return states.every(state => this.matchesState(state));
	}
}
