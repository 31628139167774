import * as React from 'react';
import { observer } from 'mobx-react';

import { ConfirmPledgeViewModel } from './confirm-pledge-view-model';
import * as styles from './confirm-pledge.less';
import { IPageBaseProps } from '../shared/page-props-base';
import { ErrorMessage } from '../../components/error-message/error-message';
import { PledgeButton } from '../../components/pledge-button/pledge-button';

import { getUniqueElementId } from '../../../LoggedInWeb/components/form-controls/form-control-utils';
import { Formatter } from '../../../LoggedInWeb/helpers/formatter';
import { AmountDisplay } from '../../../WebGiving/components/amount-display/amount-display';
import { FadeTransition, fadeTransitionDuration } from '../../../WebGiving/components/fade-transition';
import { PanelContentTransition } from '../../../WebGiving/components/panel-content-transition';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { PanelHeading } from '../../../WebGiving/components/panel-heading';
import { Form } from '../../../WebGiving/primitives/form';
import { EditLink } from '../../../WebGiving/primitives/link/link';
import { Currency } from '../../../WebGiving/webgiving-generated';
import { RecurringCalculatorSummary } from '../../components/recurring-calculator-summary/recurring-calculator-summary';
import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';

@observer
export class ConfirmPledge extends React.Component<IPageBaseProps<ConfirmPledgeViewModel>> {

	panelBodyNode: HTMLDivElement;

	render() {
		const { vm, transitionState } = this.props;
		const {
			amount,
			currency,
			campaignName,
			isLoading,
			error,
			pledgeLabel,
			recurringOption,
		} = vm;

		const elementsShouldEnter = transitionState === 'entered';
		const amountFormatted = Formatter.formatNumberForDisplay(amount, 2);
		const errorMessageElementId = 'confirm-pledge-error-message_' + getUniqueElementId();

		return (
			<div>
				<Form onSubmit={this.handleSubmit}>
					<PanelHeading vm={this.props.vm}>
						<FadeTransition in={elementsShouldEnter}>
							<div>
								<h1 className="panel-title">Confirm Your {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounSentenceCase : "pledge"}</h1>
							</div>
						</FadeTransition>
					</PanelHeading>
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<PanelContentTransition in={elementsShouldEnter} setContentHeight={this.setContentHeight} className="panel-body-content">
							<div>
								<ErrorMessage error={error} id={errorMessageElementId} />
								<p className={styles.topLabel}>You are about to {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.VerbLowerCase : "pledge"}</p>
								<AmountDisplay amount={amount} currencyCode={Currency[currency]} />
								<p className={styles.bottomLabel}>to {campaignName}</p>
								<p className={styles.editLink}><EditLink branded={false} onClick={this.handleEditLinkClick} acceptanceTestTargetId="change-details">Change details</EditLink></p>
								<div>
									{recurringOption  &&
										<RecurringCalculatorSummary
											recurringAmount={recurringOption.amount}
											startDate={recurringOption.starts}
											endDate={recurringOption.ends}
											totalAmount={recurringOption.amount * recurringOption.occurrences}
											totalGifts={recurringOption.occurrences}
											frequencyLabel={recurringOption.label}
											showAmountRoundedWarning={amount !== (recurringOption.amount * recurringOption.occurrences)}
										/>
									}
								</div>
							</div>
						</PanelContentTransition>
					</div>
					<PanelFooter className={""}>
						<FocusOnMount delay={fadeTransitionDuration}>
							{recurringOption ? 
								<SubmitButton isLoading={isLoading} acceptanceTestTargetId="GoToPaymentMethodButton">
									Go to Payment Method
								</SubmitButton>
							 : 
								<PledgeButton
									amountFormatted={amountFormatted}
									currencyCode={Currency[currency]}
									pledgeLabel={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.VerbSentenceCase : "Pledge"}
									isLoading={isLoading}
									acceptanceTestTargetId="ConfirmPledgeButton"
								/>
							}
						</FocusOnMount>
					</PanelFooter>
				</Form>
			</div>
		);
	}

	private handleEditLinkClick = () => {
		this.props.vm.handleEdit();
	}

	private handleSubmit = () => {
		this.props.vm.handleSubmit();
	}

	private setContentHeight = (offsetHeight: number) => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${offsetHeight}px`;
		}
	}

	private savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;
}
