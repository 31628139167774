export function confirmationCodeSentMessage(userPrefersVoiceCalls: boolean, userHitTimeLimit?: boolean) {
	if (NewFeatures.ApplePay_WebGiving) {
		const limitText = userHitTimeLimit ? 'The time limit has expired. ': '';
		return userPrefersVoiceCalls
			? `${limitText}We'll call you with a 6-digit security code.`
			: `${limitText}We've sent a text message to your mobile with a 6-digit security code.`;
	} else {
		return userPrefersVoiceCalls
			? `We'll call you with a 6-digit security code.`
			: `We've sent a text message to your mobile with a 6-digit security code.`;
	}
}
