import * as React from 'react';
import { observer } from 'mobx-react';

import { DonorPledgeEntryPushpayInfo } from '../../donorpledgeentry-generated';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';

import { Tooltip, TooltipPlacement } from '../../../WebGiving/components/tooltip/tooltip';
import { ModalHeader, ModalContents } from '../../../WebGiving/components/modal/modal';
import { ExternalLink, RightChevronLink, Link, DisabledRightChevronLink } from '../../../WebGiving/primitives/link/link';

import * as style from './help-modal.less';

export interface IHelpModalProps {
	showResendSecurityCode: boolean;
	pushpayInfo: DonorPledgeEntryPushpayInfo;
	resentCodeState: boolean;
	machineContext: MachineContext;
	onSendVoiceCall: () => void;
	onResendVoiceCall: () => void;
	onResendTextMessage: () => void;
}

export class HelpModalHeader extends React.Component {
	render() {
		return <ModalHeader text="Need help?" />;
	}
}

@observer
export class HelpModalContent extends React.Component<IHelpModalProps, {}> {
	public render() {
		const {
			showResendSecurityCode,
			resentCodeState,
			pushpayInfo: { HelpCenterLink, UpdateProfileUrl, ResetPasswordUrl },
		} = this.props;

		const sendCode = showResendSecurityCode ? (
			<section className={style.section}>
				<h2 className={`${style.text} ${style.header}`}>Resend security code</h2>
				<div className={style.link}>
					{resentCodeState
						? <>
							<div className={style.linkItem}>
								<Tooltip placement={TooltipPlacement.Bottom} content={`We can only send you a code every 20 seconds.`} hideOnMobile>
									<DisabledRightChevronLink branded={true}>Via text message</DisabledRightChevronLink>
								</Tooltip>
							</div>
							<div className={style.linkItem}>
								<Tooltip placement={TooltipPlacement.Bottom} content={`We can only send you a code every 20 seconds.`} hideOnMobile>
									<DisabledRightChevronLink branded={true}>Via voice call</DisabledRightChevronLink>
								</Tooltip>
							</div>
						  </>
						: <>
							<div className={style.linkItem}>
								<ResendTextMessageLink resendTextMessage={this.resendTextMessage} />
							</div>
							<div className={style.linkItem}>
								<RightChevronLink branded={true} acceptanceTestTargetId="Via voice call" onClick={this.resendVoiceCall}>
									Via voice call
								</RightChevronLink>
							</div>
						  </>
					}
				</div>
			</section>
		) : (
				<section className={style.section}>
					<h2 className={`${style.text} ${style.header}`}>Receive a security code by voice call</h2>
					<div className={style.link}>
						<RightChevronLink branded={true} acceptanceTestTargetId="Send code via voice call" onClick={this.sendVoiceCall}>
							Send code via voice call
						</RightChevronLink>
					</div>
				</section>
			);

		return (
			<ModalContents>
				<p className="lead">
					We understand that sometimes there can be issues with receiving text messages. Please select an option from the list below if you are experiencing any issues.
				</p>
				{sendCode}

				<section className={style.section}>
					<div className={style.text}>
						<h2 className={style.header}>Changed your mobile number</h2>
						<p className={style.details}>
							You will need to sign in to your account using your email and password and then update your mobile number.
							If you don't have a password already you can
							create one by using the <Link branded={true} acceptanceTestTargetId="Reset password" href={ResetPasswordUrl} openInNewTab>
								Reset password
							</Link> link.
						</p>
					</div>
					<div className={style.link}>
						<ExternalLink branded={true}
							acceptanceTestTargetId="Update mobile"
							href={UpdateProfileUrl}>
							Update mobile
						</ExternalLink>
					</div>
				</section>

				<section className={style.section}>
					<div className={style.text}>
						<h2 className={style.header}>Still need help?</h2>
					</div>
					<div className={style.link}>
						<ExternalLink branded={true} acceptanceTestTargetId="Visit Help Center" href={HelpCenterLink}>
							Visit Help Center
						</ExternalLink>
					</div>
				</section>
			</ModalContents>
		);
	}

	private sendVoiceCall = () => {
		this.props.onSendVoiceCall();
	}

	private resendVoiceCall = () => {
		this.props.onResendVoiceCall();
	}

	private resendTextMessage = () => {
		this.props.onResendTextMessage();
	}
}

const ResendTextMessageLink = ({ resendTextMessage, disabled }: { resendTextMessage: () => void, disabled?: boolean }) => {
	return (
		<RightChevronLink branded={true} acceptanceTestTargetId="Via text message" href="" disabled={disabled} onClick={resendTextMessage}>
			Via text message
		</RightChevronLink>
	);
};
