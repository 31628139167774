declare const UNIT_TEST: boolean;
declare const STORYBOOK: boolean;

//API over GA itself
export namespace GoogleAnalyticsWrapper {
	let _areaPrefix: string | undefined = UNIT_TEST ? 'UnitTest' : STORYBOOK ? 'Storybook' : undefined;
	let tagId = document.body.getAttribute('data-ga-tracker-id');
	let usingGTag = false;

	export function initialize(areaPrefix: string) {
		if (_areaPrefix) {
			console.error('Google Analytics has already been initialized');
			return;
		}

		_areaPrefix = areaPrefix;

		if (tagId != null && tagId.startsWith("G")) {
			usingGTag = true;

			var script = document.createElement('script');
			script.src = "https://www.googletagmanager.com/gtag/js?id=" + tagId;
			script.async = true;
			document.head.appendChild(script);

			window.dataLayer = window.dataLayer || [];
			gtag('js', new Date());
			gtag('config', tagId);
		} else {
			((i, s, o, g, r) => {
				i['GoogleAnalyticsObject'] = r;
				i[r] =
					i[r] ||
					function () {
						(i[r].q = i[r].q || []).push(arguments);
					};
				i[r].l = 1 * <any>new Date();
				var a = s.createElement(o);
				var m = s.getElementsByTagName(o)[0];
				a.async = 1;
				a.src = g;
				m.parentNode.insertBefore(a, m);
			})(<any>window, <any>document, 'script', '//www.google-analytics.com/analytics.js', '__gaTracker');

			gaTracker('create', tagId, 'auto');
		}
	}

	function gtag(...args : any[]) {
		window.dataLayer.push(arguments);
	}

	function gaTracker(command: string, ...args: any[]) {
		window.__gaTracker(command, ...args);
	}

	function sendPageView(url: string) {
		if (usingGTag){
			gtag('event', 'page_view', { 'url': url });
			return;
		}
		gaTracker('send', 'pageview', url);
	}

	export function sendEvent(category: string, action: string, label?: string, value?: any, fieldObject?: any) {
		if (usingGTag){
			const parameters = [label, value, fieldObject].filter((x) => x !== undefined);
			gtag('event', `${category}.${action}`, parameters);
			return;
		}

		const args = [category, action, label, value, fieldObject].filter((x) => x !== undefined);
		gaTracker('send', 'event', ...args);
	}

	//our higher level API:
	export function pageView(page: string) {
		if (!_areaPrefix) {
			console.error('Google GTag has not been initialized');
			return;
		}

		sendPageView(getPathFromPage(page));
		sendEvent(`${_areaPrefix} Page View`, page);
		console.log(`${_areaPrefix} Page View: ${page}`);
	}

	function getPathFromPage(page: string): string {
		return `${window.location.pathname}/${page}`;
	}

	export function pageViewWithLanguage(page: string, language: string) {
		if (!_areaPrefix) {
			console.error('Google Analytics has not been initialized');
			return;
		}

		sendPageView(getPathFromPage(page));
		sendEvent(`${_areaPrefix} Page View`, page, `language-${language}`);
	}
}
