import * as React from 'react';
import { observer } from 'mobx-react';
import { OrganizationLogo } from './organization-logo';
import { ErrorBoundary } from '../../../Shared/components/error-boundary';
import { ErrorInfo } from '../../utils/error-utils';
import { BrandingPackage } from '../../donorpledgeentry-generated';
import { classNames } from '../../../Shared/utils/classnames';

import {
	merchantLogo,
	enhancedLogo,
	headerContainer,
	headerBackground,
	merchantName,
	merchantHeader,
	merchantDetailsWrapper,
	enhancedDetailsWrapper,
	salutation,
} from './header.less';

export interface IHeaderProps {
	welcomeMessage?: string | null;
	className?: string;
	legalName: string;
	logoUrl: string;
	brandingPackage: BrandingPackage | null;
	onError: (errorInfo: ErrorInfo, customData?: any) => void;
	transparentBackground?: boolean;
}

@observer
export class MainHeader extends React.Component<IHeaderProps> {
	render() {
		const {
			className,
			onError,
			welcomeMessage,
			legalName,
			brandingPackage,
			logoUrl,
			transparentBackground = false,
		} = this.props;

		let merchantLogoAlt = legalName;
		let organizationLogoUrl = logoUrl;
		let merchantLogoClass = merchantLogo;
		let useBrandingPackageLogo = false;

		let containerClasses = classNames(
			'container',
			headerContainer,
		);
		let headerBackgroundClasses = classNames(
			headerBackground,
			{ 'brand-secondary-bg': !transparentBackground },
		);
		let salutationClasses = classNames(
			salutation,
			{'brand-secondary-alt-bg': !transparentBackground},
		);

		if (brandingPackage && brandingPackage.EnhancedLogoImageUrl) {
			useBrandingPackageLogo = true;
			organizationLogoUrl = brandingPackage.EnhancedLogoImageUrl;
			merchantLogoClass = enhancedLogo;
		}

		return (
			<header className={className}>
				{welcomeMessage && <div className={salutationClasses}>{welcomeMessage}</div>}
				<div className={headerBackgroundClasses}>
					<div className={containerClasses}>
						<div className={merchantHeader}>
							<div className={`${useBrandingPackageLogo ? enhancedDetailsWrapper : merchantDetailsWrapper}`}>
								<ErrorBoundary onError={onError}>
									<OrganizationLogo
										logoUrl={organizationLogoUrl}
										legalName={legalName}
										websiteUrl={null}
										logoClass={merchantLogoClass}
										alt={merchantLogoAlt}
									/>
								</ErrorBoundary>
								{useBrandingPackageLogo ? null :
									<div className={merchantName}>{legalName}</div>
								}
							</div>
							{this.props.children}
						</div>
					</div>
				</div>
			</header>
		);
	}
}
