import * as React from 'react';
import { observer } from 'mobx-react';
import { getUniqueElementId } from '../../../Shared/utils/form-control-utils';
import { FrequencyCode } from '../../../WebGiving/webgiving-generated';
import { FormField } from '../../../WebGiving/components/form-field';
import { SvgIcon, SvgProperties } from '../svg-icon';
import { responsiveHelper } from '../../../Shared/helpers/responsive-helper';
import { classNames } from '../../../Shared/utils/classnames';
import { SelectField } from '../../../WebGiving/components/form-fields';
import { IOptionProps } from '../../../WebGiving/primitives/select';

import * as styles from './frequency-combo-button.less';

const OPTIONS_BEFORE_DROPDOWN = 4;

export interface IFormControlFrequencyComboButtonProps<T> {
	/**
	 * Current value of frequency button radio group
	 */
	field: FormField<T>;
	acceptanceTestTargetId: string;
	formControlId?: string;
	options: IFormControlFrequencyComboButtonOptions<T>[];
	/**
	 * The ARIA label text for the frequency button radio group.
	 * Required if ariaLabelledBy is not in use.
	 */
	ariaLabel?: string;
	/**
	 * The ARIA labelling element ID for the frequency button radio group.
	 * Required if ariaLabel is not in use.
	 */
	ariaLabelledBy?: string;
	dataFieldInvalid?: boolean;
	className?: string;
	onChange?: (x: any) => void;
}

export interface IFormControlFrequencyComboButtonOptions<T> {
	value: T;
	label: string;
	icon?: SvgProperties;
}

export interface IFormControlFrequencyComboButtonState<T> {
	row: IFormControlFrequencyComboButtonOptions<T>[];
	dropdown: IFormControlFrequencyComboButtonOptions<T>[];
}

@observer
export class FormControlFrequencyComboButton extends React.Component<
	IFormControlFrequencyComboButtonProps<any>,
	IFormControlFrequencyComboButtonState<any>
> {
	private fallbackElementId = getUniqueElementId();
	private dropDownOptionsPlaceholderLabel = 'More';
	private dropDownOptionIsSelected = false;
	private previousSelectedValue = null;
	private dropDownIsToggled = false;

	constructor(props: IFormControlFrequencyComboButtonProps<any>) {
		super(props);
		const { row, dropdown } = this.getRowAndDropOptions(this.props.options);
		this.state = { row, dropdown };
	}

	public UNSAFE_componentWillUpdate(nextProps: any) {
		if (nextProps.options !== this.props.options) {
			const { row, dropdown } = this.getRowAndDropOptions(nextProps.options);
			this.setState({ row, dropdown });
		}
	}

	render() {
		const { acceptanceTestTargetId, options, ariaLabel, ariaLabelledBy, className } = this.props;

		const { row: splitButtonOptions, dropdown } = this.state;

		const dropDownOptions = dropdown.length
			? this.initDropDownOptions(dropdown, this.dropDownOptionsPlaceholderLabel)
			: undefined;
		const mobileDropDownOptions = this.initMobileDropDownOptions(options, this.dropDownOptionsPlaceholderLabel);

		return (
			<div className={className}>
				{responsiveHelper.isXs ? (
					<div className={classNames(styles.formControlFrequencyMobileDropdown)}>
						{this.renderMobileDropDown(mobileDropDownOptions)}
					</div>
				) : (
					<div
						className={classNames(
							styles.formControlFrequencyComboButton,
							styles.formControlFrequencyComboDropdown,
							{
								[styles.noDropdown]: !Boolean(dropDownOptions),
							}
						)}
						role="radiogroup"
						aria-label={ariaLabel}
						aria-labelledby={ariaLabelledBy}
						data-pp-at-target={acceptanceTestTargetId}
					>
						{this.renderSplitButton(splitButtonOptions)}
						{dropDownOptions && this.renderDropDown(dropDownOptions)}
					</div>
				)}
			</div>
		);
	}

	private renderSplitButton = (options: IFormControlFrequencyComboButtonOptions<any>[]) => {
		const {
			field: { value },
			acceptanceTestTargetId,
			formControlId = this.fallbackElementId,
			dataFieldInvalid = false,
		} = this.props;
		return (
			<>
				{options.map((option, index) => {
					const checked = value === option.value;
					const htmlFor = `${formControlId}_${option.value}`;

					let labelClasses = classNames(
						styles.formControlFrequencyComboButtonLabel,
						` brand-primary-hover brand-primary-border-hover`,
						{
							'brand-primary-bg brand-primary-border is-active': checked,
							'form-control-invalid': dataFieldInvalid,
							[styles.isActive]: checked,
						}
					);

					const icon = option.icon;

					return [
						<input
							type="radio"
							className={styles.formControlFrequencyComboButtonRadio}
							key={`input_${option.value}`}
							value={option.value}
							checked={checked}
							id={htmlFor}
							name={formControlId}
							onChange={this.handleChangeFromSplitButton}
							data-pp-at-target={`${acceptanceTestTargetId}_${option.label}`}
						/>,
						<label
							className={labelClasses}
							key={`label_${option.value}`}
							htmlFor={htmlFor}
							data-pp-at-target={`${acceptanceTestTargetId}_label_${option.label}`}
						>
							{icon && <SvgIcon svg={icon} />}
							<span>{option.label}</span>
						</label>,
					];
				})}
			</>
		);
	};

	private renderDropDown = (options: IOptionProps[]) => {
		const {
			field,
			field: { value },
			acceptanceTestTargetId,
			formControlId,
		} = this.props;

		const preconfigured =
			[
				FrequencyCode.FirstAndFifteenth,
				FrequencyCode.Quarterly,
				FrequencyCode.SemiYearly,
				FrequencyCode.Yearly,
			].indexOf(value) !== -1;

		let classes = classNames(` brand-primary-hover brand-primary-border-hover`, {
			'brand-primary-bg brand-primary-border is-active': this.dropDownOptionIsSelected || preconfigured,
			[styles.isActive]: this.dropDownOptionIsSelected || preconfigured,
			[styles.isUntoggled]: !this.dropDownIsToggled,
		});
		return (
			<SelectField
				formControlId={formControlId}
				formField={field}
				options={options}
				acceptanceTestTargetId={acceptanceTestTargetId}
				className={styles.dropdownWrapper}
				additionalClassNames={classes}
				onChange={this.handleChangeForDropDown}
				onClick={this.handleClickForDropDown}
				onBlur={this.handleBlurForDropDown}
			/>
		);
	};

	private renderMobileDropDown = (options: IOptionProps[]) => {
		const { field, acceptanceTestTargetId, formControlId } = this.props;

		return (
			<SelectField
				formControlId={formControlId}
				formField={field}
				options={options}
				acceptanceTestTargetId={acceptanceTestTargetId}
				onChange={this.handleChangeForDropDown}
			/>
		);
	};

	private getRowAndDropOptions(options: IFormControlFrequencyComboButtonOptions<any>[]) {
		const dropdown = [];
		const row = options.filter((opt) =>
			[FrequencyCode.Weekly, FrequencyCode.Fortnightly, FrequencyCode.Monthly].includes(opt.value)
		);

		for (const opt of options) {
			if (row.length < OPTIONS_BEFORE_DROPDOWN && !row.includes(opt)) {
				row.push(opt);
			} else if (!row.includes(opt)) {
				dropdown.push(opt);
			}
		}
		return { row, dropdown };
	}

	private initDropDownOptions(
		options: IFormControlFrequencyComboButtonOptions<any>[],
		placeholderLabel?: string
	): IOptionProps[] {
		const opts: IOptionProps[] = options.map((opt) => ({
			value: opt.value,
			label: opt.label,
		}));

		if (!!placeholderLabel) {
			this.addPlaceholderOption(placeholderLabel, opts);
		}

		return opts;
	}

	private initMobileDropDownOptions(
		options: IFormControlFrequencyComboButtonOptions<any>[],
		placeholderLabel?: string
	): IOptionProps[] {
		const opts: IOptionProps[] = options.map((opt) => ({
			value: opt.value,
			label: opt.label,
			disableTranslation: false,
		}));

		if (!!placeholderLabel) {
			this.addPlaceholderOption(placeholderLabel, opts);
		}

		return opts;
	}

	private addPlaceholderOption(placeholderLabel: string, opts: IOptionProps[]) {
		const emptyOption: IOptionProps = {
			label: placeholderLabel,
			value: '',
			disableTranslation: false,
			translationParams: { dropDownLabel: placeholderLabel },
			disabled: true,
		};
		opts.unshift(emptyOption);
	}

	private handleChangeFromSplitButton = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange, field, options } = this.props;
		const value = parseInt(event.currentTarget.value);
		const frequency = options.filter((o) => o.value === value)[0];
		if (frequency && frequency.value) {
			field.updateValue(frequency.value);
			field.revalidate();

			if (onChange) {
				onChange(frequency.value);
			}
		}
		this.dropDownOptionIsSelected = false;
		this.dropDownIsToggled = false;
	};

	private handleChangeForDropDown = () => {
		const { field, onChange } = this.props;

		if (onChange) {
			onChange(field.value);
		}

		this.dropDownOptionIsSelected = true;
		this.handleCustomDropdownHighlighting(field);
	};

	private handleClickForDropDown = () => {
		const { field } = this.props;

		this.handleCustomDropdownHighlighting(field);
	};

	private isSplitButtonOption = (formFieldValue: any) => this.state.row.indexOf(formFieldValue) !== -1;

	private handleCustomDropdownHighlighting(field: FormField<any>) {
		if (!this.dropDownIsToggled) {
			this.dropDownIsToggled = true;
			this.previousSelectedValue = field.value;

			if (this.isSplitButtonOption(field.value)) {
				field.updateValue(null);
			}
		} else if (this.dropDownIsToggled) {
			if (field.value === null && this.isSplitButtonOption(this.previousSelectedValue)) {
				this.dropDownIsToggled = false;
				field.updateValue(this.previousSelectedValue);
			} else {
				this.dropDownIsToggled = true;
			}
			this.previousSelectedValue = null;
		}
	}

	private handleBlurForDropDown = () => {
		const { field } = this.props;

		if (this.dropDownIsToggled) {
			if (this.isSplitButtonOption(this.previousSelectedValue)) {
				field.updateValue(this.previousSelectedValue);
				this.previousSelectedValue = null;
			}
			this.dropDownIsToggled = false;
		}
	};
}
