import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { MainViewModel } from '../../main/main-view-model';
import {
	CreatePledgeAlreadyExistsResponse,
	CreatePledgeRequest,
	CreatePledgeResultStatus,
	CreatePledgeServiceUnavailableResponse,
	CreatePledgeSessionExpiredResponse,
	CreatePledgeSuccessResponse
} from '../../donorpledgeentry-generated';
import { getDonorPledgeEntryDataService } from '../../donorpledgeentry-data-service';
import { InvokingMachineEvents } from '../../state-machine/invoking-states-events-actions';
import { generateInvokingStateMachineNew } from '../../state-machine/invoking-state-machine';
import { Actions, Events } from '../../state-machine/states-events-actions';

const genericErrorMessage = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;

type CreatePledgeResponse =
	| CreatePledgeSuccessResponse
	| CreatePledgeAlreadyExistsResponse
	| CreatePledgeSessionExpiredResponse
	| CreatePledgeServiceUnavailableResponse;

export function* postConfirmPledge(machineContext: MachineContext<MainViewModel>, createPledgeRequest: CreatePledgeRequest) {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const {
		amount,
		setAmount,
		setPersonHasPledged,
		pledgeExistsViewModel:{
			setRecurringInfo,
			setPledgeProgressUrl
		},
		recurringOption,
		confirmCreatePledgeViewModel: { setRequestCode },
		pledgeSuccessViewModel: { setAmount: setSuccessPageAmount, setPledgeProgressUrl: setSuccessPagePledgeProgressUrl },
	} = mainViewModel;

	try {
		const response: CreatePledgeResponse = yield getDonorPledgeEntryDataService().createPledge({ model: createPledgeRequest as CreatePledgeRequest }, () => (mainViewModel));
		switch (response.Status) {
			case CreatePledgeResultStatus.Success:
				if(response.WebGivingRedirectUrl) {
					window.location.href = decodeURIComponent(response.WebGivingRedirectUrl);
				}
				else {
					setSuccessPagePledgeProgressUrl(response.CampaignsRedirectUrl);
					setSuccessPageAmount(amount);
					setAmount(0);
					setPersonHasPledged(true);
					Events.raise.PledgeConfirmed(machineContext);
				}
				break;
			case CreatePledgeResultStatus.UserSessionExpired:
				setRequestCode(response.RequestToken);
				Events.raise.UserSessionExpired(machineContext);
				break;
			case CreatePledgeResultStatus.UserAlreadyPledged:
				setAmount(0);
				setPersonHasPledged(true);
				setPledgeProgressUrl(response.CampaignsRedirectUrl);
				setRecurringInfo({
					amount,
					option:recurringOption,
					redirectUrl: response.WebGivingRedirectUrl,
				});
				Events.raise.UserAlreadyPledged(machineContext);
				break;
			case CreatePledgeResultStatus.ServiceUnavailable:
			default:
				mainViewModel.confirmPledgeViewModel.setError([genericErrorMessage]);
				InvokingMachineEvents.raise.RequestFailure(machineContext);
		}
	} catch {
		mainViewModel.confirmPledgeViewModel.setError([genericErrorMessage]);
		InvokingMachineEvents.raise.RequestFailure(machineContext);
	}
}

export const confirmPledgeMachineConfig =
	() => generateInvokingStateMachineNew(Actions.ProcessConfirmPledgeRequest, "", Actions.InvokingFailureDefault);
