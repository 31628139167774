export function getBoundingRect(element: Element) {
	//elemRect is relative to viewport space, we need to convert to it document space
	//otherwise we can get positioning issues on devices with ability to touch scale

	return getBoundingRectRelativeToContainer(element, document.body);
}

export function getBoundingRectRelativeToContainer(element: Element, container: Element) {
	const containerRect = container.getBoundingClientRect();
	const elemRect = element.getBoundingClientRect();

	//elemRect is relative to viewport space, we need to convert to it document space
	//otherwise we can get positioning issues on devices with ability to touch scale

	return {
		top: elemRect.top - containerRect.top,
		left: elemRect.left - containerRect.left,
		right: elemRect.right - containerRect.right,
		bottom: elemRect.bottom - containerRect.bottom,
		width: elemRect.width,
		height: elemRect.height,
	};
}
