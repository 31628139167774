import { DonorPledgeEntryPushpayInfo, DonorPledgeEntryPersonModel } from '../donorpledgeentry-generated';

export function generateManageAccountUrl(pushpayInfo: DonorPledgeEntryPushpayInfo, person: DonorPledgeEntryPersonModel | null) {
	if (!person) {
		return pushpayInfo.ManageAccountUrl;
	}

	// If we start using exotic URLs with fragments or what-not this will stop working
	const baseUrl = pushpayInfo.ManageAccountUrl;
	const signedPersonId = person.SignedPersonId;
	const splicer = baseUrl.indexOf('?') > -1 ? '&' : '?';

	return baseUrl + splicer + `signedPersonId=${signedPersonId}`;
}
