import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { TransitionProps } from 'react-transition-group/Transition';
import { SvgIcon } from '../../components/svg-icon';
import closeIcon from '../../assets/svg/icon-close.svg';
import { classNames } from '../../../Shared/utils/classnames';
import { Text } from '../../components/text';
import * as ua from 'ua-parser-js';

import * as style from './modal.less';

export interface IModalProps extends Partial<TransitionProps> {
	onClose?: () => void;
}

@observer
export class Modal extends React.Component<IModalProps, {}> {
	private renderTarget: HTMLElement;
	private wrapper: HTMLDivElement | null;
	private closeButton: HTMLButtonElement | null;

	render() {
		const { onClose, children, ...rest } = this.props;

		const classNames = {
			appear: style.containerEnter,
			appearActive: style.containerEnterActive,
			enter: style.containerEnter,
			enterActive: style.containerEnterActive,
			exitActive: style.containerExitActive,
	};

		const modal = (
			<CSSTransition timeout={parseInt(style.modalTransitionDuration)}
				classNames={classNames}
				mountOnEnter
				unmountOnExit
				onEntered={this.onEntered}
				onExiting={this.onExiting}
				{...rest}>
				<div className={`${style.container}`}>
					<div className={style.background} />
					<div className={style.wrapper} ref={ref => this.wrapper = ref}>
						<button type="button"
							className={`btn-link ${style.close}`}
							onClick={onClose}
							ref={ref => this.closeButton = ref}
							data-pp-at-target="Close Modal"
							aria-label="Close">
							<SvgIcon svg={closeIcon} className={`icon ${style.closeIcon}`} />
						</button>
						{children}
					</div>
				</div>
			</CSSTransition>
		);

		return ReactDOM.createPortal(modal, this.renderTarget);
	}

	UNSAFE_componentWillMount() {
		this.renderTarget = document.createElement('div');
		this.renderTarget.className = 'pp-modal';
		document.body.appendChild(this.renderTarget);
		window.addEventListener('resize', this.setWrapperHeightIfIE);
	}

	componentDidMount() {
		this.setWrapperHeightIfIE();
	}

	componentWillUnmount() {
		document.body.removeChild(this.renderTarget);
		window.removeEventListener('resize', this.setWrapperHeightIfIE);
	}

	onEntered = () => {
		if (this.closeButton) {
			this.closeButton.focus();
		}

		document.body.addEventListener('focus', this.trapFocus, true);
	}

	onExiting = () => {
		document.body.removeEventListener('focus', this.trapFocus, true);
	}

	private trapFocus = (ev: FocusEvent) => {
		if (this.closeButton && this.wrapper && !this.wrapper.contains(ev.target as Node)) {
			ev.preventDefault();
			this.closeButton.focus();
		}
	}

	private setWrapperHeightIfIE = () => {
		if (isIE() &&  this.wrapper) {
			this.wrapper.style.height = '';
			this.wrapper.style.height = `${this.wrapper.clientHeight}px`;
		}
	}
}

export const ModalHeader: React.StatelessComponent<{ text: string, params?: { [key: string]: any } }> = (props) => {
	return (
		<div className={style.header}><Text params={props.params}>{props.text}</Text></div>
	);
};

export const ModalHeaderShell: React.FC = (props) => <div className={style.header}>{props.children}</div>;

export const ModalFooterShell: React.FC = (props) => <div className={style.footer}>{props.children}</div>;

export const ModalContentsHtml: React.StatelessComponent<{ contentBase64: string, className?: string }> = (props) => {
	const innerHtml = { __html: atob(props.contentBase64) };
	return (<DisableTouchPropogation><div className={props.className} dangerouslySetInnerHTML={innerHtml}></div></DisableTouchPropogation>);
};

export const ModalContents: React.StatelessComponent<{}> = (props) => {
	return (<DisableTouchPropogation>{props.children}</DisableTouchPropogation>);
};

class DisableTouchPropogation extends React.Component<{}, {}> {
	private container: HTMLDivElement | null;

	// binding the eventListener with the onTouchMove prop does not stopPropagation so we use a ref
	containerRef = (containerElement: HTMLDivElement | null) => {
		if (this.container) {
			this.container.removeEventListener('touchmove', this.stopTouchPropagation, false);
		}

		this.container = containerElement;

		if (this.container) {
			this.container.addEventListener('touchmove', this.stopTouchPropagation, false);
		}
	}

	render() {
		return (
			<div className={classNames(style.content, { [style.contentIos]: isIOS() })} ref={this.containerRef}>
				{this.props.children}
			</div>
		);
	}

	private stopTouchPropagation = (ev: TouchEvent) => {
		if (this.container && this.container.scrollHeight > this.container.clientHeight) {
			ev.stopPropagation();
		}
	}
}

let iOS: boolean | null;

function isIOS() {
	if (iOS === undefined) {
		const os = new ua.UAParser(window.navigator.userAgent).getOS();
		return os.name === 'iOS';
	}
	return iOS;
}

let ie: boolean | null;

function isIE() {
	if (ie === undefined) {
		const os = new ua.UAParser(window.navigator.userAgent).getBrowser();
		return os.name === 'IE';
	}
	return ie;
}
