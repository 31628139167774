import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { isFunction } from '../../Shared/utils/is-function';
import { Formatter, stripNonNumber, centsToDollarFormat } from '../../Shared/utils/formatter';
import { floor } from '../../Shared/utils/floatmath';
import {
	handleDecoratedText,
	TextMaskOldState,
} from '../utils/text-mask';
const precision = 2;
const maxFormattedSize = '###,###.##'.length;


export enum InputStyle {
	/**
	 * Normal Style suitable for desktop and androids
	 * Shows as telephone keyboard on mobile experience
	 */
	Normal,
	/**
	 * IOS style changes the keyboard in safari to use text
	 * Shows as number keypad (without separators) on IOS
	 */
	IOS,
}

export interface IFormControlAmountProps {
	value: number;
	acceptanceTestTargetId: string;
	formControlId?: string;
	onChange?: (value: number) => void;
	onBlur?: () => void;
	placeholder?: string;
	inputStyle?: InputStyle;
	className?: string;
	dataFieldInvalid?: boolean;
	ariaDescribedBy: string;
	ariaInvalid: boolean;
	ariaRequired: boolean;
	amountIsLocked: boolean;
	autoFocus?: boolean;
}

@observer
export class FormControlAmount extends React.Component<IFormControlAmountProps> {


	defaultInputStyle = this.getSupportedInputStyle();

	private nextCaretPositionUpdate: number = 0;
	private element: HTMLInputElement;
	private oldState: TextMaskOldState | null = null;

	@observable
	private amount = '0.00';

	@observable
	private  amountAsNumber = 0;

	UNSAFE_componentWillMount() {
		this.updateComponentState(this.props.value);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IFormControlAmountProps) {
		const { value } = nextProps;
		if (value !== this.amountAsNumber) {
			this.updateComponentState(value);
		}
	}

	componentWillUnmount() {
		if (this.nextCaretPositionUpdate) {
			cancelAnimationFrame(this.nextCaretPositionUpdate);
		}
	}

	render() {
		const {
			placeholder,
			formControlId,
			className,
			dataFieldInvalid,
			acceptanceTestTargetId,
			ariaDescribedBy,
			ariaInvalid,
			ariaRequired,
			amountIsLocked,
			autoFocus,
		} = this.props;
		let { inputStyle } = this.props;
		if (inputStyle === null || inputStyle === undefined) {
			inputStyle = this.defaultInputStyle;
		}
		const inputProps = {
			id: formControlId,
			placeholder: placeholder,
			value: this.amount,
			ref: this.ref,
			type: inputStyle === InputStyle.IOS ? 'text' : 'tel',
			pattern: inputStyle === InputStyle.IOS ? '\\d*' : undefined,
			onKeyDown: this.handleKeyDown,
			onChange: this.handleOnChange,
			onBlur: this.handleOnBlur,
			onFocus: this.placeCaretAtRight,
			className,
			'data-field-invalid': dataFieldInvalid ? true : null,
			'data-pp-at-target': acceptanceTestTargetId,
			'aria-label': 'Amount',
			'aria-describedby': ariaDescribedBy,
			'aria-invalid': ariaInvalid,
			'aria-required': ariaRequired,
			disabled : amountIsLocked,
			autoFocus,
		};
		return <input {...inputProps} />;
	}

	private handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elementValue = event.currentTarget.value.trim();

		// this.oldState is set onKeyDown
		// sometimes the change event happens without keyDown (eg. context menu > paste)
		// in those cases we pass oldState as null
		const { caretPosition, value } = handleDecoratedText(this.oldState, { value: elementValue, caretPosition: event.currentTarget.selectionEnd! });

		if (value.length <= maxFormattedSize) {
			this.updateComponentState(centsToDollarFormat(stripNonNumber(value)));
			this.notifyParent();
			this.setSelection(caretPosition);
		} else if (this.oldState) {
			this.setSelection(this.oldState.selectionEnd);
		}

		this.oldState = null;
	}

	private handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
		if (isFunction(this.props.onBlur)) {
			this.props.onBlur();
		}
	}

	private placeCaretAtRight = (event: React.FormEvent<HTMLInputElement>): void => {
		const input = event.currentTarget;
		const strLengthPlus = this.amount.length * 2; // make sure we get much than the length by doubling it
		input.setSelectionRange(strLengthPlus, strLengthPlus); // setting the selection range beyond the text puts the caret at the end
	}

	@action
	private updateComponentState(value: number) {
		this.amountAsNumber = floor(value, precision);
		this.amount = Formatter.formatNumberForDisplay(this.amountAsNumber, precision);
	}

	private notifyParent(): void {
		if (isFunction(this.props.onChange)) {
			this.props.onChange(this.amountAsNumber);
		}
	}

	private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		this.oldState = {
			selectionStart: this.element.selectionStart!,
			selectionEnd: this.element.selectionEnd!,
			value: this.element.value,
		};
	}

	private setSelection = (index: number) => {
		if (this.nextCaretPositionUpdate) {
			cancelAnimationFrame(this.nextCaretPositionUpdate);
		}

		this.nextCaretPositionUpdate = requestAnimationFrame(() => {
			if (this.element.value === this.amount) {
				this.element.setSelectionRange(index, index);
			}
		});
	}

	private ref = (element: HTMLInputElement) => {
		this.element = element;
	}

	private getSupportedInputStyle(): InputStyle {
		const iOS = !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
		return iOS ? InputStyle.IOS : InputStyle.Normal;
	}
}
