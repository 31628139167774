import React from 'react';
import { observer, inject } from 'mobx-react';
import { EnterPledgeViewModel } from './enter-pledge-view-model';
import { PledgeWithRecurring } from './pledge-with-recurring/pledge-with-recurring';
import { IPageBaseProps } from '../shared/page-props-base';
import { machineContextKey } from '../../../Shared/state-machine/saga-state-machine';
import { OriginalPassiveAmountInput } from '../../../WebGiving/components/amount-input';
import { Form } from '../../../WebGiving/primitives/form';
import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';
import { FadeTransition, fadeTransitionDuration } from '../../../WebGiving/components/fade-transition';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { InjectableWebGivingProps } from '../../../WebGiving/utils/injectable-props';
import { FormField } from '../../../WebGiving/components/form-field';
import { getPageClasses } from '../../../WebGiving/pages/helpers/get-page-classes';
import { classNames } from '../../../Shared/utils/classnames';
import { Formatter } from '../../../Shared/utils/formatter';
import { getSizeName } from '../../../WebGiving/components/amount-display/amount-display';
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';
import { SvgIcon } from '../../../WebGiving/components/svg-icon';
import errorSvg from '../../../WebGiving/assets/svg/error.svg';
import * as styles from './enter-pledge.less';

@inject(machineContextKey)
@observer
export class EnterPledge extends React.Component<IPageBaseProps<EnterPledgeViewModel> & InjectableWebGivingProps> {
	panelBodyNode: HTMLDivElement;
	contentNode: HTMLDivElement;

	render() {
		const {vm, transitionState} = this.props;
		const {
			amount,
			campaignName,
			isLoading,
			isBlocked,
			pageShouldBeVisible,
			brandingPackage,
			disableValidation,
			pledgeLabel,
		} = vm;

		const elementsShouldEnter = transitionState === 'entered';

		// TODO: When removing NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar, refactor to remove these pledge* constants
		const pledgeTerm = NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar
			? pledgeLabel.NounLowerCase
			: "pledge";
		const pledgeHeading = `Make a ${pledgeTerm}`;
		const hasEnhancedCampaignName = !!(brandingPackage && brandingPackage.EnhancedLogoImageUrl);
		const headerClasses = classNames(
			styles.header,
			{
				[styles.headerHasEnhancedCampaignName]: hasEnhancedCampaignName,
			},
		);
		return (
			<div className={getPageClasses(pageShouldBeVisible, isLoading)} data-pp-at-target={pageShouldBeVisible ? `Enter pledge page` : null}>
				<Form onSubmit={this.handleSubmit}>
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<FadeTransition in={elementsShouldEnter} onExit={this.setContentHeight}>
							<div>
								<header className={headerClasses}>
									<h2 className={styles.pledgeSubtitle} data-pp-at-target="Enter pledge campaign name">
										{campaignName}
									</h2>
									<h1 className={classNames(styles.heading, styles.pledgeTitle)}>
										{pledgeHeading}
									</h1>
									<AmountInput amount={amount} acceptanceTestTargetId="Amount input" disableValidation={disableValidation} />
								</header>
								<div className={'panel-body-content'} ref={this.saveContentNode}>
									<PledgeWithRecurring vm={vm} />
								</div>
							</div>
						</FadeTransition>
					</div>
					<PanelFooter>
						<SubmitButton acceptanceTestTargetId="Next" isLoading={isLoading} isBlocked={isBlocked}>Next</SubmitButton>
					</PanelFooter>
				</Form>
			</div>
		);
	}

	handleSubmit = () => {
		this.props.vm.handleSubmit();
	}

	setContentHeight = () => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${this.contentNode.offsetHeight}px`;
		}
	}

	savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;

	saveContentNode = (contentNode: HTMLDivElement) => this.contentNode = contentNode;
}

export interface IEnterPledgeBodyTextProps {
	campaignDescription: string;
	className?: string;
}

@observer
class AmountInput extends React.Component<{
	amount: FormField<number>;
	acceptanceTestTargetId: string;
	disableValidation?: boolean;
}> {
	render() {
		const { amount, acceptanceTestTargetId, disableValidation } = this.props;
		const formattedAmount = Formatter.formatNumberForDisplay(amount.value, 2);
		const wrapperClassName = `${styles.amountInputWrapper} ${amount.value === 0 ? styles.zero : ''}`;
		const errorClass = classNames({
			[styles.amountValidationError]: amount.hasValidationError,
		});

		return (
			<div className="form-control-container">
				<div className={`${styles.amount} ${getSizeName(formattedAmount)}`}>
					<FocusOnMount delay={fadeTransitionDuration}>
						<OriginalPassiveAmountInput
							value={amount.value}
							dataFieldInvalid={amount.hasValidationError}
							onChange={amount.updateValue}
							onBlur={!disableValidation ? amount.validateEveryTimeCauseImASpecialCase : undefined}
							className={styles.input}
							acceptanceTestTargetId={acceptanceTestTargetId}
							ariaDescribedBy="amount-validation-error"
							ariaInvalid={amount.hasValidationError}
							ariaRequired={true}
							amountIsLocked={amount.isLocked}
						/>
					</FocusOnMount>
					<div className={styles.decorator}>
						<span className={wrapperClassName}>
							<span className={classNames(styles.dollar)} aria-hidden="true" role="presentation">
								$
							</span>
							<span className={styles.underline}>{formattedAmount}</span>
						</span>
					</div>
				</div>
				<div id="amount-validation-error" className={errorClass} role="alert" aria-atomic="true">
					{amount.hasValidationError && <SvgIcon svg={errorSvg} className={styles.errorIcon} ariaLabel="There is a problem with the amount field:" />}
					{amount.hasValidationError && <div className={styles.errorContent}>{amount.validationError}</div>}
				</div>
			</div>
		);
	}
}
