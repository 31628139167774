import * as React from 'react';
import { observer } from 'mobx-react';
import { IDatePickerCommonProps } from '../date-picker/date-picker';
import { FormField } from '../form-field';
import { DatePicker } from '../date-picker/date-picker';
import { OptionalDate } from '../date-picker/date-picker';
import { RuleType } from '../../validation/validation-rules';

export interface IDatePickerFieldSpecificProps {
	formField: FormField<OptionalDate>;
	onChange?: (d: OptionalDate) => void;
}

export type IDatePickerFieldProps = IDatePickerCommonProps & IDatePickerFieldSpecificProps;

@observer
export class DatePickerField extends React.Component<IDatePickerFieldProps, {}> {
	render() {
		const { value, hasValidationError, hasValidationRule } = this.props.formField;
		const ariaRequired = hasValidationRule(RuleType.Required);

		return (
			<DatePicker
				{...this.props}
				value={value}
				onChange={this.handleChange}
				aria-invalid={hasValidationError}
				aria-required={ariaRequired}
			/>
		);
	}

	handleChange = (value: any) => {
		const { formField: { updateValue, revalidate }, onChange } = this.props;
		if (onChange) {
			onChange(value);
		}
		updateValue(value);
		revalidate();
	}
}
