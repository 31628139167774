import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Country, CountryData } from '../../donorpledgeentry-generated';
import { classNames } from '../../../Shared/utils/classnames';
import { FormField } from '../../../WebGiving/components/form-field';
import { SelectField } from '../../../WebGiving/components/form-fields';
import { FormControlWithIcon, FormControlIcon } from '../../../WebGiving/components/control-with-icon/control-with-icon';
import { panelContentTransitionDuration } from '../../../WebGiving/components/panel-content-transition';
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';
import { TextMaskInput } from '../../../WebGiving/components/text-mask-input';
import { Textbox } from '../../../WebGiving/primitives/textbox';
import { PhoneNumberUtils } from '../../utils/phone-number-utils';
import { TextMask } from '../../../WebGiving/utils/text-mask';
import * as styles from './mobile-phone-control.less';
import { SvgIcon } from '../../../WebGiving/components/svg-icon';
import icon from '../../../WebGiving/assets/svg/mobile.svg';

@observer
export class MobilePhoneControl extends React.Component<{
	acceptanceTestTargetId: string;
	numberField: FormField<string>;
	countryField: FormField<Country>
	formControlId?: string;
	focusOnMount?: boolean;
}> {

	@computed
	get maskFn() {
		const { countryField } = this.props;
		return (value: string) => PhoneNumberUtils.phoneNumberTextMask(value, countryField.value);
	}

	@computed
	get placeholderMobileNumber() {
		const { countryField } = this.props;
		return PhoneNumberUtils.getExampleMobileNumber(countryField.value);
	}

	render() {
		const { formControlId, numberField, countryField, acceptanceTestTargetId, focusOnMount } = this.props;
		const countryOptions = CountryData.filter(c => c.countryCode != Country.SG).map(x => ({ value: x.countryCode, label: x.displayCode2 }));
		const invalidClassName = 'form-control-invalid';
		const countryClassNames = classNames('form-control-group-left', { [invalidClassName]: numberField.hasValidationError });
		const numberClassNames = classNames('form-control-group-right', { [invalidClassName]: numberField.hasValidationError });
		const { value, updateValue, revalidate, hasValidationError } = numberField;

		const mobileInput = (
			<PhoneMaskInput country={countryField.value}>
				<Textbox
					value={value}
					onChange={updateValue}
					onBlur={revalidate}
					aria-invalid={hasValidationError}
					dataFieldInvalid={numberField.hasValidationError}
					type="tel"
					autoComplete="tel"
					placeholder={this.placeholderMobileNumber}
					formControlId={formControlId}
					acceptanceTestTargetId={`${acceptanceTestTargetId} number`}
					additionalClassNames={numberClassNames}
				/>
			</PhoneMaskInput>
		);
		return (
			<div className={`${styles.container} form-control-group`}>
				<FormControlWithIcon iconPlacement="left" squished>
					<SelectField formField={countryField}
						options={countryOptions}
						onChange={this.handleCountryFieldChange}
						additionalClassNames={countryClassNames}
						acceptanceTestTargetId={`${acceptanceTestTargetId} country`}
						aria-label="Mobile phone country"
					/>
					<FormControlIcon>
						<SvgIcon svg={icon} />
					</FormControlIcon>
				</FormControlWithIcon>

				<div>
					{focusOnMount ? (
						<FocusOnMount delay={panelContentTransitionDuration}>
							{mobileInput}
						</FocusOnMount>
					) : mobileInput}

				</div>
			</div>
		);
	}

	handleCountryFieldChange = () => {
		this.props.numberField.clearServerError();
		this.props.numberField.revalidate();
	}
}

class PhoneMaskInput extends React.PureComponent<{ country: Country }> {
	private maskFn: ((currentValue: string) => TextMask[]);

	constructor(props: { country: Country }) {
		super(props);
		this.maskFn = this.createMaskFn(props);
	}

	UNSAFE_componentWillReceiveProps(nextPros: { country: Country }) {
		if (this.props.country !== nextPros.country) {
			this.maskFn = this.createMaskFn(nextPros);
		}
	}

	render() {
		return (
			<TextMaskInput mask={this.maskFn}>
				{this.props.children}
			</TextMaskInput>
		);
	}

	private createMaskFn = (props: this['props']) => {
		const { country } = props;
		return (value: string) => PhoneNumberUtils.phoneNumberTextMask(value, country);
	}
}
