import * as React from 'react';
import { Button, ButtonStyle, IButtonProps } from '../../primitives/button/button';
import { activateSubmit, deactivateSubmit } from '../../utils/submit-activator';

export class SubmitButton extends React.Component<IButtonProps, {}> {
	render() {
		return (
			<Button
				{...this.props}
				submit={true}
				className="button"
				buttonStyle={ButtonStyle.Primary}
				onMouseDown={activateSubmit}
				onMouseUp={deactivateSubmit}
				onMouseLeave={deactivateSubmit}
			/>
		);
	}
}
