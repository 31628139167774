import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { MainViewModel } from '../../main/main-view-model';
import { EnterMobileNumberRequest, Country } from '../../donorpledgeentry-generated';
import { getDonorPledgeEntryDataService } from '../../donorpledgeentry-data-service';
import { Actions, Events } from "../../state-machine/states-events-actions";
import { states, events, eventType } from '../../../Shared/state-machine/states-events-and-actions';

export function* postEnterMobileNumber(machineContext: MachineContext<MainViewModel>, mobileNumberRequest: EnterMobileNumberRequest) {
	const mainViewModel = machineContext.viewModel as MainViewModel;

	if (!mainViewModel.disableValidation) {
		const validationState = mainViewModel.enterMobileNumberViewModel.validate();
		if (!validationState.isValid) {
			mainViewModel.enterMobileNumberViewModel.validationState = validationState;
			EnterMobileNumberEvents.raise.ValidationError(machineContext);
			return;
		}
	}

	try {
		const response = yield getDonorPledgeEntryDataService().enterMobileNumber({ model: mobileNumberRequest as EnterMobileNumberRequest }, () => (mainViewModel));
		if (response.Success) {
			mainViewModel.enterSecurityCodeViewModel.setNormalizedInternationalNumber(response.MobileNumber.NormalizedInternationalNumber);
			mainViewModel.enterSecurityCodeViewModel.setRequestCode(response.RequestToken);
			Events.raise.SecurityCodeSent(machineContext);
		} else {
			mainViewModel.enterMobileNumberViewModel.setError(response.reason);
			EnterMobileNumberEvents.raise.RequestFailure(machineContext);
		}
	} catch {
		mainViewModel.enterMobileNumberViewModel.setError(`Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`);
		EnterMobileNumberEvents.raise.RequestFailure(machineContext);
	}
}

export function postEnterMobileNumberByVoice(machineContext: MachineContext<MainViewModel>) {
	const mainViewModel = machineContext.viewModel as MainViewModel;
	const { enterMobileNumberViewModel: vm } = mainViewModel;

	mainViewModel.setMobileNumber(vm.mobileNumber.value);

	const request = {
		MobileNumber: vm.mobileNumber.value,
		Country: Country[vm.mobileCountry.value],
		UseVoiceCall: true,
	} as EnterMobileNumberRequest | any;

	EnterMobileNumberEvents.raise.InitializeRequest(machineContext, request);
}

enum EnterMobileNumberStateValues {
	Idle,
	SendCode,
	SendCodeByVoice,
	Success,
	Failure,
	NeedHelp,
	HowDoesMobileSignInWork,
}

export const EnterMobileNumberStates = states(EnterMobileNumberStateValues);

export const EnterMobileNumberEvents = events({
	InitializeRequest: eventType<void>(),
	RequestCodeByVoice: eventType<void>(),
	ValidationError: eventType<void>(),
	RequestSuccess: eventType<void>(),
	RequestFailure: eventType<void>(),
	RequestedFailed: eventType<void>(),
	ShowNeedHelp: eventType<void>(),
	ShowHowDoesMobileSignInWork: eventType<void>(),
});

interface EnterMobileNumberActions {
	ProcessEnterMobileNumberRequest: string;
	ProcessEnterMobileNumberRequestByVoice: string;
}

const enterMobileNumberStateMachine = (actions: EnterMobileNumberActions) => {
	return {
		initial: EnterMobileNumberStates.Idle,
		states: {
			[EnterMobileNumberStates.Idle]: {
				on: {
					[EnterMobileNumberEvents.InitializeRequest]: EnterMobileNumberStates.SendCode,
					[EnterMobileNumberEvents.ShowNeedHelp]: EnterMobileNumberStates.NeedHelp,
					[EnterMobileNumberEvents.ShowHowDoesMobileSignInWork]: EnterMobileNumberStates.HowDoesMobileSignInWork,
				},
			},
			[EnterMobileNumberStates.SendCode]: {
				onEntry: actions.ProcessEnterMobileNumberRequest,
				on: {
					[EnterMobileNumberEvents.RequestSuccess]: EnterMobileNumberStates.Success,
					[EnterMobileNumberEvents.ValidationError]: EnterMobileNumberStates.Idle,
					[EnterMobileNumberEvents.RequestFailure]: EnterMobileNumberStates.Failure,
				},
			},
			[EnterMobileNumberStates.Success]: {},
			[EnterMobileNumberStates.Failure]: {
				on: {
					[EnterMobileNumberEvents.InitializeRequest]: EnterMobileNumberStates.SendCode,
				},
			},
			[EnterMobileNumberStates.NeedHelp] : {
				on : {
					[Events.CloseModal]: EnterMobileNumberStates.Idle,
					[EnterMobileNumberEvents.RequestCodeByVoice]: EnterMobileNumberStates.SendCodeByVoice,
				}
			},
			[EnterMobileNumberStates.SendCodeByVoice]: {
				onEntry: actions.ProcessEnterMobileNumberRequestByVoice,
				on: {
					[EnterMobileNumberEvents.InitializeRequest]: EnterMobileNumberStates.SendCode,
				},
			},
			[EnterMobileNumberStates.HowDoesMobileSignInWork] : {
				on : {
					[Events.CloseModal]: EnterMobileNumberStates.Idle,
				}
			},
		},
	};
};

export const enterMobileNumberMachineConfig =
	() => enterMobileNumberStateMachine(Actions);
