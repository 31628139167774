import moment from 'moment';
import { FrequencyCode } from '../donorpledgeentry-generated';
import { OptionalDate } from '../../WebGiving/components/date-picker/date-picker';

export const dateInputFormat = 'YYYY-MM-DD';
export const dateFormat = 'DD MMMM YYYY';

export type RecurringOption = {
	code: FrequencyCode;
	label: string;
	amount: number;
	occurrences: number;
	starts: string;
	ends: string;
};

export type OptionalRecurringOption = RecurringOption | undefined;

export enum PledgeType {
	OneTime,
	WithRecurring,
}

export function isTesterFrequency(frequency: FrequencyCode) {
	return [FrequencyCode.FourHours, FrequencyCode.FiveMinutes, FrequencyCode.OneMinute].includes(frequency);
}

function insertIf<T>(condition: boolean, ...elements: T[]) {
	return condition ? elements : [];
}

type GetRecurringBaseRepeatsArgs = {
	start: Date;
	end: Date;
	hasQuarterlyRecurringGivingEnabled: boolean
	hasSemiYearlyRecurringGivingEnabled: boolean
	hasYearlyRecurringGivingEnabled: boolean
};

export function getRecurringBaseRepeats({
	start,
	end,
	hasQuarterlyRecurringGivingEnabled,
	hasSemiYearlyRecurringGivingEnabled,
	hasYearlyRecurringGivingEnabled
}: GetRecurringBaseRepeatsArgs): {
	frequencyCode: FrequencyCode;
	label: string;
	repeats: number;
	ending?: string;
	starting?: string;
}[] {
	const startDate = moment(start.toUTCString());
	const endDate = moment(end.toUTCString());

	return [
		{
			frequencyCode: FrequencyCode.Weekly,
			label: 'Every week',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'weeks')
		},
		{
			frequencyCode: FrequencyCode.Fortnightly,
			label: 'Every 2 weeks',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'weeks', 2)
		},
		{
			frequencyCode: FrequencyCode.Monthly,
			label: 'Every month',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'months')
		},
		getFirstAndFifteenthOption(startDate, endDate),
		...insertIf(hasQuarterlyRecurringGivingEnabled, {
			frequencyCode: FrequencyCode.Quarterly,
			label: 'Every 3 months',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'months', 3)
		}),
		...insertIf(hasSemiYearlyRecurringGivingEnabled, {
			frequencyCode: FrequencyCode.SemiYearly,
			label: 'Every 6 months',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'months', 6)
		}),
		...insertIf(hasYearlyRecurringGivingEnabled, {
			frequencyCode: FrequencyCode.Yearly,
			label: 'Every year',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'years')
		}),
		{
			frequencyCode: FrequencyCode.OneMinute,
			label: 'Every minute',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'minutes')
		},
		{
			frequencyCode: FrequencyCode.FiveMinutes,
			label: 'Every 5 minutes',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'minutes', 5)
		},
		{
			frequencyCode: FrequencyCode.FourHours,
			label: 'Every 4 hours',
			repeats: calculateRepeatsInclusive(startDate, endDate, 'hours', 4)
		},
	];
}

function calculateRepeatsInclusive(startDate: moment.Moment, endDate: moment.Moment, frequency: string, divisor = 1) {
	return Math.floor(endDate.diff(startDate, frequency) / divisor) + 1;
}

export const getFirstAndFifteenthOption = (startDate: moment.Moment, endDate: moment.Moment) => {
	const now = startDate.clone();
	const dates = [];

	while (now.isSameOrBefore(endDate)) {
		if ([1, 15].includes(now.toDate().getDate())) {
			dates.push(now.format(dateInputFormat));
		}
		now.add(1, 'days');
	}

	return {
		frequencyCode: FrequencyCode.FirstAndFifteenth,
		label: '1st & 15th monthly',
		starting: moment(dates[0]).format(dateInputFormat),
		repeats: dates.length
	}
}

export type getRecurringOptionsArgs = {
	amount: number;
	paymentMax: number;
	paymentMin: number;
	startDate: Date;
	endDate: Date;
	options: {
		frequencyCode: FrequencyCode;
		label: string;
		repeats: number;
		ending?: string;
		starting?: string;
	}[];
};

export const getRecurringOptions = ({
	amount,
	paymentMax,
	paymentMin,
	startDate,
	endDate,
	options,
}: getRecurringOptionsArgs) => {
	const freqs: RecurringOption[] = [];

	options.forEach((baseRepeats) => {
		const { frequencyCode, repeats, label } = baseRepeats;

		if (!isTesterFrequency(frequencyCode)) {
			const possibleFrequency = getRecurringAmountAndRepeats(amount, repeats, paymentMax, paymentMin);

			if (possibleFrequency) {
				const ends = getRecurringEndDate(frequencyCode, baseRepeats.starting || startDate.toUTCString(), possibleFrequency.repeats)

				if (ends) {
					freqs.push({
						amount: possibleFrequency.amount,
						code: frequencyCode,
						label,
						occurrences: possibleFrequency.repeats,
						starts: baseRepeats.starting || moment(startDate).format(dateInputFormat),
						ends: moment(ends).format(dateInputFormat),
					});
				}
			}
		} else if (NewFeatures.TesterCommandsEnabled) {
			// DIsabled tester frequencies to test UX
			// PP-47450-Remove-tester-frequencies-to-validate-UX
			return;
			const testAmount = amount / repeats;
			freqs.push({
				code: frequencyCode,
				label,
				amount: testAmount,
				occurrences: repeats,
				starts: startDate.toUTCString(),
				ends: endDate.toUTCString(),
			});
		}
	});

	return freqs;
};

export const getRecurringAmount = (total: number, repeats: number) =>
	Math.ceil((total / repeats) * 100) / 100;

export const getRecurringEndDate = (frequency: FrequencyCode, startDate: string, repeats: number) => {
	//required to avoid calculating the end date 1 schedule over as first donation will be made on start date 
	repeats -= 1;

	switch (frequency) {
		case FrequencyCode.OneMinute:
			return moment(startDate).add(repeats, 'minutes')
		case FrequencyCode.FiveMinutes:
			return moment(startDate).add(repeats * 5, 'minutes')
		case FrequencyCode.FourHours:
			return moment(startDate).add(repeats * 4, 'hours')
		case FrequencyCode.Weekly:
			return moment(startDate).add(repeats, 'weeks')
		case FrequencyCode.Fortnightly:
			return moment(startDate).add(repeats * 2, 'weeks')
		case FrequencyCode.FirstAndFifteenth:
			var proposedEndDate = moment(startDate).clone();
			for(var i = 0; i < repeats; i++)
			{
				if(proposedEndDate.date() == 15)
				{
					proposedEndDate.add(1, 'months').date(1);
				}
				else //date is 1st of month
				{
					proposedEndDate.date(15)
				}
			}	
			return moment(proposedEndDate)
		case FrequencyCode.Monthly:
			return moment(startDate).add(repeats, 'months')
		case FrequencyCode.Yearly:
			return moment(startDate).add(repeats, 'years')
		case FrequencyCode.SemiYearly:
			return moment(startDate).add(repeats * 6, 'months')
		case FrequencyCode.Quarterly:
			return moment(startDate).add(repeats * 3, 'months')
		default:
			break;
	}
};

export const getRecurringAmountAndRepeats = (
	total: number,
	repeats: number,
	paymentMax: number,
	paymentMin: number
) => {
	for (var i = repeats; i >= 0; i--) {
		const amount = getRecurringAmount(total, i);
		const belowMax = paymentMax ? amount <= paymentMax : true;
		const aboveMin = paymentMin ? amount >= paymentMin : true;
		const reachesTotalPledge = amount * i >= total;
		if (i > 1 && reachesTotalPledge && belowMax && aboveMin) {
			return {
				repeats: i,
				amount: amount,
			};
		}
	}
};

export const getValidDate = (date: OptionalDate): Date | undefined => {
	if (date instanceof Date) {
		return date;
	} else {
		return undefined;
	}
};
