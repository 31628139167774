import { MachineContext } from './saga-state-machine';

export function eventType<TData>(): TData {
	return null as any; //using this for typings
}

export function actionType<TData>(fn: (machineContext: MachineContext, data?: TData) => void | Iterator<any>): TData {
	return fn as any;
}

export function events<TEvents extends object>(events: TEvents) {
	return Object.keys(events).reduce((acc: any, eventKey) => {
		acc[eventKey] = eventKey;
		acc.raise[eventKey] = (machineContext: MachineContext, data?: any) => {
			machineContext.transition(eventKey, data);
		};
		return acc;
	}, { raise: {} }) as Eventz<TEvents>;
}

export type Eventz<TEventz> = { [x in keyof TEventz]: x }
	& { raise: { [x in keyof TEventz]: (machineContext: MachineContext, data?: TEventz[x]) => void } };

export function actions<TActions extends Record<string, any>>(actions: TActions) {
	return Object.keys(actions).reduce((acc: any, eventKey) => {
		acc[eventKey] = eventKey;
		acc.dataTypes[eventKey] = null; //using this for typings
		acc.handlers[eventKey] = actions[eventKey];
		return acc;
	}, { dataTypes: {}, handlers: {} }) as Actionz<TActions>;
}

export type Actionz<TActions> = { [x in keyof TActions]: x }
	& { dataTypes: { [x in keyof TActions]: TActions[x] } }
	& { handlers: { [x in keyof TActions]: (context?: MachineContext, data?: any) => Iterator<any> | void } };

export function states<TStates extends Record<string, any>>(states: TStates) {
	const stringKeys = Object.keys(states).filter(key => isNaN(+key)) as any;
	return stringKeys.reduce((acc: any, key: string) => {
		const state = states[states[key]];
		acc[state] = state;
		acc.internal[state] = `.${state}`;
		acc.id[state] = `#${state}`;
		return acc;
	}, { internal: {}, id: {} }) as Statez<TStates>;
}

export type Statez<TStates extends Record<string, any>> = { [x in Exclude<keyof TStates, number>]: string }
	& { internal: { [x in Exclude<keyof TStates, number>]: string } }
	& { id: { [x in Exclude<keyof TStates, number>]: string } };


/** Generates a fully pathed set of state names for the states of state machine
 *  contained within a state machine node.
 * 
 * 	@param parent The name of the parent state machine node.
 * 
 *  @param states The enum of the states of the contained / child state machine.
 * 
 *  See `DonorPledgeEntry/state-machine/states-event-actions.ts` for an example of use.
*/
export function subStates<TStates extends Record<string, any>>(parent:string, states: TStates) {
	const stringKeys = Object.keys(states).filter(key => isNaN(+key)) as any;
	return stringKeys.reduce((acc: any, key: string) => {
		const state = states[states[key]];
		const fullState = parent + "." + state;
		acc[state] = fullState;
		acc.id[state] = `#${fullState}`;
		return acc;
	}, { internal: {}, id: {} }) as SubStatez<TStates>;
}

export type SubStatez<TStates extends Record<string, any>> = { [x in Exclude<keyof TStates, number>]: string }
	& { id: { [x in Exclude<keyof TStates, number>]: string } };
