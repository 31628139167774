import * as React from 'react';
import { observer } from 'mobx-react';
import Transition from 'react-transition-group/Transition';

import { transitionDurationLongMs } from '../main/webgiving-main.less';

export const fadeTransitionDuration: number = parseInt(transitionDurationLongMs, 10);

@observer
export class FadeTransition extends React.Component<{
	in: boolean,
	exit?: boolean,
	appear?: boolean,
	duration?: number,
	onExit?: () => void,
}, {}> {
	render() {
		const {
			children,
			in: inProp,
			exit = true,
			appear = true,
			duration = fadeTransitionDuration,
			onExit,
		} = this.props;
		const defaultStyle = {
			transition: `opacity ${duration}ms ease-in-out`,
			opacity: '0',
		};
		const transitionStyles: { [key: string]: {} } = {
			// Start with component invisible
			entering: {
				opacity: '0',
			},
			entered: {
				opacity: '1',
			},
			exiting: {
				opacity: '0',
			},
			exited: {
				opacity: '0',
			},
		};
		const timeout = {
			enter: 0,
			exit: duration,
		};

		return (
			<Transition in={inProp} exit={exit} appear={appear} timeout={timeout} onExit={onExit} unmountOnExit>
				{(status: string) => React.cloneElement(children as React.ReactElement<any>, {
					style: Object.assign({}, defaultStyle, transitionStyles[status]) as Partial<CSSStyleDeclaration>,
					'data-pp-transition-state': status,
				})}
			</Transition>
		);
	}
}
