import * as React from 'react';
import { observer } from 'mobx-react';

import { CreateUserViewModel } from './create-user-view-model';
import { IPageBaseProps } from '../shared/page-props-base';
import { getPageClasses } from '../shared/get-page-classes';
import { ErrorMessage } from '../../components/error-message/error-message';
import { pageFooter } from '../../main/main.less';

import { Form } from '../../../WebGiving/primitives/form';
import { PanelHeading } from '../../../WebGiving/components/panel-heading';
import { FadeTransition } from '../../../WebGiving/components/fade-transition';
import { PanelContentTransition, panelContentTransitionDuration } from '../../../WebGiving/components/panel-content-transition';
import { FormControl } from '../../../WebGiving/components/form-control/form-control';
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';
import { TextboxField } from '../../../WebGiving/components/form-fields';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { SubmitButton } from '../../../WebGiving/components/submit-button/submit-button';


@observer
export class CreateUser extends React.Component<IPageBaseProps<CreateUserViewModel>> {
	panelBodyNode: HTMLDivElement;

	render() {
		const { vm, transitionState } = this.props;
		const { 
			firstName, 
			lastName, 
			emailAddress, 
			error, 
			isBlocked, 
			pageShouldBeVisible, 
			isLoading 
		} = vm;
		const elementsShouldEnter = transitionState === 'entered';

		return (
			<div className={getPageClasses(pageShouldBeVisible, isLoading)} data-pp-at-target={pageShouldBeVisible ? `Create Account page` : null}>
				<Form onSubmit={this.handleSubmit}>
					<PanelHeading vm={vm}>
						<FadeTransition in={elementsShouldEnter}>
							<div>
								<h1 className="panel-title">PERSONAL DETAILS</h1>
								<p className="panel-subtitle">Enter your name and email to create an account.</p>
							</div>
						</FadeTransition>
					</PanelHeading>
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<PanelContentTransition in={elementsShouldEnter} setContentHeight={this.setContentHeight} className="panel-body-content">
							<div>
								<ErrorMessage error={error} />
								<div className="row">
									<FormControl label="First name" validationError={vm.firstName.validationError} className="col-sm-6">
										<FocusOnMount delay={panelContentTransitionDuration}>
											<TextboxField formField={firstName} acceptanceTestTargetId="First Name" />
										</FocusOnMount>
									</FormControl>
									<FormControl label="Last name" validationError={vm.lastName.validationError} className="col-sm-6">
										<TextboxField formField={lastName} acceptanceTestTargetId="Last Name" />
									</FormControl>
								</div>
								<FormControl label="Email address" validationError={vm.emailAddress.validationError}>
									<TextboxField formField={emailAddress} acceptanceTestTargetId="Email Address" type="email" />
								</FormControl>
							</div>
						</PanelContentTransition>
					</div>
					<PanelFooter className={pageFooter}>
						<SubmitButton acceptanceTestTargetId="Next" isLoading={vm.isLoading} isBlocked={isBlocked}>Next</SubmitButton>
					</PanelFooter>
				</Form>
			</div>
		);
	}

	setContentHeight = (offsetHeight: number) => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${offsetHeight}px`;
		}
	}

	private savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;


	private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.props.vm.handleSubmit();
	}
}
