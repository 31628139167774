import { WebGivingMerchant } from '../webgiving-generated';
import { Formatter } from '../../Shared/utils/formatter';

export const calculateFee = (base: number, rate: number): number => {
	const baseCents = base * 100;
	const rateThousands = rate * 1000;
	const feeThousandCents = baseCents * rateThousands;
	const totalCents = baseCents + feeThousandCents / 1000;
	return totalCents / 100;
};

export const getPayerCoverFeesLegalText = () =>
	'*This rate represents an approximation of the applicable processing rates; actual processing costs may be higher or lower';

export const amountToParts = (amount: number, precision: number) => {
	const displayValue = Formatter.formatNumberForDisplay(amount, precision);
	const bits = displayValue.split('.');
	return {
		major: bits[0],
		minor: bits[1],
	};
};

export const isTotalOverMaxLimit= (amount: number, maxPaymentAmount: number): boolean => amount > maxPaymentAmount;

export const getMinMaxPaymentAmounts = (merchantInfo: WebGivingMerchant) => {
	const minPaymentAmount = merchantInfo.PaymentMin ? merchantInfo.PaymentMin : 1;
	let maxPaymentAmount: number;
	maxPaymentAmount = merchantInfo.AchMax 
		? Math.max(merchantInfo.AchMax, merchantInfo.PaymentMax) 
		: merchantInfo.PaymentMax;

	return { minPaymentAmount, maxPaymentAmount };
} 