import * as React from 'react';

import { PledgeExistsViewModel } from './pledge-exists-view-model';
import * as styles from "./pledge-exists.less";
import { IPageBaseProps } from '../shared/page-props-base';

import { SubmitButton } from "../../../WebGiving/components/submit-button/submit-button";
import { Form } from "../../../WebGiving/primitives/form";
import { PanelHeading } from "../../../WebGiving/components/panel-heading";
import { FadeTransition, fadeTransitionDuration } from "../../../WebGiving/components/fade-transition";
import { FocusOnMount } from '../../../WebGiving/components/focus-on-mount-hoc';
import { SvgIcon } from "../../../WebGiving/components/svg-icon";
import iconGift from '../../../WebGiving/assets/svg/gift-box.svg';
import { PanelFooter } from '../../../WebGiving/components/panel-footer';
import { RecurringCalculatorSummary } from '../../components/recurring-calculator-summary/recurring-calculator-summary';
import { PanelContentTransition } from '../../../WebGiving/components/panel-content-transition';

export class PledgeExists extends React.Component<IPageBaseProps<PledgeExistsViewModel>> {
	panelBodyNode: HTMLDivElement;

	render() {
		const { vm, transitionState } = this.props;
		const {
			campaignName,
			handleSubmit,
			pledgeLabel,
			recurringPaymentExists,
			recurringInfo,
			pledgeProgressUrl,
		} = vm;

		const elementsShouldEnter = transitionState === 'entered';
		const showLink = recurringInfo.redirectUrl || (!recurringInfo.redirectUrl && !recurringPaymentExists);

		return (
			<Form onSubmit={handleSubmit}>
				<PanelHeading vm={this.props.vm}>
					<FadeTransition in={elementsShouldEnter}>
						<div className={styles.headingWrapper}>
							<div className={styles.pledgeIcon}>
								<SvgIcon svg={iconGift} />
							</div>
							<h2>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounSentenceCase : "Pledge"} already received</h2>
							<p className="lead">
								Thanks, we have already received your {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : "pledge"} to <strong>{campaignName}</strong>.
								You can view your {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : "pledge"} progress in your account.
							</p>
						</div>
					</FadeTransition>
				</PanelHeading>
				{recurringInfo.redirectUrl && recurringInfo.option && (
					<div className="panel-body panel-body-form" ref={this.savePanelBodyNode}>
						<PanelContentTransition
							in={elementsShouldEnter}
							setContentHeight={this.setContentHeight}
						>
							{<RecurringCalculatorSummary
								containerStyle={styles.recurringSummary}
								recurringAmount={recurringInfo.option.amount}
								startDate={recurringInfo.option.starts}
								endDate={recurringInfo.option.ends}
								totalAmount={recurringInfo.option.amount * recurringInfo.option.occurrences}
								totalGifts={recurringInfo.option.occurrences}
								frequencyLabel={recurringInfo.option.label}
								showAmountRoundedWarning={
									recurringInfo.option.amount !==
									recurringInfo.option.amount * recurringInfo.option.occurrences
								}
							/>}
						</PanelContentTransition>
					</div>
				)}
				<PanelFooter>
					<>
						<FocusOnMount delay={fadeTransitionDuration}>{this.GiveScheduleOrView()}</FocusOnMount>
						{showLink && (
							<div className={styles.secondaryCta}>
								<a className="brand-link" href={pledgeProgressUrl}>
									View my {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar
										? pledgeLabel.NounLowerCase
										: 'pledge'} progress
								</a>
							</div>
						)}
					</>
				</PanelFooter>
			</Form>
		)
	}

	GiveScheduleOrView = () => {
		const { paymentLabel, recurringInfo, recurringPaymentExists, isLoading, pledgeLabel } = this.props.vm;

		if (recurringInfo.redirectUrl) {
			return (
				<SubmitButton
					acceptanceTestTargetId="PledgeExistsScheduleCtaButton"
					isLoading={isLoading}
					isBlocked={false}
				>
					Create a schedule for your {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}
				</SubmitButton>
			);
		}

		if (!recurringPaymentExists) {
			return (
				<SubmitButton
					acceptanceTestTargetId="PledgeExistsGiveCtaButton"
					isLoading={isLoading}
					isBlocked={false}
				>
					{paymentLabel.VerbSentenceCase} to Campaign
				</SubmitButton>
			);
		}

		return (
			<SubmitButton acceptanceTestTargetId="PledgeExistsViewCtaButton" isLoading={isLoading} isBlocked={false}>
				View my {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'} progress
			</SubmitButton>
		);
	};

	setContentHeight = (offsetHeight: number) => {
		if (this.panelBodyNode) {
			this.panelBodyNode.style.minHeight = `${offsetHeight}px`;
		}
	}

	private savePanelBodyNode = (panelBodyNode: HTMLDivElement) => this.panelBodyNode = panelBodyNode;
}
