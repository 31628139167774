import {
	events,
	eventType,
	states,
} from "../../../Shared/state-machine/states-events-and-actions";
import {
	Actions,
	Events,
} from "../../state-machine/states-events-actions";
import { MachineContext } from "../../../Shared/state-machine/saga-state-machine";
import { MainViewModel } from "../../main/main-view-model";

// states
enum EnterPledgeStateValues {
	Idle,
	ProcessingPledge,
}
export const EnterPledgeStates = states(EnterPledgeStateValues);

// events
const eventItems = {
	PledgeSubmitted: eventType<typeof Actions.dataTypes.ProcessEnterPledge>(),
	ValidationError: eventType<void>(),
};
export const EnterPledgeEvents = events(eventItems);

// actions
export const EnterPledgeActions = {
	ProcessEnterPledge: processEnterPledge,
};

// config
export const enterPledgeMachineConfig = () => {
	return {
		initial: EnterPledgeStates.Idle,
		states: {
			[EnterPledgeStates.Idle]: {
				on: {
					[EnterPledgeEvents.PledgeSubmitted]: EnterPledgeStates.ProcessingPledge,
				},
			},
			[EnterPledgeStates.ProcessingPledge]: {
				onEntry: Actions.ProcessEnterPledge,
				on: {
					[EnterPledgeEvents.ValidationError]: EnterPledgeStates.Idle,
				},
			},
		},
	};
};

function* processEnterPledge(machineContext: MachineContext<MainViewModel>): IterableIterator<void> {
	const vm = machineContext.viewModel as MainViewModel;
	const { person, enterPledgeViewModel, disableValidation, setAmount, setRecurringOption, confirmedMobileNumber } = vm;

	if (!disableValidation) {
		const validationState = enterPledgeViewModel.validate();
		if (!validationState.isValid) {
			enterPledgeViewModel.validationState = validationState;
			EnterPledgeEvents.raise.ValidationError(machineContext);
			return;
		}
	}

	setAmount(enterPledgeViewModel.amount.value);

	setRecurringOption(enterPledgeViewModel.recurringOption.value);			

	if (person) {
		Events.raise.UserAuthenticated(machineContext);
	} else {
		if (NewFeatures.EnhancedDonorAuth_IdentityVerification_DPE && confirmedMobileNumber != null) {
			Events.raise.UserUnknownHasConfirmedMobileNumber(machineContext);
		} else {
			Events.raise.UserUnknown(machineContext);
		}
	}
}
