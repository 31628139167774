import React from 'react';
import { observer } from 'mobx-react';
import * as styles from './recurring-calculator-summary.less';
import { dateFormat } from '../../utils/recurring-utils';
import { Formatter } from '../../../Shared/utils/formatter';
import { classNames } from '../../../Shared/utils/classnames';

export interface IRecurringCalculatorSummaryProps {
	recurringAmount: number;
	startDate: string;
	endDate: string;
	totalAmount: number;
	totalGifts: number;
	frequencyLabel: string;
	showAmountRoundedWarning: boolean;
	containerStyle?: string;
}

@observer
export class RecurringCalculatorSummary extends React.Component<IRecurringCalculatorSummaryProps> {
	render() {
		const { containerStyle, recurringAmount, startDate, endDate, totalAmount, totalGifts, frequencyLabel, showAmountRoundedWarning } = this.props;
		return (
			<div className={classNames(styles.container, containerStyle) }>
				<div className={styles.title}>
					Giving Summary
				</div>
				<div className={styles.rows}>
					<div className={styles.row}>
						<strong>Contribution ({frequencyLabel}):</strong>
						<strong>
							${Formatter.formatNumberForDisplay(recurringAmount)}{showAmountRoundedWarning && '*'}
						</strong>
					</div>
					<div className={styles.row}>
						<span>Starting on:</span>
						<span>{moment(startDate).format(dateFormat)}{moment(startDate).isSame(moment(), 'day') ? ' (Today)' : ''}</span>
					</div>
					<div className={styles.row}>
						<span>Ending on:</span>
						<span>{moment(endDate).format(dateFormat)}</span>
					</div>
					<div className={styles.row}>
						<span>Total Contribution:</span>
						<span>${Formatter.formatNumberForDisplay(totalAmount)}{showAmountRoundedWarning && '*'}</span>
					</div>
					<div className={styles.row}>
						<span>Total Number of Gifts:</span>
						<span>{totalGifts} gifts</span>
					</div>

					{showAmountRoundedWarning && <div className={styles.row}>
						<span className={styles.info}>* Amount has been rounded up to the nearest cent</span>
					</div>}
				</div>
			</div>
		);
	}
}
