import { action, observable } from 'mobx';

import { EnterMobileNumberEvents } from './enter-mobile-number-machine-config';
import { PageViewModelBase } from '../shared/page-view-model-base';

import {
	DonorPledgeEntryError,
	DonorPledgeEntryErrorType,
} from '../../components/error-message/error-message';
import { Country, EnterMobileNumberRequest, ModelMetadata } from '../../donorpledgeentry-generated';
import { MainViewModel } from '../../main/main-view-model';
import { PhoneNumberUtils } from '../../utils/phone-number-utils';

import { validateFields, ValidationState } from '../../../WebGiving/validation/view-model-validator';
import { FormField } from '../../../WebGiving/components/form-field';
import {
	combineValidators,
	createRulesFromMetadata,
	createValidatorFunc,
	FieldValidationFailure,
	fieldValidationSuccess,
	RuleType,
} from '../../../WebGiving/validation/validation-rules';

export class EnterMobileNumberViewModel extends PageViewModelBase {
	mobileNumber: FormField<string>;
	mobileCountry: FormField<Country>;

	@observable
	validationState?: ValidationState;

	@observable
	error: DonorPledgeEntryError;

	constructor(
		private vm: MainViewModel,
		defaultMobileNumber: string,
		defaultMobileCountry: Country,
	) {
		super();
		const mobileNumber = defaultMobileNumber || PhoneNumberUtils.format(defaultMobileNumber, defaultMobileCountry);

		this.mobileNumber = new FormField<string>(mobileNumber,
			combineValidators(createRulesFromMetadata(ModelMetadata.EnterMobileNumberRequest.MobileNumber), this.createMobilePhoneNumberValidator()));
		this.mobileNumber.updateSubject.register(this.synchronisePhoneCountryField);

		const country = PhoneNumberUtils.getRegionForNumber(mobileNumber, defaultMobileCountry);
		this.mobileCountry = FormField.Unvalidated(typeof country === 'number' ? country : defaultMobileCountry);

		vm.history.push(false);
	}

	createMobilePhoneNumberValidator = () => createValidatorFunc((value: string) => {
		if (PhoneNumberUtils.isValidNumberForRegion(value, this.mobileCountry.value)) {
			return fieldValidationSuccess;
		}

		const errorMessage = ModelMetadata.EnterMobileNumberRequest.MobileNumber.validationRules
			? ModelMetadata.EnterMobileNumberRequest.MobileNumber.validationRules.length.errorMessage
			: 'That is not a recognized phone number';

		return new FieldValidationFailure(RuleType.Custom, errorMessage);
	}, RuleType.Custom)

	synchronisePhoneCountryField = (field: FormField<string>) => {
		const value = field.value;
		const newRegion = PhoneNumberUtils.getRegionForNumber(value, this.mobileCountry.value);

		if (typeof newRegion === 'number') {
			this.mobileCountry.updateValue(newRegion);
		}
	}

	@action
	showHelp = () => {
		EnterMobileNumberEvents.raise.ShowNeedHelp(this.vm.machineContext);
	}

	@action
	showMobileSigninInfo = () => {
		EnterMobileNumberEvents.raise.ShowHowDoesMobileSignInWork(this.vm.machineContext);
	}

	@action
	handleSubmit = () => {
		this.vm.setMobileNumber(this.mobileNumber.value);

		const request = {
			MobileNumber: this.mobileNumber.value,
			Country: Country[this.mobileCountry.value]
		} as EnterMobileNumberRequest | any;

		EnterMobileNumberEvents.raise.InitializeRequest(this.vm.machineContext, request);
	}

	validate(): ValidationState {
		return validateFields(this);
	}

	@action
	setMobileNumber = (mobileNumber: string) => {
		this.mobileNumber.updateValue(mobileNumber);
	}

	@action
	setError = (errorMessage: string) => {
		this.error = {
			errorType: DonorPledgeEntryErrorType.Generic,
			message: errorMessage,
		};
	}
}
