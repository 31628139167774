import * as mobx from 'mobx';

import { post } from '../Shared/utils/ajax-client';
import { AjaxUtils } from '../Shared/utils/ajax-utils';
import { ICancellablePromise } from '../Shared/utils/cancellable-promise';
import { DonorPledgeEntryApiConfig } from './donorpledgeentry-generated';
import { MainViewModel } from './main/main-view-model';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type DonorPledgeEntryActionRequest<TActionKey extends keyof DonorPledgeEntryApiConfigActions> = DonorPledgeEntryApiConfigActions[TActionKey]['request'];
export type DonorPledgeEntryActionResponse<TActionKey extends keyof DonorPledgeEntryApiConfigActions> = DonorPledgeEntryApiConfigActions[TActionKey]['response'];

export type DonorPledgeEntryApiConfigActions = typeof DonorPledgeEntryApiConfig['actions'];

export type DonorPledgeEntryDataService = {
	[x in keyof DonorPledgeEntryApiConfigActions]:
		(request: DonorPledgeEntryActionRequest<x>, getAppState: () => MainViewModel) => ICancellablePromise<DonorPledgeEntryActionResponse<x>>;
};

const options = {
	timeout: 30000,
	newCSRFToken: '',
};

let instance: DonorPledgeEntryDataService | null = null;

function createActionHandler<TKey extends keyof DonorPledgeEntryApiConfigActions>(actionKey: TKey) {
	const actionConfig = DonorPledgeEntryApiConfig.actions[actionKey] as any;

	return (request: any, getAppState: () => MainViewModel, queryStringParameters?: { [key: string]: string }) => {
		request = mobx.isObservable(request) ? mobx.toJS(request) : { ...request };

		const appState = getAppState();
		const customHeaders = appState.person && !appState.rememberMe ? [{ header: 'X-PP-WebGiving-Identity', value: appState.person.SignedPersonId }] : [];
		customHeaders.push({
			header: 'X-PP-DonorPledgeEntry-CorrelationId',
			value: appState.correlationId,
		});

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(DonorPledgeEntryApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken,
			customHeaders: customHeaders,
			queryStringParameters: queryStringParameters,
		}) as any;
	};
}

function createDonorPledgeEntryDataService(): DonorPledgeEntryDataService {
	return (Object.keys(DonorPledgeEntryApiConfig.actions) as Array<keyof DonorPledgeEntryApiConfigActions>)
		.reduce<DonorPledgeEntryDataService>((acc: DonorPledgeEntryDataService, actionKey: keyof DonorPledgeEntryApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as DonorPledgeEntryDataService);
}

export function getDonorPledgeEntryDataService() {
	if (instance === null) {
		instance = createDonorPledgeEntryDataService();
	}

	return instance;
}

export function setNewCRSFoken(token: string) {
	options.newCSRFToken = token;
}

export function mockDonorPledgeEntryDataService(mockDataService: DonorPledgeEntryDataService) {
	instance = mockDataService;
}
