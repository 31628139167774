
import * as React from 'react';
import { observer } from 'mobx-react';
import { TransitionGroup } from 'react-transition-group';

import { HelpModalContent, HelpModalHeader } from './help-modal/help-modal';
import { MobileSignInInfoModalContent, MobileSignInInfoModalHeader } from './mobile-sign-in-info-modal/mobile-sign-in-info-modal';

import { MainViewModel } from '../main/main-view-model';
import { States } from '../state-machine/states-events-actions';
import { ErrorInfo, getErrorInfo } from '../utils/error-utils';

import { Modal } from '../../WebGiving/components/modal/modal';
import { ModalType } from '../modals/modal-type';

@observer
export class ModalCommodore extends React.Component<{ vm: MainViewModel, onError: (errorInfo: ErrorInfo, customData?: any) => void }> {
	private documentActiveElement: Element | null;

	render() {
		const { vm, } = this.props;
		const { pushpayInfo, machineContext } = vm;
		const showResendSecurityCode = machineContext.matchesAnyState([States.EnterSecurityCode, States.ReenterSecurityCode]);
		let content: React.ReactElement<any>[] | null = null;

		switch (vm.visibleModal) {
			case ModalType.NeedHelp:
				content = [
					<HelpModalHeader />,
					<HelpModalContent
						machineContext={vm.machineContext}
						showResendSecurityCode={showResendSecurityCode}
						resentCodeState={vm.resentCodeState()}
						onSendVoiceCall={vm.sendVoiceCall}
						onResendVoiceCall={vm.resendVoiceCall}
						onResendTextMessage={vm.resendTextMessage}
						pushpayInfo={pushpayInfo} />,
				];
				break;

			case ModalType.MobileSignInInfo:
				content = [
					<MobileSignInInfoModalHeader />,
					<MobileSignInInfoModalContent />,
				];
				break;

			case null:
				break;

			default:
				const unsupported: never = vm.visibleModal;
				throw new Error(`ModalType ${unsupported} is unsupported`);
		}

		if (content) {
			// ensure keys are assigned
			content = content.map((x, i) => React.cloneElement(x, { key: i }));
		}

		return (
			<TransitionGroup>
				{content && <Modal key={typeof vm.visibleModal !== 'number' ? '' : vm.visibleModal}
					onClose={this.handleClose}
					onEnter={this.setDocumentActiveElement}
					onExited={this.returnFocus}>
					{content}
				</Modal>}
			</TransitionGroup>
		);
	}

	componentDidMount() {
		this.setupGlobalEventHandlers();
	}

	componentDidUpdate() {
		this.setupGlobalEventHandlers();
	}

	componentDidCatch(error: any, info: React.ErrorInfo) {
		this.props.vm.closeModal();
		this.props.onError(getErrorInfo(error), info);
	}

	private setupGlobalEventHandlers() {
		const anyModalVisible = typeof this.props.vm.visibleModal === 'number';

		if (anyModalVisible) {
			document.body.style.overflow = 'hidden';
			document.documentElement.style.overflow = 'hidden';
			document.body.addEventListener('touchmove', this.preventTouchMove, false);
			document.body.addEventListener('keydown', this.keyDown);
		} else {
			document.body.style.overflow = '';
			document.documentElement.style.overflow = '';
			document.body.removeEventListener('touchmove', this.preventTouchMove, false);
			document.body.removeEventListener('keydown', this.keyDown);
		}
	}

	private keyDown = (evt: any) => {
		if ((evt.key === 'Escape' || evt.keyCode === 27)) {
			this.handleClose();
		}
	}

	private preventTouchMove(ev: TouchEvent) {
		ev.preventDefault();
	}

	private handleClose = () => {
		this.props.vm.closeModal();
	}

	private setDocumentActiveElement = () => {
		this.documentActiveElement = document.activeElement;
	}

	private returnFocus = () => {
		if (this.documentActiveElement && this.documentActiveElement instanceof HTMLElement) {
			this.documentActiveElement.focus();
		}
	}
}
