import * as React from 'react';
import {
	bannerEnter,
	bannerEnterActive,
	bannerExitActive,
	banner,
	transitionDurationLongMs,
	extendBannerMobile,
	extendBannerMobileEnterActive,
	bannerExt,
	bannerExtEnter,
	bannerExtEnterActive,
	bannerExtExitActive,
} from './webgiving-main-banner.less';
import CSSTransition from 'react-transition-group/CSSTransition';
import { isIE } from '../../../Shared/helpers/browser-support-helper';

export class WebGivingMainBanner extends React.Component<{ visible: boolean; className?: string, extended?: boolean}, {}> {
	render() {
		const { visible, className = '', extended} = this.props;

		const appearClassName = isIE() ? undefined : bannerEnter;
		const appearActiveClassName = isIE() ? undefined : bannerEnterActive;
		const classNames = {
			appear: appearClassName,
			appearActive: appearActiveClassName,
			enter: bannerEnter,
			enterActive: extended ? extendBannerMobileEnterActive : bannerEnterActive,
			exitActive: bannerExitActive,
		};

		return (
			<CSSTransition in={visible}
				timeout={parseInt(transitionDurationLongMs, 10)}
				classNames={classNames} appear unmountOnExit>
				<div className={`${className} ${banner} ${extended ? extendBannerMobile : ''} `} />
			</CSSTransition>);
	}
}

export class WebGivingMainBannerExtended extends React.Component<{ visible: boolean; className?: string}, {}> {
	render() {
		const { visible, className = ''} = this.props;

		const appearClassName = isIE() ? undefined : bannerExtEnter;
		const appearActiveClassName = isIE() ? undefined : bannerExtEnterActive;
		const classNames = {
			appear: appearClassName,
			appearActive: appearActiveClassName,
			enter: bannerExtEnter,
			enterActive: bannerExtEnterActive,
			exitActive: bannerExtExitActive,
		};

		return (
			<CSSTransition in={visible}
				timeout={parseInt(transitionDurationLongMs, 10)}
				classNames={classNames} appear unmountOnExit>
				<div className={`${className} ${bannerExt}`} />
			</CSSTransition>);
	}
}
