export enum KeyCodes {
	Unknown = -1,
	Backspace = 8,
	Tab = 9,
	Enter = 13,
	Escape = 27,
	Space = 32,
	End = 35,
	Home = 36,
	LeftArrow = 37,
	UpArrow = 38,
	RightArrow = 39,
	DownArrow = 40,
	Delete = 46,
}
