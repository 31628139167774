import * as React from 'react';
import { observer } from 'mobx-react';
import { getUniqueElementId } from '../../Shared/utils/form-control-utils';
import { FormField } from './form-field';
import { SvgIcon, SvgProperties } from './svg-icon';
import { classNames } from '../../Shared/utils/classnames';
import { Text } from '../components/text';

export interface IFormControlSplitButtonProps<T> {
	/**
	 * Current value of split button radio group
	 */
	field: FormField<T>;
	acceptanceTestTargetId: string;
	formControlId?: string;
	options: IFormControlSplitButtonOptions<T>[];
	/**
	 * The ARIA label text for the split button radio group.
	 * Required if ariaLabelledBy is not in use.
	 */
	ariaLabel?: string;
	/**
	 * The ARIA labelling element ID for the split button radio group.
	 * Required if ariaLabel is not in use.
	 */
	ariaLabelledBy?: string;
	uniformWidth?: boolean;
	dataFieldInvalid?: boolean;
	onChange?: (x: any) => void;
}

export interface IFormControlSplitButtonOptions<T> {
	value: T;
	label: string;
	icon?: SvgProperties;
}

@observer
export class FormControlSplitButton extends React.Component<IFormControlSplitButtonProps<any>, {}> {
	private fallbackElementId = getUniqueElementId();

	render() {
		const {
			field: { value },
			acceptanceTestTargetId,
			options,
			ariaLabel,
			ariaLabelledBy,
			formControlId = this.fallbackElementId,
			uniformWidth = false,
			dataFieldInvalid = false,
		} = this.props;

		const componentClasses = `form-control-split-button ${uniformWidth ? 'form-control-split-button-has-uniform-width' : ''}`;

		return (
			<span className={componentClasses}
				role="radiogroup"
				aria-label={ariaLabel}
				aria-labelledby={ariaLabelledBy}
				data-pp-at-target={acceptanceTestTargetId}
			>
				{options.map((option, index) => {
					const checked = value === option.value;
					const htmlFor = `${formControlId}_${index}`;

					let labelClasses = classNames(
						`form-control-split-button-label brand-primary-hover brand-primary-border-hover`,
						{
							'brand-primary-bg brand-primary-border is-active': checked,
							'form-control-invalid': dataFieldInvalid,
						},
					);

					const icon = option.icon;

					return ([
						<input type="radio" className="form-control-split-button-radio"
							key={`input_${index}`}
							value={index}
							checked={checked}
							id={htmlFor}
							name={formControlId}
							onChange={this.handleChange}
							data-pp-at-target={`${acceptanceTestTargetId}_${option.label}`}
						/>,
						<label
							className={labelClasses}
							key={`label_${index}`}
							htmlFor={htmlFor}
							data-pp-at-target={`${acceptanceTestTargetId}_label_${option.label}`}>
							{icon && <SvgIcon svg={icon} />}
							<span><Text>{option.label}</Text></span>
						</label>,
					]);
				})}
			</span>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange, field, options } = this.props;
		const selectedIndex = parseInt(event.currentTarget.value);
		field.updateValue(options[selectedIndex].value);
		field.revalidate();
		if (onChange) {
			onChange(options[selectedIndex].value);
		}
	}
}
