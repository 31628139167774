
import { Subject } from '../../Shared/utils/subject';
import { isFunction } from '../../Shared/utils/is-function';

const version = 'v1';

export interface IHistoryRecordState {
	// IMPORTANT changes to IHistoryRecordState can be breaking and need to be treated like a DB migration
	// Every record stored in the history is versioned
	// version should help with possible changes in the future
	firstPage: boolean;
	initialPage: boolean;
	hasNextPage?: WindowHistoryState;
}

type WindowHistoryState = IHistoryRecordState & {
	version: typeof version;
	[stateKey: string]: any;
};

function push(state: IHistoryRecordState) {
	window.history.pushState(state, '');
}

function replace(state: IHistoryRecordState) {
	window.history.replaceState(state, '');
}

function getState() {
	return window.history.state as WindowHistoryState | null;
}

function createKey() {
	const keyLength = 6;
	return Math.random().toString(36).substr(2, keyLength);
}

export interface IHistoryAdapter {
	start(blockNavigation?: () => boolean | undefined) : void;
	push(firstPage: boolean) : void;
	listen(callback: () => void) : void;
}

export class HistoryAdapter implements IHistoryAdapter {
	currentState: WindowHistoryState;

	private subject = new Subject<void>();
	private blockNavigation?: () => boolean | undefined;

	constructor(readonly stateKey: string) {
	}

	start(blockNavigation?: () => boolean | undefined) {
		this.blockNavigation = blockNavigation;
		const state = getState();

		this.updateCurrentState({
			firstPage: true,
			initialPage: true,
		});
		replace(this.currentState);

		if (state && state.hasNextPage) {
			this.currentState = state.hasNextPage;
			push(state.hasNextPage);
		}

		window.addEventListener('popstate', this.handlePopState);
	}

	destroy() {
		window.removeEventListener('popstate', this.handlePopState);
	}

	push(firstPage: boolean) {
		const isInitialPageCurrent = this.currentState.initialPage;
		this.updateCurrentState({
			initialPage: false,
			firstPage,
		});

		if (isInitialPageCurrent) {
			push(this.currentState);
		} else {
			replace(this.currentState);
		}
	}

	goBack() {
		window.history.back();
	}

	listen = (callback: () => void) => this.subject.register(callback);

	private updateCurrentState(state: IHistoryRecordState | WindowHistoryState) {
		this.currentState = {
			...state,
			version: 'v1',
			[this.stateKey]: createKey(),
		};
	}

	private handlePopState = () => {
		const currentState = this.currentState;
		const newState = getState();

		if (!newState || !newState[this.stateKey]) {
			// this is probably the case for navigating forward via a[href] #hash
			// we shouldn't use a[href=#] without preventDefault() intentionally
			// however, we should not stop end users from making payments if we ship a change with a[href=#] accidentally
			// ignoring
			return;
		}

		if (newState && currentState && newState[this.stateKey] === currentState[this.stateKey]) {
			if (currentState.hasNextPage) {
				// as far as I know we only need it for Safari
				this.currentState = currentState.hasNextPage;
				push(currentState.hasNextPage);
			}
			return;
		}

		if (newState.firstPage && currentState.firstPage) {
			// a user navigates back from the initial page but caught into the back button trap
			// need to navigate back
			this.currentState = {
				...newState,
				hasNextPage: currentState,
			};

			replace(this.currentState);
			window.history.back();
			return;
		}

		if (isFunction(this.blockNavigation) && this.blockNavigation()) {
			push(currentState);
			return;
		}

		this.currentState = newState;

		this.subject.raise(void 0);
	}
}
