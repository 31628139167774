import { action, observable } from 'mobx';
import { PaymentLabel, PledgeLabel } from '../../donorpledgeentry-generated';
import { MainViewModel } from '../../main/main-view-model';
import { RecurringOption } from '../../utils/recurring-utils';

type RecurringInfo = { option?: RecurringOption; amount?: number; redirectUrl?: string };

export class PledgeExistsViewModel {
	campaignName: string;
	pledgeProgressUrl: string;
	giveToCampaignUrl: string;
	pledgeLabel: PledgeLabel;
	paymentLabel: PaymentLabel;

	@observable
	recurringInfo: RecurringInfo = {};

	@observable
	recurringPaymentExists: boolean;

	get isLoading(): boolean {
		return this.vm.isAppLoading;
	}

	constructor(private vm: MainViewModel){
		this.campaignName = vm.campaignInfo.CampaignName;
		this.pledgeProgressUrl = vm.campaignInfo.PledgeProgressUrl;
		this.giveToCampaignUrl = vm.campaignInfo.GiveToCampaignUrl;
		this.pledgeLabel = vm.organization.PledgeLabel;
		this.paymentLabel = vm.organization.PaymentLabel;
		this.recurringPaymentExists = Boolean(vm.person && vm.person.RecurringPaymentExists);
		vm.history.push(false);
	}

	@action
	setRecurringPaymentExists = (exists: boolean) => {
		this.recurringPaymentExists = exists;
	};

	@action
	setPledgeProgressUrl = (url: string) => {
		this.pledgeProgressUrl = url;
	};

	@action
	setRecurringInfo = (info: RecurringInfo) => {
		this.recurringInfo = { ...this.recurringInfo, ...info };
	};

	handleSubmit = () => {
		if (!this.recurringInfo.redirectUrl && this.recurringPaymentExists) {
			this.redirectToGiversCampaignPage();
		} else if (this.recurringInfo.redirectUrl) {
			window.location.href = decodeURIComponent(this.recurringInfo.redirectUrl);
		} else if (!this.recurringPaymentExists) {
			window.location.href = decodeURIComponent(this.giveToCampaignUrl);
		};
	}

	redirectToGiversCampaignPage = () => {
		window.location.href = this.pledgeProgressUrl;
	}
}
