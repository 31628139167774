import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import '../../scrollIntoViewIfNeeded';
import { getBoundingRect } from '../../../Shared/utils/getboundingrect';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { responsiveHelper } from '../../../Shared/helpers/responsive-helper';
import { isTouchSupported } from '../../../Shared/helpers/browser-support-helper';
import { classNames } from '../../../Shared/utils/classnames';
import { Text } from '../../components/text';

import { SvgIcon } from '../svg-icon';
import closeIcon from '../../assets/svg/icon-close.svg';
import infoIcon from '../../assets/svg/icon-info.svg';
import * as style from './tooltip.less';

export enum TooltipPlacement {
	Left = 1,
	Right,
	Bottom,
	Top,
}

export interface ITooltipProps {
	visibleForTesting?: boolean;
	tooltipContentId?: string;
	placement: TooltipPlacement;
	hideOnMobile?: boolean;
	mobileHeader?: string;
	buttonClassName?: string;
	content: React.ReactChild[] | React.ReactChild;
}

export interface ITooltipContext {
	visible: boolean;
}

export class TooltipContent extends React.Component<{}, {}> {
	public render() {
		return <div>{this.props.children}</div>;
	}
}

let tooltipId = 0;

function getTooltipId() {
	return tooltipId++;
}

@observer
export class Tooltip extends React.Component<ITooltipProps, ITooltipContext> {
	static arrowWidth = parseInt(style.tooltipArrowSize);
	tooltipRef: HTMLDivElement | null;// the tooltip container
	contentRef: HTMLDivElement | null;// the tooltip content
	targetRef: HTMLButtonElement | null; // The element that triggers the tooltip
	container: HTMLDivElement; // The out-of-flow container for the tooltip
	previousAnimationRequest: number | null = null;
	documentActiveElement: Element;
	focusTimeout: number;
	private readonly tooltipButtonId: string;
	private readonly tooltipContentId: string;
	private readonly tooltipHiddenId: string;

	constructor(props: ITooltipProps) {
		super(props);
		this.state = {
			visible: false,
		};
		const tooltipId = getTooltipId();
		this.tooltipButtonId = `tooltip_button_${tooltipId}`;
		this.tooltipContentId = `tooltip_content_${tooltipId}`;
		this.tooltipHiddenId = `tooltip_hidden_${tooltipId}`;
	}

	public render(): any {
		const { buttonClassName, children } = this.props;

		return ([
				<button key={this.tooltipButtonId}
					type="button"
					className={classNames(style.button, buttonClassName)}
					ref={ref => this.targetRef = ref}
					aria-hidden
					{...this.getButtonActions()}>
					{children}
				</button>,
				<div key={this.tooltipHiddenId} className="hidden" id={this.props.tooltipContentId}>{this.props.content}</div>,
				this.renderTooltipContent(),
		]);
	}

	public UNSAFE_componentWillMount() {
		this.container = document.createElement('div');
		this.container.classList.add('pp-tooltip');
		document.body.appendChild(this.container);
	}

	public componentDidUpdate() {
		this.reposition();
	}

	public componentDidMount() {
		this.reposition();
		window.addEventListener('resize', this.resize);
	}

	public componentWillUnmount() {
		document.body.removeChild(this.container);
		window.removeEventListener('resize', this.resize);
		window.clearTimeout(this.focusTimeout);
	}

	private resize = () => {
		if (this.previousAnimationRequest) {
			window.cancelAnimationFrame(this.previousAnimationRequest);
		}
		this.previousAnimationRequest = window.requestAnimationFrame(this.resizeCallback);
	}

	private getButtonActions = () => {
		if (!responsiveHelper.isXs) {
			return {
				onClick: this.showTooltip,
				onMouseEnter: this.showTooltip,
				onMouseOut: this.hideTooltip,
			};
		}
		if (isTouchSupported()) {
			return { onTouchStart: this.showTooltip };
		}
		return { onClick: this.showTooltip };

	}

	private resizeCallback = () => {
		this.previousAnimationRequest = null;
		this.reposition();
	}

	private setDocumentActiveElement = (element: Element) => {
		this.documentActiveElement = element;
	}

	private handleEscapeDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
		const { keyCode } = evt;
		const { Escape } = KeyCodes;

		if (keyCode === Escape) {
			this.hideTooltip();
		}
	}

	private showTooltip = () => {
		this.setDocumentActiveElement(document.activeElement as Element);
		this.setState({ visible: true });
		this.focusTimeout = window.setTimeout(this.focusTooltip, parseInt(style.transitionDuration, 10));
	}

	private hideTooltip = () => {
		this.setState({ visible: false });
		if (this.documentActiveElement) {
			(this.documentActiveElement as HTMLElement).focus();

			if (this.documentActiveElement.scrollIntoViewIfNeeded && responsiveHelper.isXs) {
				this.documentActiveElement.scrollIntoViewIfNeeded();
			}
		}
	}

	private focusTooltip = () => {
		if (this.contentRef) {
			this.contentRef.focus();
		}
	}

	private renderTooltipContent = () => {
		const classNames = {
			appear: style.tooltipEnter,
			appearActive: style.tooltipEnterActive,
			enter: style.tooltipEnter,
			enterActive: style.tooltipEnterActive,
			exitActive: style.tooltipExitActive,
		};

		const backgroundAction = isTouchSupported() ? { onTouchStart: this.hideTooltip } : { onClick: this.hideTooltip };
		const {mobileHeader} = this.props;

		const component = (
			<CSSTransition
				key={this.tooltipContentId}
				in={this.getTooltipContentVisibility()}
				timeout={parseInt(style.transitionDuration, 10)}
				classNames={classNames}
				enter
				unmountOnExit
			>
				<div>
					<div className={style.background} {...backgroundAction}/>
					<div
						className={`${style.inner} ${this.getContentPositionClassName()}`}
						ref={ref => this.tooltipRef = ref}
						onKeyDown={this.handleEscapeDown}
					>
						<div className={style.content} ref={ref => this.contentRef = ref} tabIndex={0}>
							<button className={style.close} onClick={this.hideTooltip}>
								<SvgIcon svg={closeIcon} />
							</button>
							<h1 className={style.modalHeader}>{!!mobileHeader ? <Text>{mobileHeader}</Text> : mobileHeader}</h1>
							{this.props.content}
						</div>
					</div>
				</div>
			</CSSTransition>
		);

		return ReactDOM.createPortal(component, this.container);
	}

	private getContentPositionClassName() {
		switch (this.props.placement) {
			case TooltipPlacement.Top:
				return style.top;
			case TooltipPlacement.Left:
				return style.left;
			case TooltipPlacement.Bottom:
				return style.bottom;
			case TooltipPlacement.Right:
				return style.right;
		}
	}

	private getTooltipContentVisibility() {
		const { hideOnMobile, visibleForTesting } = this.props;
		if (hideOnMobile && responsiveHelper.isXs) {
			return false;
		}
		if (visibleForTesting) {
			return true;
		}
		return this.state.visible;
	}

	/**
	 * Handle aligning the tooltip to the content that triggered it. This only happens
	 * on devices where the screen is large enough to allow a floating tooltip, else
	 * it will be a full-screen modal.
	 */
	private reposition = () => {
		if (!this.targetRef) {
			return;
		}
		if (!this.tooltipRef) {
			return;
		}

		this.tooltipRef.style.top = '';
		this.tooltipRef.style.bottom = '';
		this.tooltipRef.style.left = '';
		this.tooltipRef.style.right = '';
		this.tooltipRef.style.height = '';

		if (responsiveHelper.isXs) {
			return;
		}

		const localSize = this.tooltipRef.getBoundingClientRect();
		const { width, height } = localSize;

		const { left, top, width: parentWidth, height: parentHeight } = getBoundingRect(this.targetRef);

		switch (this.props.placement) {
			case TooltipPlacement.Top:
				this.tooltipRef.style.top = (top - height) - Tooltip.arrowWidth + 'px';
				this.tooltipRef.style.left = this.calculateMidpoint(left, parentWidth, width) + 'px';
				break;
			case TooltipPlacement.Right:
				this.tooltipRef.style.top = this.calculateMidpoint(top, parentHeight, height) + 'px';
				this.tooltipRef.style.left = (left + parentWidth) + Tooltip.arrowWidth + 'px';
				break;
			case TooltipPlacement.Bottom:
				this.tooltipRef.style.top = (top + parentHeight) + Tooltip.arrowWidth + 'px';
				this.tooltipRef.style.left = this.calculateMidpoint(left, parentWidth, width) + 'px';
				break;
			case TooltipPlacement.Left:
				this.tooltipRef.style.top = this.calculateMidpoint(top, parentHeight, height) + 'px';
				this.tooltipRef.style.left = (left - width) - Tooltip.arrowWidth + 'px';
				break;
		}
	}

	private calculateMidpoint(parentStart: number, parentSize: number, size: number) {
		return parentStart + (parentSize / 2 - size / 2);
	}
}

export class StandardTooltip extends React.Component<ITooltipProps> {
	render() {
		const { children, buttonClassName, ...rest } = this.props;
		return (
			<Tooltip buttonClassName={classNames(style.standardButton, buttonClassName)} {...rest}>
				<SvgIcon svg={infoIcon} className={`icon ${style.tooltipIcon}`} />
			</Tooltip>
		);
	}
}
