import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './main/main';
import { MainViewModel } from './main/main-view-model';
import { Main } from './main/main';
import { DonorPledgeEntryViewModel } from './donorpledgeentry-generated';
import { getDonorPledgeEntryDataService } from './donorpledgeentry-data-service';
import { GoogleAnalyticsWrapper } from '../WebGiving/infrastructure/google-analytics-wrapper';

export function init(model: string) {
	initApp(JSON.parse(model) as DonorPledgeEntryViewModel);
}

function initApp(model: DonorPledgeEntryViewModel) {
	GoogleAnalyticsWrapper.initialize('DonorPledgeEntry');

	const vm = new MainViewModel(model);
	ReactDOM.render(React.createElement(Main, { vm }), document.getElementById('react-container'));

	// temporary to have the service testable from Chrome DevTools
	if (DEBUG) {
		(window as any)['dataService'] = getDonorPledgeEntryDataService();
		(window as any)['vm'] = vm;
	}
}
