import React from 'react';
import { observer } from 'mobx-react';
import { EnterPledgeViewModel } from '../enter-pledge-view-model';
import { PledgeType } from '../../../utils/recurring-utils';
import { FormControl } from '../../../../WebGiving/components/form-control/form-control';
import { FormControlSplitButton } from '../../../../WebGiving/components/split-button';
import { DatePickerField } from '../../../../WebGiving/components/form-fields/date-picker-field';
import { FormControlFrequencyComboButton } from '../../../components/frequency-combo-button/frequency-combo-button';
import { RecurringCalculatorSummary } from '../../../components/recurring-calculator-summary/recurring-calculator-summary';

import * as styles from './pledge-with-recurring.less';
import { FrequencyCode } from '../../../donorpledgeentry-generated';

@observer
export class PledgeWithRecurring extends React.Component<{ vm: EnterPledgeViewModel }> {
	render() {
		const { vm } = this.props;
		const {
			pledgeLabel,
			pledgeType,
			frequency,
			pledgeTypeOptions,
			campaignDescription,
			recurringOption,
			recurringOptions,
			recurringStartDate,
			recurringEndDate,
			recurringMinStartDate,
			recurringMaxEndDate,
			setRecurringOption,
			setRecurringStartDate,
			setRecurringEndDate,
			amount
		} = vm;

		const pickerOptions = {
			minDate: recurringMinStartDate,
			maxDate: recurringMaxEndDate,
			disableDayFn: this.disableDays
		}

		return (
			<div data-pp-at-target="Enter pledge with recurring page">
				<h3 className={styles.title}>About the campaign</h3>
				<div className={styles.description}><p data-pp-at-target="Enter pledge campaign description" dangerouslySetInnerHTML={{ __html: campaignDescription}}></p></div>
				<FormControl className={styles.pledgeType}>
					<FormControlSplitButton
						field={pledgeType}
						options={pledgeTypeOptions}
						uniformWidth={true}
						ariaLabel={`${pledgeLabel.NounSentenceCase} type`}
						acceptanceTestTargetId="Pledge type"
					/>
				</FormControl>
				{pledgeType.value === PledgeType.WithRecurring && (
					<>
						<div className={styles.recurringDates}>
							<FormControl
								label="Starting"
								className={styles.recurringStart}
								validationError={recurringStartDate.validationError}
							>
								<DatePickerField
									formField={recurringStartDate}
									options={pickerOptions}
									acceptanceTestTargetId="Recurring Start Date"
									onChange={setRecurringStartDate}
								/>
							</FormControl>
							<FormControl
								label="Ending"
								className={styles.recurringEnd}
								validationError={recurringEndDate.validationError}
							>
								<DatePickerField
									formField={recurringEndDate}
									options={pickerOptions}
									acceptanceTestTargetId="Recurring End Date"
									placeholder="Select end date"
									onChange={setRecurringEndDate}
								/>
							</FormControl>
						</div>
						{recurringOptions.length ? (
							<>
								<FormControl
									label={`Contribute to your ${pledgeLabel.NounLowerCase}`}
									validationError={recurringOption.validationError}
								>
									<FormControlFrequencyComboButton
										field={frequency}
										options={recurringOptions.map((option) => ({
											value: option.code,
											label: option.label,
										}))}
										ariaLabel="Frequency"
										acceptanceTestTargetId="Recurring Frequency"
										onChange={setRecurringOption}
									/>
								</FormControl>
							</>
						) : <div className={styles.noOptions}>{this.getNotice()}</div>}
						{recurringOption.value &&
							<RecurringCalculatorSummary
								recurringAmount={recurringOption.value.amount}
								startDate={recurringOption.value.starts}
								endDate={recurringOption.value.ends}
								totalAmount={recurringOption.value.amount * recurringOption.value.occurrences}
								totalGifts={recurringOption.value.occurrences}
								frequencyLabel={recurringOption.value.label}
								showAmountRoundedWarning={amount.value !== (recurringOption.value.amount * recurringOption.value.occurrences)}
							/>
						}
					</>
				)}
			</div>
		);
	}

	private disableDays = (date: Date) => {
		if (this.props.vm.recurringOption.value && this.props.vm.recurringOption.value.code === FrequencyCode.FirstAndFifteenth) {
			return ![1, 15].includes(date.getDate());
		}
		return false;
	};

	private getNotice = () => {
		const MINIMUM_OCCURRENCES = 2;

		const {
			amount,
			organizationName,
			merchantPaymentMin,
			merchantPaymentMax,
			merchantAchMax,
			recurringEndDate,
			recurringStartDate,
			paymentLabel,
			pledgeLabel,
		} = this.props.vm;

		const weeksApart = moment(recurringEndDate.value).diff(moment(recurringStartDate.value), 'weeks');
		const maxPaymentLimit = Math.min(merchantPaymentMax, merchantAchMax || merchantPaymentMax);
		const isBeyondMax = weeksApart ? amount.value / weeksApart > maxPaymentLimit : false;
		const isBelowMinimum = amount.value / MINIMUM_OCCURRENCES < merchantPaymentMin;

		const labels = NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar
			? {
					upperCase: pledgeLabel.VerbSentenceCase,
					lowerCase: pledgeLabel.VerbLowerCase,
			  }
			: {
					upperCase: 'Pledge',
					lowerCase: 'pledge',
			  };

		if (amount.value && weeksApart && (isBelowMinimum || isBeyondMax)) {
			return (
				<>
					No recurring options available,
					<p className={styles.info}>
						This is because your proposed {paymentLabel.NounLowerCase} amount{' '}
						{isBeyondMax ? 'exceeds the maximum' : 'is below the minimum'} {paymentLabel.NounLowerCase}{' '}
						limit set by {organizationName}. Try adjusting your {labels.lowerCase} amount and start/end
						dates for more options. Alternatively select {labels.upperCase} only.
					</p>
				</>
			);
		}

		return `No recurring options available, please ${
			amount.value ? `adjust your ${labels.lowerCase} amount, start or end date.` : 'update your amount.'
		}`;
	};
}
