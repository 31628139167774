import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { ManageAccountLink, LogOutLink } from './account-links';
// import { DonorPledgeEntryUserAction } from '../../donor-user-actions';
import { responsiveHelper } from '../../../Shared/helpers/responsive-helper';
import { DonorPledgeEntryPersonModel, PaymentLabel } from '../../donorpledgeentry-generated';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { SvgIcon } from '../svg-icon';

import accountSvg from '../../../WebGiving/assets/svg/account.svg';
import closeIcon from '../../../WebGiving/assets/svg/icon-close.svg';
import * as styles from './account.less';
// import { injectDonorPledgeEntryProps, InjectableDonorPledgeEntryProps, raiseAction } from '../../utils/injectable-props';

export interface IAccountProps {
	isAccountVisible: boolean;
	person: DonorPledgeEntryPersonModel | null;
	setAccountVisible: (visible: boolean) => void;
	paymentLabel: PaymentLabel;
	manageAccountUrl: string;
	handleUserSignOut: () => void;
}

@observer
export class Account extends React.Component<IAccountProps> {
	private button: HTMLButtonElement;

	render() {
		const { isAccountVisible, person, paymentLabel, manageAccountUrl, handleUserSignOut } = this.props;
		const personName = person && person.Nickname;
		const classNames = {
			appear: styles.panelEnter,
			appearActive: styles.panelEnterActive,
			enter: styles.panelEnter,
			enterActive: styles.panelEnterActive,
			exitActive: styles.panelExitActive,
		};

		return (
			<div className={styles.container}>
				<button type="button" className={styles.button} onClick={this.handleAccountClick} data-pp-at-target="Your account" ref={this.setButton} aria-label="Your account">
					<div className={`${styles.logo} hidden-xs`}>
						<SvgIcon svg={accountSvg} />
					</div>
					<div className={styles.logoSmall}>
						<SvgIcon svg={accountSvg} scale={0.87} />
					</div>
					<div className={styles.salutationSmall}>
						<span className="hidden-xs">{personName ? `Hi ${personName}` : 'Your Account'}</span>
					</div>
				</button>
				<CSSTransition
					in={isAccountVisible}
					classNames={classNames}
					timeout={parseInt(styles.transitionDurationMs, 10)}
					appear
					unmountOnExit
				>
					<AccountPanel
						onClose={this.handleClose}
						person={person}
						paymentLabel={paymentLabel}
						manageAccountUrl={manageAccountUrl}
						getPosition={this.getButtonPosition}
						handleUserSignOut={handleUserSignOut}
					/>
				</CSSTransition>
			</div>
		);
	}

	getButtonPosition = () => {
		return this.button && !responsiveHelper.isXs
			? window.innerWidth - this.button.getBoundingClientRect().right
			: null;
	}

	private handleClose = () => {
		this.props.setAccountVisible(false);
		if (this.button) {
			this.button.focus();
		}
	}

	private handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		event.preventDefault();
		this.toggleAccountVisible();
	}

	private toggleAccountVisible = () => {
		this.props.setAccountVisible(!this.props.isAccountVisible);
	}

	private setButton = (button: HTMLButtonElement) => {
		this.button = button;
	}
}

interface IAccountPanelProps {
	person: DonorPledgeEntryPersonModel | null;
	onClose: () => void;
	paymentLabel: PaymentLabel;
	manageAccountUrl: string;
	getPosition: () => number | null;
	handleUserSignOut: () => void;
}

// @injectDonorPledgeEntryProps
@observer
export class AccountPanel extends React.Component<IAccountPanelProps /*& InjectableDonorPledgeEntryProps */> {
	private renderTarget: HTMLDivElement;
	private panel: HTMLDivElement;
	private manageLink: HTMLAnchorElement;

	UNSAFE_componentWillMount() {
		this.renderTarget = document.createElement('div');
		this.renderTarget.className = 'pp-account-panel';
		document.body.insertBefore(this.renderTarget, document.body.firstChild);
	}

	render() {
		const { person, paymentLabel, manageAccountUrl, handleUserSignOut } = this.props;
		const accountPanel = (
			<div className={styles.panel} onKeyDown={this.handleKeyDown} ref={this.setPanel}>
				<button
					className={`${styles.close} visible-xs`}
					data-pp-at-target="Close account panel"
					aria-label="Close"
					onClick={this.props.onClose}
				>
					<SvgIcon svg={closeIcon}
					/>
				</button>
				<h4 className={styles.title} aria-hidden>Welcome to Your Account</h4>
				<p className="small" id="account-panel-description">
					Your Account is where you can view transactions, update your payment method or manage recurring {paymentLabel.NounPluralLowerCase}.
				</p>
				<ManageAccountLink
					className={styles.manage}
					href={manageAccountUrl}
					acceptanceTestTargetId="Account Manage account"
					branded={true}
					aria-labelledby="account-panel-description"
					ref={this.setManageLink}
				>
					Manage Account
				</ManageAccountLink>
				{person && [
					<hr key="divider" className={styles.horizontalDivider} />,
					<LogOutLink key="link" className={styles.signout} onClick={handleUserSignOut} acceptanceTestTargetId="Account Sign out" branded={false}>Sign out</LogOutLink>,
				]}
			</div>
		);

		return ReactDOM.createPortal(accountPanel, this.renderTarget);
	}

	componentDidMount() {
		this.positionPanel();
		this.injectListeners();
		this.focusContent();
	}

	componentWillUnmount() {
		this.removeListeners();
		document.body.removeChild(this.renderTarget);
	}

	private focusContent = () => {
		if (this.manageLink && !responsiveHelper.isXs) {
			this.manageLink.focus();
		}
	}

	private injectListeners = () => {
		window.addEventListener('resize', this.positionPanel);
		document.body.addEventListener('click', this.onInteract);
		document.body.addEventListener('touchstart', this.onInteract);
		document.body.addEventListener('focus', this.onInteract, true);
	}

	private removeListeners = () => {
		window.removeEventListener('resize', this.positionPanel);
		document.body.removeEventListener('click', this.onInteract);
		document.body.removeEventListener('touchstart', this.onInteract);
		document.body.removeEventListener('focus', this.onInteract, true);
	}

	private onInteract = (event: MouseEvent | FocusEvent | TouchEvent) => {
		if (this.panel && !this.panel.contains(event.target as Node)) {
			event.preventDefault();
			event.stopPropagation();
			this.props.onClose();
		}
	}

	private setPanel = (panel: HTMLDivElement) => {
		this.panel = panel;
	}

	private positionPanel = () => {
		const { getPosition } = this.props;
		const right = getPosition();

		this.panel.style.right = this.panel && right ? `${right}px` : '';
	}

	private handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.keyCode === KeyCodes.Escape) {
			this.props.onClose();
		}
	}

	private setManageLink = (ref: any) => {
		this.manageLink = ReactDOM.findDOMNode(ref) as HTMLAnchorElement;
	}
}
