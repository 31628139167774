import { action, IObservableValue, observable } from 'mobx';

import { PageViewModelBase } from '../shared/page-view-model-base';
import { DonorPledgeEntryError, DonorPledgeEntryErrorType } from '../../components/error-message/error-message';
import { MainViewModel } from '../../main/main-view-model';
import { SecurityCodeEvents, SecurityCodeStates } from '../../state-machine/security-code-states-events-actions';

import { ValidationState, validateFields } from '../../../WebGiving/validation/view-model-validator';
import { FormField } from '../../../WebGiving/components/form-field';
import { createRulesFromMetadata } from '../../../WebGiving/validation/validation-rules';
import { ModelMetadata } from '../../../WebGiving/webgiving-generated';

export class ConfirmCreatePledgeViewModel extends PageViewModelBase {
	get amount(): number {
		return this.vm.amount;
	}

	@observable
	mobileNumber: string;

	@observable
	securityCode: FormField<string>;

	@observable
	validationState?: ValidationState;

	rememberMobileNumber = FormField.Unvalidated(false);

	@observable
	error: DonorPledgeEntryError;

	@observable
	normalizedInternationalNumber: string;

	@observable
	requestCode: string;

	@observable
	resentCodeState(): boolean {
		return this.vm.machineContext.matchesState(SecurityCodeStates.Resent);
	}

	constructor(
		private vm: MainViewModel,
		mobileNumber: string,
		public userIsRemembered: boolean,
		public resendCodeDisabled: IObservableValue<boolean>,
	) {
		super();
		this.mobileNumber = mobileNumber;
		this.securityCode = new FormField<string>('', createRulesFromMetadata(ModelMetadata.EnterMobileNumberConfirmCodeRequest.Code));
		vm.history.push(false);
	}

	@action
	handleSubmit = () => {
		SecurityCodeEvents.raise.SubmitSecurityCode(this.vm.machineContext);
	}

	@action
	showHelp = () => {
		SecurityCodeEvents.raise.ShowNeedHelp(this.vm.machineContext);
	}

	validate(): ValidationState {
		return validateFields(this);
	}

	@action
	setMobileNumber = (mobileNumber: string) => {
		this.mobileNumber = mobileNumber;
	}

	@action
	setError = (errorMessage: string) => {
		this.error = {
			errorType: DonorPledgeEntryErrorType.Generic,
			message: errorMessage,
		};
	}

	@action
	resetError = () => {
		this.error = null;
	}

	@action
	resetSecurityCode = () => {
		this.securityCode.resetField();
		this.securityCode.updateValue('');
	}

	enableResendCode = () => {
		this.resendCodeDisabled.set(false);
	}

	disableResendCode = () => {
		this.resendCodeDisabled.set(true);
	}

	resendCode = () => {
		SecurityCodeEvents.raise.RequestCodeResend(this.vm.machineContext);
	}

	@action
	setRequestCode = (code: string) => {
		this.requestCode = code;
	}
}
