import * as React from 'react';

import { ModalHeader, ModalContents } from '../../../WebGiving/components/modal/modal';


export interface IMobileSignInInfoModalProps {
}

export class MobileSignInInfoModalHeader extends React.Component {
	render() {
		return <ModalHeader text="How does mobile sign in work?" />;
	}
}

export class MobileSignInInfoModalContent extends React.Component<IMobileSignInInfoModalProps> {
	render() {
		return (
			<ModalContents>
				<div className="lead">
					{/* tslint:disable:max-line-length */}
					<p>Mobile sign in streamlines your giving by allowing you to sign in with a security code. The way it works is simple. We will text the security code to your mobile phone, or we can send it via voice call.</p>
					<p>You no longer need to remember a password! The security code replaces the need to remember one. Managing your account and giving has never been so secure.</p>
					{/* tslint:enable:max-line-length */}
				</div>
			</ModalContents>
		);
	}
}
